import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import ScrollToTop from './Components/ScrollToTop/ScrollToTop';

ReactDOM.render(
  <BrowserRouter>
    <ScrollToTop>
      <App className="app-class" />
    </ScrollToTop>
  </BrowserRouter>,
  document.getElementById('root'),
);
registerServiceWorker();
