import React from 'react';
import './WebDev.css';
import { Helmet } from 'react-helmet';
class WebDev extends React.Component {
  constructor(props) {
    super(props);
    this.initialState = {
      isShow: false,
    };
  }

  componentWillMount() {
    this.setState(this.initialState);
  }
  componentDidMount() {
    // window.addEventListener('scroll', (event) => {
    //     this.myFunction();
    // })
  }
  componentWillUnmount() {
    this.setState(this.initialState);
  }
  handleChange = event => {
    event.preventDefault();
    document.querySelector('.learnMoreText').classList.toggle('hide');
    this.setState({
      isShow: !this.state.isShow,
    });
  };
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Web Development</title>
        </Helmet>
        <div className="genericContainer genericContainerHeader">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-6 col-12">
              <div className="hero-software content hero-feature-content">
                <strong>
                  <span className="TeamPageTitle1">WEB DESIGN & </span> WEB DEVELOPMENT
                </strong>
                {/* <p className="alignCenter">Please read the document carefully. </p> */}
              </div>
            </div>
          </div>
        </div>
        {/* <header className="webDevHeader">
          <div className="webDevImage row">
            <div className="transparentLayer" />
            <img  src={webDev} alt="webDevImage" className='col-12'/>
          </div>
          <div className="webDevTitle">
            <center>
              <strong>
                <span className="webDevTitle1">Website Design and</span> Web App Development
              </strong>
            </center>
          </div>
        </header> */}
        <div className="webDevContent container">
          <div className="webDevSection1">
            <br />
            <center className="webDevContentTagLine">
              <i class="fa fa-quote-left" aria-hidden="true" />
              <h4>
                <strong className="webDevContentTagLineTitle">
                  The force behind your IT infrastructure
                </strong>
              </h4>
            </center>
            <p className="webDevIntro">
              We simplify and support your business through affordable IT solutions. What’s a
              website that has only beauty and no brains?
              <span onClick={this.handleChange} className="learnMoreText show">
                [Learn More...]
              </span>
            </p>
            {this.state.isShow && (
              <div className="learnMore">
                <div className="webDesign">
                  <div className="webDesignTitle">
                    <strong>Website Design</strong>
                  </div>
                  <div className="webDesignContent">
                    Website design is the process that incorporates elements such as information
                    architecture, user interface, navigation ergonomics, website layouts, colours,
                    contrasts, imagery, icons, and fonts and it involves the steps of planning,
                    creation, and updating of the website keeping the above elements in mind. If
                    these terms seem mindboggling, we are here to assure you that we have the best
                    resources and expertise in creating simple and user-friendly websites that suit
                    your business needs.{' '}
                    <strong>
                      FSJARS provides website services for free and we build your website for free
                      in order to amplify your business potential and bring you closer to success.
                      We believe in you.
                    </strong>
                    We use up to date technologies such as NodeJS & Express, Java python for server
                    side scripting, ReactJS or AngularJS for enhanced user experience, and CSS
                    frameworks for developing a professional look for the website. We keep abreast
                    with new technology which loads your pages faster and better. We also test
                    cross-browser compatibility and thoroughly check all the platforms for your
                    website and ensure that it fits every platform well. Once you come to us, you
                    don’t need to worry about anything. You can focus on your business expansion and
                    leave the content development and website maintenance to us. We manage updates
                    for free. We also provide end-to-end support because we will leave you only when
                    you become a raging success. Our company is well equipped with these tools which
                    will make your website stand out amongst others. We are revered for combining
                    creativity with a number of functionalities to give enhanced and interactive
                    websites that will blow the user’s mind away.
                  </div>
                </div>
                <div className="webAppDevelopment">
                  <div className="webAppDevTitle">
                    <strong>Web Application Development</strong>
                  </div>
                  <div className="webAppDevContent">
                    Web application development involves the crucial task of creating applications
                    which can be accessed by the user’s device via the internet and these
                    applications reside on remote servers. The client-server software application
                    runs in the web browser of the client. The client portion of the website is
                    designated as the front end whereas the server side involves using a powerful
                    programming language, a database, and the server which together constitute the
                    backend. The front end and the back end combine to create user-friendly and
                    attractive web applications. Web applications that are created by our company
                    are robust and secure. Our expertise in creating web applications that implement
                    well-thought-out strategies for solving problems, executing a process,{' '}
                    <strong>mobile-first approach</strong>, and making a repetitive mundane task
                    automated along with thoughtful and important reports that will elevate your
                    business online. Our company uses technologies such as MEAN (Mongo/MySQL,
                    Express, AngularJS, and NodeJS) stack or MERN (Mongo/MySQL, Express, ReactJS,
                    and NodeJS) that are modern and strong technologies which are well suited for
                    your business. Our work is absolutely flawless and customized to suit your
                    needs. We also provide <strong>end-to-end support</strong> because we will leave
                    you only when you become a raging success.
                  </div>
                </div>
              </div>
            )}
            <div className="webDevService">
              We also provide these services for website and web application such as-
            </div>
            <div className="webDevContent">
              <div className="webDevDesign">
                <div className="webDevDesignTitle webServicesTitle">
                  <strong>Multilingual website design</strong>
                </div>
                <div className="webDevDesignContent">
                  If you wish to create a website that caters to the needs of customers all over the
                  world, then you can stop your search here. In today’s world where
                  internationalization knocks on every door of every business, the need for
                  multilingual websites to comprehend and visualize consumer demands from all over
                  the world warrants the need to know their language. Multilingual websites could be
                  the way to increase the existing customer base and sales volumes exponentially
                  because a bridge between your business and a customer from a separate part of the
                  world has been created. We are pioneers of conjuring robust and creative
                  multilingual websites which will be the innovation that gives your business a
                  competitive advantage over others. Our services will leave you with a growing
                  international consumer base.
                </div>
              </div>
              <div className="webDevSEO">
                <div className="webDevSEOTitle webServicesTitle">
                  {' '}
                  <strong>Search Engine Optimization (SEO)</strong>
                </div>
                <div className="webDevSEoContent">
                  Search results on the internet can be manipulated in order to drive more quality
                  and quantity of traffic on your website through the ‘organic’, ‘editorial’,
                  ‘natural’, and ‘free’ search results that are present on search engines.
                  Optimization includes the part where the content management system of the website
                  where people put up content that is deliberately made attractive enough for search
                  engines to match the queries of the user exactly to the website. Our company
                  consists of experts who can ensure that your website is search engine optimized
                  and make sure that the user traffic that enters your website is relevant to your
                  product or service.
                </div>
              </div>
              <div className="webDevHosting">
                <div className="webDevHostingTitle webServicesTitle">
                  <strong> Web hosting services</strong>
                </div>
                <div className="webDevHostingContent">
                  We are providers of top-notch web hosting services where we manage the technology
                  employed for connecting your website to the internet. We provide the services for
                  hosting your website which is a necessity as it physically locates your website on
                  the internet and we maintain the server where your website and its associated data
                  resides. We accurately gauge the amount of bandwidth and disk space your website
                  requires based on the interaction and traffic your website attains. The items and
                  content (photos, maps, and PDF files) that is present on your website determine
                  the amount of disk space that is needed by your website.
                </div>
              </div>
              <div className="webDevFooter">
                <center>
                  <strong>
                    With FSJARS, you are making the right decision of using our website design and
                    web application services. Our quality of work will impact your business
                    meaningfully and we are the stepping stone that will elevate your business and
                    ensure its success.
                  </strong>
                </center>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default WebDev;
