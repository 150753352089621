import React from 'react';
import './HappyClient.css';
import {Link} from 'react-router-dom';

const HappyClient=()=>{
    return(
        <React.Fragment>
            <section id="testimonials">
                <div className="row happy-client-section">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                        <h2 className="text-center">
                        Happy <span className="custom-h2">Clients</span>
                        </h2>
                        <hr className="below-header-hr" />
                        <p className="below-para">
                        <em>Our work speaks volumes</em>
                        <Link to='/testimonial' className='btn aboutUsLearnMore' style={{textAlign: 'end'}}><strong>Learn More <i className='fa fa-arrow-right rightArrowLearnMore'></i></strong></Link></p>
                    </div>
                </div>

                <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                    <ol className="carousel-indicators happyClientCarousel">
                        <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active" />
                        <li data-target="#carouselExampleIndicators" data-slide-to="1" />
                    </ol>
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <div className="test-grid">
                                <div class="col-md-12 test-grid1 happy-client">
                                <i className="fa fa-quote-left" aria-hidden="true" />
                                <p className="para-w3-agile">
                                    FSJARS was instrumental in the creation of my website. Their experience was
                                    very vast and they were able to deliver exactly what I required in a very
                                    short span of time. Along with their lightning fast services, they also
                                    provided useful advice for my business. I will definitely opt for their
                                    services for any other websites that I may create in the near future.
                                </p>
                                <h4>Brijesh Mehetre</h4>
                                <span>Co-Founder, coreidd.com</span>
                                <br />
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div className="test-grid">
                                <div class="col-md-12 test-grid1 happy-client">
                                    <i className="fa fa-quote-left" aria-hidden="true" />
                                    <p className="para-w3-agile">
                                        FSJARS is an esteemed company to work with and I’m glad I employed their
                                        services. They came up with great concepts and developments and gave a
                                        fantastic output with little to no difficulties. They are great listeners ,
                                        move around with the work very well, and they implement changes very
                                        quickly. I am working with them on 2 projects currently and their team has
                                        always come up with gorgeous ideas. I would recommend them to anybody in
                                        need of thoroughly professional web development services.
                                    </p>
                                    <h4>Sunil Pawar</h4>
                                    <span>Co-Founder, godigitalseva.com</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}
export default HappyClient;