import React, { Component } from 'react';
import './Grievance.css';
import { Helmet } from 'react-helmet';
class Grievance extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Raise your Grievance</title>
        </Helmet>
        <header className="grievanceHeader">
          <strong>
            GRIEVANCE <span className="grievanceHeaderMiddle">REGISTRATION</span> FORM
          </strong>
        </header>

        <div class="card container">
          <h5 class="card-header">Basic Details</h5>
          <div class="card-body grievane">
            <form action="">
              <div className="row">
                <div className="col-md-2 ">Types of Grievance</div>
                <div className="col-md-2 form-group">
                  <input type="radio" value="Complaint" name="typeOfGrievance" id="Complaint" />
                  Complaint
                </div>
                <div className="col-md-2 form-group">
                  <input type="radio" name="typeOfGrievance" id="Suggestion" value="Suggestion" />
                  Suggestion
                </div>
                <div className="col-md-2 form-group">
                  <input
                    type="radio"
                    name="typeOfGrievance"
                    id="Guidance"
                    value="Seeking Guidance/Information"
                  />
                  Seeking Guidance/ Information
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="card container">
          <h5 class="card-header">Grievance Details</h5>
          <div class="card-body">
            <div className="form">
              <div className="form-group">
                <label for="Subject">Subject</label>
                <input type="text" id="Subject" className="form-control" placeholer="Subject" />
              </div>
              <div className="form-group">
                <label for="">Complaint/Suggestion</label>
                <input type="text" className="form-control" placeholder="Complaint/suggestion" />
              </div>
            </div>
          </div>
        </div>
        <div class="card container">
          <h5 class="card-header">Contact Details</h5>
          <div class="card-body">
            <form onSubmit={this.handleChange}>
              <div className="row">
                <div className="col-md-6">
                  <label className="labelAlign">
                    First Name<span className="redColor">*</span>
                  </label>
                  <input type="text" className="form-control" placeholder="Enter Complainee Name" />
                </div>
                <div className="col-md-6">
                  <label className="labelAlign">
                    First Name<span className="redColor">*</span>
                  </label>
                  <input type="text" className="form-control" placeholder="Enter State/UT" />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label className="labelAlign">
                    First Name<span className="redColor">*</span>
                  </label>
                  <input
                    type="text"
                    rows="6"
                    className="form-control"
                    style={{ height: '150px' }}
                    placeholder="Enter Complainant Address"
                  />
                </div>
                <div className="row col-md-6">
                  <div className="col-md-12">
                    <label className="labelAlign">
                      First Name<span className="redColor">*</span>
                    </label>
                    <input type="text" className="form-control" placeholder="Enter District" />
                  </div>
                  <div className="col-md-12">
                    <label className="labelAlign">
                      First Name<span className="redColor">*</span>
                    </label>
                    <input type="text" className="form-control" placeholder="Email Id" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label className="labelAlign">
                    First Name<span className="redColor">*</span>
                  </label>
                  <input type="text" id="pincode" className="form-control" placeholder="PinCode" />
                </div>
                <div className="col-md-6">
                  <label className="labelAlign">
                    First Name<span className="redColor">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="mobileNumber"
                    placeholder="Mobile Number"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
        <center className="submitButtonGrievance">
          <button className="btn btn-success " style={{ width: '30%' }}>
            Submit
          </button>
        </center>
      </React.Fragment>
    );
  }
}

export default Grievance;
