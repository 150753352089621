import React, { Component } from 'react'
import './contact.css';
import Navbar from '../Navbar/Navbar';
import {Helmet} from 'react-helmet';
import SimpleReactValidator from "simple-react-validator";
// import { onBlurClientSideValidation } from '../../utils/validator/validator';
import Axios from 'axios';
import { ApiUrl, Error_Type } from '../../Utils/globalConst/ApiConfig';
import Swal from 'sweetalert2';


const initialState={
  ui_name: '',
  ui_company_name: "",
  ui_contact_number: '',
  ui_message: '',
  ui_email: '',
  ui_subject:'',
  validationMessage: {},
  serverSideValidation: []
}
export class Contact extends Component {
  constructor(props){
    super(props);
    this.validator = new SimpleReactValidator({autoForceUpdate: this});
   };
   componentWillMount(){
     this.setState({...initialState});
   }
   handleChange = (event)=>{
      event.preventDefault();
      let targetName = event.target.name;
      if(targetName==="ui_contact_number" && !(/^(\s*|\d+)$/).test(event.target.value)){
        return;
      }
      this.setState({
        [event.target.name] : event.target.value,
      },()=>{
        console.log('object ',this.state);
        if(this.state.serverSideValidation.includes(targetName)){
          let newValidationFailure=this.state.serverSideValidation.filter((string)=>{
              return string!==targetName
          })
          this.setState({
            serverSideValidation : newValidationFailure
          })
        }
      })
    };
   onSubmitForm=async (event)=>{
    event.preventDefault();
    console.log('submit Form');
    this.setState({
      submit: true,
    })
    if(this.validator.allValid()){
      this.validator.hideMessageFor("name");
      this.validator.hideMessageFor("company name");
      this.validator.hideMessageFor("email");
      this.validator.hideMessageFor("contact number");
      this.validator.hideMessageFor("subject");
      this.validator.hideMessages();
      const { ui_name,ui_contact_number,ui_company_name,ui_subject,ui_message,ui_email } = this.state;
      const requestBody={
        ui_name,
        ui_contact: ui_contact_number,
        ui_company: ui_company_name,
        ui_subject,
        ui_message,
        ui_email,
        ui_product_code: 'FSJARS'
      }
      console.log('requestBody ',requestBody);
      try{
        const response= await Axios.post(ApiUrl.Api+"/enquiry/add",requestBody)
        if(response){
          console.log(response);
          Swal.fire({
            title: 'Successful',
            text: response.data.data.description,
            type: 'success',
            confirmButtonColor: '#7cce60',
          })
          this.setState({
            submit: false,
            ...initialState,
            // messageShown: false
          },()=>{
            this.validator.hideMessageFor("name");
            this.validator.hideMessageFor("company name");
            this.validator.hideMessageFor("email");
            this.validator.hideMessageFor("contact number");
            this.validator.hideMessageFor("subject");
            this.validator.hideMessages();
            this.forceUpdate();
          })
        }
      }catch(error){
        console.log(error.response);
        switch (error.response.data.type) {
          case Error_Type.Validation_Error: {
            console.log("Validation Error");
            var validationFailure = [];
            error.response.data.errors.forEach(error => {
              if(error.field==='ui_name'){
                validationFailure.push('ui_name');
              }
              if(error.field==='ui_contact'){
                validationFailure.push('ui_contact_number');
              }
              if(error.field==='ui_subject'){
                validationFailure.push('ui_subject');
              }
              if(error.field==='ui_company'){
                validationFailure.push('ui_company_name');
              }
              if(error.field==='ui_email'){
                validationFailure.push('ui_email');
              }
            });
            this.setState({
              submit: false,
              serverSideValidation : validationFailure 
            });
            Swal.fire(
              'Oops...',
              error.response.data.message,
              'error'
            )
            // alert(error.response.data.message);
            break;
          }
          case Error_Type.Db_Error: {
            console.log("db Error");
            this.setState({
              submit: false
            })
            alert(error.response.data.message);
            break;
          }
          case Error_Type.Internal_Error: {
            console.log("Internal Error");
            this.setState({
              submit: false
            })
            alert(error.response.data.message);
            break;
          }
          case Error_Type.Fatal_Error: {
            console.log("Fatal Error");
            this.setState({
              submit: false
            })
            alert(error.response.data.message);
            break;
          }
          case Error_Type.Auth_Error: {
            console.log("Auth_Error");
            this.setState({
              submit: false
            })
            alert(error.response.data.message);
            break;
          }
          default: {
            console.log("default case hitted");
            alert(error);
            this.setState({
              submit: false
            })
            break;
          }
        }
      }
    }
    else{
      console.log('sjvbs');
      this.validator.showMessages();
      this.setState({
        submit: false
      })
    }
  }
  render() {
    const { serverSideValidation,submit }= this.state;
  return (
    <div>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Contact Us</title>
    </Helmet>
    <section id="contact" className='contactUsPage'>
        <div className="" id="contact">
          <div className="">
            <div className="row">
            <div className="col-12">
                <h2 className="text-center-getintouch">Contact</h2>
                <hr className="below-header-hr"></hr>
              </div>
            </div>

            <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12 text-center custom-headers">
                  <h2 className="text-center-contact">Find  <span className="custom-h2">Us</span></h2>
                  <hr className="below-header-hr"></hr>
                <p className="below-para text-center-contact"><em>Pune Office</em></p>
                  <div className="map">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3783.272883352964!2d73.84732259405276!3d18.51656667796784!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c070ba0a0d0b:0x149c50f0c41f9750!2sFSJARS,+576,+Narayan+Peth,+Pune,+Maharashtra+411030!5e0!3m2!1sen!2sin!4v1521229510125" frameborder="0" allowfullscreen></iframe>
                  </div>
                  <hr className="below-header-hr"></hr>
                <p className="below-para text-center-contact"><em>Indore Office</em></p>
                  <div className="map">
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1840.165817457581!2d75.90830375808433!3d22.715911796277297!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962e335eb318f99%3A0x9c52e07942f68948!2sElipi%20Software%20Solutions!5e0!3m2!1sen!2sin!4v1571480741103!5m2!1sen!2sin"  frameborder="0" allowfullscreen=""></iframe>
                    {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3783.272883352964!2d73.84732259405276!3d18.51656667796784!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c070ba0a0d0b:0x149c50f0c41f9750!2sFSJARS,+576,+Narayan+Peth,+Pune,+Maharashtra+411030!5e0!3m2!1sen!2sin!4v1521229510125" frameborder="0" allowfullscreen></iframe> */}
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12 text-center custom-headers">
                  <div className="leaveAMessage">
                  <h2 className="text-center-contact">Leave  <span className="custom-h2">a message</span></h2>
                  <hr className="below-header-hr"></hr>
                  <p className="below-para text-center-contact"><em>We will give you the help you need.</em></p>
                <div className="custom-form">
                  <form onSubmit={this.onSubmitForm}>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label for="inputName" className="inputLabel">Name<span className='redColorStar'>*</span></label>
                        <input type="text" class="form-control input-contact" value={this.state["ui_name"]} name="ui_name" onChange={this.handleChange} id="inputEmail4" onBlur={() => this.validator.showMessageFor('name')} placeholder="Name" />
                        <div className="text-danger">
                          {this.validator.message(
                            "name",
                            this.state["ui_name"],
                            "required|alpha_space|min:3|max:50"
                          )}
                        </div>
                        {serverSideValidation.includes('ui_name') && <div className='text-danger'>Name should be at-least 3 characters long</div>}
                      </div>
                      <div className="form-group col-md-6">
                        <label for="inputPhone" className="inputLabel">Phone<span className='redColorStar'>*</span></label>
                        <input type="text" class="form-control input-contact" value={this.state["ui_contact_number"]} name="ui_contact_number" id="inputPassword4" placeholder="Contact Number" onChange={this.handleChange} onBlur={() => this.validator.showMessageFor('contact number')}/>
                        <div className='text-danger'>{this.validator.message('contact number', this.state["ui_contact_number"], "required|numeric|min:10|max:10")}</div>
                        {serverSideValidation.includes('ui_contact_number') && <div className='text-danger'>Mobile number is not valid please check if you have entered a 10 digit mobile number</div>}
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label for="inputEmail" className="inputLabel">Email<span className='redColorStar'>*</span></label>
                          <input type="text" class="form-control input-contact" value={this.state["ui_email"]} name="ui_email" id="inputAddress" placeholder="Your email address" data-toggle="popover" data-trigger="focus" data-placement="bottom" data-content="We don't spam you mailbox" onChange={this.handleChange} onBlur={() => this.validator.showMessageFor('email')} />
                          <div className='text-danger'>{this.validator.message('email', this.state["ui_email"], 'required|email')}</div>
                          {serverSideValidation.includes('ui_email') && <div className='text-danger'>Please enter a valid email-id</div>}
                        </div>
                      </div>
                      <div className="form-group col-md-6">
                        <label for='inputCompany' className='inputLabel'>Company<span className='redColorStar'>*</span></label>
                        <input type="text" name="ui_company_name" value={this.state["ui_company_name"]} onChange={this.handleChange}  class="form-control input-contact" id="inputPassword4" onBlur={() => this.validator.showMessageFor('company name')} placeholder="Institute Name" />
                        <div className="text-danger">
                          {this.validator.message(
                            "company name",
                            this.state["ui_company_name"],
                            "required|alpha_num_space|min:3|max:50"
                          )}
                        </div>
                        {serverSideValidation.includes('ui_company_name') && <div className='text-danger'>Company Name should be at-least 3 characters long</div>}
                      </div>
                      <div className="form-group col-md-12">
                        <label for='inputTitle' className='inputLabel'>Subject<span className='redColorStar'>*</span></label>
                        <input type="text" class="form-control input-contact" value={this.state["ui_subject"]} name="ui_subject" id="inputAddress2" placeholder="Your subject of this message" onChange={this.handleChange} onBlur={() => this.validator.showMessageFor('subject')}/>
                        <div className='text-danger'>{this.validator.message('subject', this.state["ui_subject"], "required|alpha_space|min:3")}</div>
                        {serverSideValidation.includes('ui_subject') && <div className='text-danger'>Subject should be at-least 3 characters long</div>}
                      </div>
                    </div>
                    <div className="form-group">
                      <label for="inputMessage" className="inputLabel">Message</label>
                      <textarea rows='5' type="text" value={this.state["ui_message"]} name="ui_message" onChange={this.handleChange} className="form-control" id="inputMessage" placeholder="Enter your message" />
                    </div>
                    <br/>
                    {!submit ? <button type="submit" class="btn btn-success input-contact sbmtbtn">
                      <b>SUBMIT</b>
                    </button> : 
                    <button class="btn btn-primary input-contact sbmtbtn" type="submit" disabled>
                      <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                      Loading...
                    </button>}
                </form>
               </div>
                </div>
                </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    )
  }
}
export default Contact;
