import React from 'react';
import { Helmet } from 'react-helmet';
import './E-Com.css';
class eComDev extends React.Component {
  constructor(props) {
    super(props);
    this.initialState = {
      isShow: true,
    };
  }

  componentWillMount() {
    this.setState(this.initialState);
  }
  componentDidMount() {
    // window.addEventListener('scroll', (event) => {
    //     this.myFunction();
    // })
  }
  componentWillUnmount() {
    this.setState(this.initialState);
  }
  handleChange = event => {
    event.preventDefault();
    document.querySelector('.learnMoreText').classList.toggle('hide');
    this.setState({
      isShow: !this.state.isShow,
    });
  };
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>E-Commerce Solution</title>
        </Helmet>
        {/* <header className="eComDevHeader">
          <div className="eComDevImage row">
            <div className="transparentLayer" />
            <img  src={eComDev} alt="eComDevImage" className='col-12'/>
          </div>
          <div className="eComDevTitle">
            <center>
              <strong>
                <span className="eComDevTitle1">Ecommerce</span> Services
              </strong>
            </center>
          </div>
        </header> */}
        <div className="genericContainer genericContainerHeader">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-6 col-12">
              <div className="hero-software content hero-feature-content">
                <strong>
                  <span className="TeamPageTitle1">ECOMMERCE</span> SERVICES
                </strong>
                {/* <p className="alignCenter">Please read the document carefully. </p> */}
              </div>
            </div>
          </div>
        </div>
        <div className="eComDevContent container">
          <div className="eComDevSection1">
            <br />
            <center className="eComDevContentTagLine">
              <i class="fa fa-quote-left" aria-hidden="true" />
              <h4>
                <strong className="eComDevContentTagLineTitle">
                  The force behind your IT infrastructure
                </strong>
              </h4>
            </center>
            <p className="eComDevIntro">
              <center>
                We transform your brilliant business ideas into visually appealing websites with
                online shopping tools.
              </center>
            </p>
            {this.state.isShow && (
              <div className="learnMore">
                <div className="webDesign">
                  <div className="webDesignContent">
                    <p>
                      E-commerce development is essential in today’s age where people with a
                      thriving business can expand their consumer base. Online shopping solutions
                      are a treasure trove in today’s digital world which is keen on getting
                      transactions done online. A user-friendly website with all your products on
                      display will make the user aware of not only a single variety of items, but
                      many other varieties which are organized into different tabs.
                    </p>
                    <p>
                      Your company can have several options where you can customize your own
                      shopping cart so that your shopping store remains unique and distinct. Your
                      unique business process and characteristics will be apparent from the shopping
                      portal that is built for your company. When you lean towards an e-commerce
                      business, you become independent of geographical restrictions, much unlike the
                      brick and mortar stores. Your e-commerce business can be enhanced to perform
                      globally since these local restrictions can be eliminated.
                    </p>
                    <p>
                      Security is the predominant task for customers and companies. Our experts
                      incorporate payment gateway options which can link all the cards with utmost
                      privacy and ensure customer satisfaction this way. We use SSL encryption in
                      the sites developed for you for the safety of all customers who enter their
                      information online.
                    </p>
                    <p>
                      We also use SEO in order to keep your websites on the top of Google search
                      results so that maximum traffic is directed to your website. Our team has deep
                      knowledge and will ensure that the site is optimized because we know that most
                      users try to access the site through their mobile phones while multitasking
                      and will move forward to another site if your website doesn’t load fast
                      enough.
                    </p>
                    <p>
                      We also focus on site search and responsive web pages which make your company
                      website available on every platform whether it is a mobile tablet or a
                      desktop. Site search is important because most users search for their products
                      and easy search options become a necessity for e-commerce websites. You can be
                      free of worry when you come to FSJARS for the solutions to your business
                      needs. We will listen to all your concerns and provide the best output with
                      our wide range of resources whether you own a small shop or a large showroom
                    </p>
                  </div>
                </div>
                {/* <div className="webAppDevelopment">
                                    <div className="webAppDevTitle">
                                        <strong>Web Application Development</strong>
                                    </div>
                                    <div className="webAppDevContent">
                                    Web application development involves the crucial task of creating applications which can be accessed by the user’s device via the internet and these applications reside on remote servers. The client-server software application runs in the web browser of the client.
                                        The client portion of the website is designated as the front end whereas the 
                                    server side involves using a powerful programming language, a database, and the server which together constitute the backend. The front end and the back end combine to create user-friendly and attractive web applications.
                                        Web applications that are created by our company are robust and secure. Our 
                                    expertise in creating web applications that implement well-thought-out strategies for solving problems, executing a process, <strong>mobile-first approach</strong>, and making a repetitive mundane task automated along with thoughtful and important reports that will elevate your business online.
                                        Our company uses technologies such as MEAN (Mongo/MySQL, Express, AngularJS, 
                                    and NodeJS) stack or MERN (Mongo/MySQL, Express, ReactJS, and NodeJS) that are modern and strong technologies which are well suited for your business. Our work is absolutely flawless and customized to suit your needs. We also provide <strong>end-to-end support</strong> because we will leave you only when you become a raging success. 
                                    </div>
                                </div> */}
              </div>
            )}
            {/* <div className="eComDevService">
                                We also provide these services for website and web application such as-
                        </div> */}
            {/* <div className="eComDevContent">
                            <div className="eComDevDesign">
                                <div className="eComDevDesignTitle webServicesTitle">
                                    <strong>Multilingual website design</strong>
                                </div>
                                <div className="eComDevDesignContent">
                                    If you wish to create a website that caters to the needs of customers all over the world, then you can stop your search here. In today’s world where internationalization knocks on every door of every business, the need for multilingual websites to comprehend and visualize consumer demands from all over the world warrants the need to know their language. Multilingual websites could be the way to increase the existing customer base and sales volumes exponentially because a bridge between your business and a customer from a separate part of the world has been created. We are pioneers of conjuring robust and creative multilingual websites which will be the innovation that gives your business a competitive advantage over others. Our services will leave you with a growing international consumer base.
                                </div>
                            </div>
                            <div className="eComDevSEO">
                                <div className="eComDevSEOTitle webServicesTitle"> <strong>Search Engine Optimization (SEO)</strong></div>
                                <div className="eComDevSEoContent">
                                    Search results on the internet can be manipulated in order to drive more quality and quantity of traffic on your website through the ‘organic’, ‘editorial’, ‘natural’, and ‘free’ search results that are present on search engines. Optimization includes the part where the content management system of the website where people put up content that is deliberately made attractive enough for search engines to match the queries of the user exactly to the website. Our company consists of experts who can ensure that your website is search engine optimized and make sure that the user traffic that enters your website is relevant to your product or service.
                                </div>
                            </div>
                            <div className="eComDevHosting">
                                <div className="eComDevHostingTitle webServicesTitle"><strong> Web hosting services</strong></div>
                                <div className="eComDevHostingContent">
                                    We are providers of top-notch web hosting services where we manage the technology employed for connecting your website to the internet. We provide the services for hosting your website which is a necessity as it physically locates your website on the internet and we maintain the server where your website and its associated data resides. We accurately gauge the amount of bandwidth and disk space your website requires based on the interaction and traffic your website attains. The items and content (photos, maps, and PDF files) that is present on your website determine the amount of disk space that is needed by your website.
                                </div>
                            </div>
                            <div className="eComDevFooter">
                                <center>
                                    <strong>
                                        With FSJARS, you are making the right decision of using our website design and web application services. Our quality of work will impact your business meaningfully and we are the stepping stone that will elevate your business and ensure its success.
                                    </strong>
                                </center>
                            </div>
                        </div> */}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default eComDev;
