import React, { Component } from 'react';
import 'bootstrap/dist/js/bootstrap';
import 'react-popper';
import {Route,Link,Switch} from  'react-router-dom'
import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer';
import Contact from './Components/Contact/Contact';
import Home from './Components/Home/Home';
import Main from './Main';

class App extends Component {
  render() {
    return (
      <Switch>
          <Route path = "/" component = {Main} />
          <Route path = "/Contact" exact component = {Contact} />
      </Switch>
    );
  }
}

export default App;
