import React, { Component } from 'react';
import './MobDev.css';
import { Helmet } from 'react-helmet';
class MobDev extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShow: true,
    };
  }
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Mobile App Development</title>
        </Helmet>
        <div className="genericContainer genericContainerHeader">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-6 col-12">
              <div className="hero-software content hero-feature-content">
                <strong>
                  <span className="TeamPageTitle1">MOBILE APP</span> DEVELOPMENT
                </strong>
                {/* <p className="alignCenter">Please read the document carefully. </p> */}
              </div>
            </div>
          </div>
        </div>
        {/* <header className="mobDevHeader">
          <div className="mobDevImage row">
            <div className="transparentLayer" />
            <img  src={mobDev} alt="mobDevImage" className='col-12'/>
          </div>
          <div className="mobDevTitle">
            <center>
              <strong>
                <span className="mobDevTitle1">Mobile App</span> Development
              </strong>
            </center>
          </div>
        </header> */}
        <div className="mobDevContent container">
          <div className="mobDevSection1">
            <br />
            {/* <center className='mobDevContentTagLine'><i class="fa fa-quote-left" aria-hidden="true"></i><h4><strong className='mobDevContentTagLineTitle'>The force behind your IT infrastructure</strong></h4></center> */}
            <p className="mobDevIntro">
              <center>
                <strong>
                  We are focused on building new and innovative mobile applications to build new and
                  enhanced user experiences on mobile devices.
                </strong>
              </center>
            </p>
            {this.state.isShow && (
              <div className="learnMore">
                <div className="mobAppDesign">
                  <div className="mobAppDesignContent">
                    Mobile phones have become all the rage in India. They are the most popular
                    commodity in the country and the market is exponentially growing every day as
                    new contenders take the floor. Most consumers prefer using applications on their
                    smartphone rather than web applications. Multiple mobile platforms such as
                    iPhone, Blackberry, Android, and Symbian have come up in the recent and mobile
                    applications must be compatible with these platforms to function efficiently. At
                    FSJARS, you will definitely obtain a value for your money with our state-of-art
                    mobile application development services. Our services span a wide variety and we
                    work on native applications, hybrid applications, and HTML5 applications on iOS
                    and Android platforms to provide your company the best and optimized services.
                  </div>
                </div>
                <br />
              </div>
            )}
            <div className="mobDevService">Some information on the types of apps-</div>
            <div className="mobDevContent">
              <div className="mobDevDesign">
                <div className="mobDevDesignTitle mobServicesTitle">
                  <strong>Native Applications</strong>
                </div>
                <div className="mobDevDesignContent">
                  The software development tools and languages that are specific to the operating
                  system of a particular platform (iOS or Android). The meaning of ‘native’ is
                  implied as the application developed for a single platform only which determines
                  the performance of the device that uses that platform.
                </div>
              </div>
              <div className="mobDevSEO">
                <div className="mobDevSEOTitle mobServicesTitle">
                  {' '}
                  <strong>HTML5 Applications</strong>
                </div>
                <div className="mobDevSEoContent">
                  A ‘write-once-run-anywhere’ approach is followed by applications developed using
                  the robust universal web technologies such as HTML5, JavaScript, and CSS which are
                  well on their way to make mobile applications universal. These applications are
                  compatible over all platforms. They require miniscule changes for acquiring full
                  functionality in the OS of all platforms.
                </div>
              </div>
              <div className="mobDevHosting">
                <div className="mobDevHostingTitle mobServicesTitle">
                  <strong> Hybrid Applications</strong>
                </div>
                <div className="mobDevHostingContent">
                  The native system is invaded with a small container where an HTML5 application can
                  reside. These applications have a unique touch because they not only enjoy the
                  perks of HTML5 applications, but also enjoy the perks of the unique elements that
                  are contained in every native system.
                </div>
              </div>
              <div className="mobDevFooter">
                <center>
                  <strong>
                    FSJARS boasts of a well-equipped team which can create multi-functional mobile
                    applications that can solve all the needs of your organization.
                  </strong>
                </center>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default MobDev;
