import React from 'react';
import { Helmet } from 'react-helmet';
import './SupportAndMaint.css';
class supportMaint extends React.Component {
  constructor(props) {
    super(props);
    this.initialState = {
      isShow: true,
    };
  }
  componentWillMount() {
    this.setState(this.initialState);
  }
  componentDidMount() {
    // window.addEventListener('scroll', (event) => {
    //     this.myFunction();
    // })
  }
  componentWillUnmount() {
    this.setState(this.initialState);
  }
  handleChange = event => {
    event.preventDefault();
    document.querySelector('.learnMoreText').classList.toggle('hide');
    this.setState({
      isShow: !this.state.isShow,
    });
  };
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Software and Maintainance</title>
        </Helmet>
        <div className="genericContainer genericContainerHeader">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-6 col-12">
              <div className="hero-software content hero-feature-content">
                <strong>
                  <span className="TeamPageTitle1">SUPPORT & </span> MAINTAINANCE
                </strong>
                {/* <p className="alignCenter">Please read the document carefully. </p> */}
              </div>
            </div>
          </div>
        </div>
        {/* <header className="supportAndMaintHeader">
          <div className="supportAndMaintImage row">
            <div className="transparentLayer" />
            <img  src={supportAndMaint} alt="supportAndMaintImage" className='col-12'/>
          </div>
          <div className="supportAndMaintTitle">
            <center>
              <strong>
                Support & <span className="supportAndMaintTitle1"> Maintenance</span>
              </strong>
            </center>
          </div>
        </header> */}
        <div className="supportAndMaintContent container">
          <div className="supportAndMaintSection1">
            <br />
            <center className="supportAndMaintContentTagLine">
              <i class="fa fa-quote-left" aria-hidden="true" />
              <h4>
                <strong className="supportAndMaintContentTagLineTitle">
                  The force behind your IT infrastructure
                </strong>
              </h4>
            </center>
            <p className="supportAndMaintIntro">
              Our support will include everything from updating content of your website to provide
              additional features in your websites which can increase your business revenue.
              {/* <span onClick={this.handleChange} className='learnMoreText show'>[Learn More...]</span> */}
            </p>
            {this.state.isShow && (
              <div className="learnMore">
                <div className="webDesign">
                  <div className="webDesignTitle" />
                  <div className="webDesignContent">
                    <p>
                      Business is full of competition, especially when most of them have gone
                      online. Today everyone with a flourishing business is taking it online and
                      ensuring their success through dynamic web pages. However, a website that has
                      been developed needs to be maintained, just like a car. Website will be
                      outdated if not maintained regularly, which is what our team of experts will
                      be happy in doing for you.
                    </p>
                    <p>
                      We ensure that the website is updated regularly. Our website hosting services
                      have maintenance providers which regularly update our core server software.
                    </p>
                    <p>
                      The speed of the website is also increased to ensure that the website will
                      load faster on all devices. This will maintain the traffic on the website as
                      users usually opt for other websites if it takes a long time to upload your
                      website. We optimize your websites time and again after reviewing our speed
                      checking tools using several techniques so that the traffic diverted to your
                      website is magnificent.
                    </p>
                    <p>
                      We also back up the files which will ensure that you never lose your crucial
                      information and a backup will be performed regularly if you opt for our
                      services.
                    </p>
                    <p>
                      FSJARS believes in providing the best and wholesome services that will match
                      the business needs of your company and increase your customer base and
                      revenue. We provide unparalleled support and maintenance services that will
                      cause your website to stay at the top in a sea of a million customers.
                    </p>
                  </div>
                </div>
                {/* <div className="webAppDevelopment">
                                    <div className="webAppDevTitle">
                                        <strong>Web Application Development</strong>
                                    </div>
                                    <div className="webAppDevContent">
                                    Web application development involves the crucial task of creating applications which can be accessed by the user’s device via the internet and these applications reside on remote servers. The client-server software application runs in the web browser of the client.
                                        The client portion of the website is designated as the front end whereas the 
                                    server side involves using a powerful programming language, a database, and the server which together constitute the backend. The front end and the back end combine to create user-friendly and attractive web applications.
                                        Web applications that are created by our company are robust and secure. Our 
                                    expertise in creating web applications that implement well-thought-out strategies for solving problems, executing a process, <strong>mobile-first approach</strong>, and making a repetitive mundane task automated along with thoughtful and important reports that will elevate your business online.
                                        Our company uses technologies such as MEAN (Mongo/MySQL, Express, AngularJS, 
                                    and NodeJS) stack or MERN (Mongo/MySQL, Express, ReactJS, and NodeJS) that are modern and strong technologies which are well suited for your business. Our work is absolutely flawless and customized to suit your needs. We also provide <strong>end-to-end support</strong> because we will leave you only when you become a raging success. 
                                    </div>
                                </div> */}
              </div>
            )}
            {/* <div className="supportAndMaintService">
                                We also provide these services for website and web application such as-
                        </div>
                        <div className="supportAndMaintContent">
                            <div className="supportAndMaintDesign">
                                <div className="supportAndMaintDesignTitle webServicesTitle">
                                    <strong>Multilingual website design</strong>
                                </div>
                                <div className="supportAndMaintDesignContent">
                                    If you wish to create a website that caters to the needs of customers all over the world, then you can stop your search here. In today’s world where internationalization knocks on every door of every business, the need for multilingual websites to comprehend and visualize consumer demands from all over the world warrants the need to know their language. Multilingual websites could be the way to increase the existing customer base and sales volumes exponentially because a bridge between your business and a customer from a separate part of the world has been created. We are pioneers of conjuring robust and creative multilingual websites which will be the innovation that gives your business a competitive advantage over others. Our services will leave you with a growing international consumer base.
                                </div>
                            </div>
                            <div className="supportAndMaintSEO">
                                <div className="supportAndMaintSEOTitle webServicesTitle"> <strong>Search Engine Optimization (SEO)</strong></div>
                                <div className="supportAndMaintSEoContent">
                                    Search results on the internet can be manipulated in order to drive more quality and quantity of traffic on your website through the ‘organic’, ‘editorial’, ‘natural’, and ‘free’ search results that are present on search engines. Optimization includes the part where the content management system of the website where people put up content that is deliberately made attractive enough for search engines to match the queries of the user exactly to the website. Our company consists of experts who can ensure that your website is search engine optimized and make sure that the user traffic that enters your website is relevant to your product or service.
                                </div>
                            </div>
                            <div className="supportAndMaintHosting">
                                <div className="supportAndMaintHostingTitle webServicesTitle"><strong> Web hosting services</strong></div>
                                <div className="supportAndMaintHostingContent">
                                    We are providers of top-notch web hosting services where we manage the technology employed for connecting your website to the internet. We provide the services for hosting your website which is a necessity as it physically locates your website on the internet and we maintain the server where your website and its associated data resides. We accurately gauge the amount of bandwidth and disk space your website requires based on the interaction and traffic your website attains. The items and content (photos, maps, and PDF files) that is present on your website determine the amount of disk space that is needed by your website.
                                </div>
                            </div>
                            <div className="supportAndMaintFooter">
                                <center>
                                    <strong>
                                        With FSJARS, you are making the right decision of using our website design and web application services. Our quality of work will impact your business meaningfully and we are the stepping stone that will elevate your business and ensure its success.
                                    </strong>
                                </center>
                            </div>
                        </div> */}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default supportMaint;
