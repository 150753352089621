import React, { Component } from 'react';
import './Hosting.css';
import { Helmet } from 'react-helmet';
class Hosting extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Shared Hosting | Cloud Hosting | Dedicated Hosting</title>
        </Helmet>
        <div className="genericContainer genericContainerHeader">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-6 col-12">
              <div className="hero-software content hero-feature-content">
                <strong>
                  <span className="TeamPageTitle1">WEB HOSTING</span> SERVICES
                </strong>
                {/* <p className="alignCenter">Please read the document carefully. </p> */}
              </div>
            </div>
          </div>
        </div>
        {/* <header className="HostingHeader">
          <div className="HostingImage row">
            <div className="transparentLayer" />
            <img  src={Hosting} alt="HostingImage" className='col-12'/>
          </div>
          <div className="HostingTitle">
            <center>
              <strong>
                <span className="HostingTitle1">Web Hosting </span> Services
              </strong>
            </center>
          </div>
        </header> */}
        <div className="HostingContent container">
          <p>
            We are providers of top-notch web hosting services where we manage the technology
            employed for connecting your website to the internet. We provide the services for
            hosting your website which is a necessity as it physically locates your website on the
            internet and we maintain the server where your website and its associated data resides.
            We accurately gauge the amount of bandwidth and disk space your website requires based
            on the interaction and traffic your website attains. The items and content (photos,
            maps, and PDF files) that is present on your website determine the amount of disk space
            that is needed by your website.
          </p>
        </div>
      </React.Fragment>
    );
  }
}

export default Hosting;
