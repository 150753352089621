import React from 'react';
import './BecomeOurPartner.css';
import { Helmet } from 'react-helmet';
class BecomeOurPartner extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Become Our Partner</title>
        </Helmet>

        {/* <div className="BecomeOurPartnerHeader">
                    <div className="BecomeOurPartnerImage row">
                    <div className="transparentLayer">
                            
                        </div>
                        <img src={WhyUsImage} className='col-12' alt="whyUsImage"/>
                    </div>
                    <div className="BecomeOurPartnerTitle">
                        <center><strong><span className='BecomeOurPartnerTitle1'>Become  </span> Our Partner</strong></center>
                    </div>
                </div> */}
        <div className="genericContainer FAQheader genericContainerHeader">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-6 col-12">
              <div className="hero-software content hero-feature-content">
                <strong>
                  <span className="TeamPageTitle1">Become </span> Our Partner
                </strong>
                {/* <p className="alignCenter">Please read the document carefully. </p> */}
              </div>
            </div>
          </div>
        </div>
        <div className="BecomeOurPartnerContent container">
          <div className="BecomeOurPartnerSection1 row">
            <div className="BecomeOurPartnerSection1Head" />
            <div className="BecomeOurPartnerSection1Content" />
          </div>
          <div className="BecomeOurPartnerSection2 row">
            <div className="BecomeOurPartnerSection2Head BecomeOurPartnerSectionHead col-md-12">
              <span>
                <strong>1. Do you wish to boost revenue?</strong>
              </span>
            </div>
            <div className="BecomeOurPartnerSection2Content BecomeOurPartnerSectionContent col-md-12">
              <span>
                You can boost your revenues by taking up complicated projects that have new and
                improved technologies.
              </span>
            </div>
          </div>
          <div className="BecomeOurPartnerSection3 row">
            <div className="BecomeOurPartnerSection3Head BecomeOurPartnerSectionHead col-md-12">
              <span>
                <strong>2. Do you want to improve your margins?</strong>
              </span>
            </div>
            <div className="BecomeOurPartnerSection3Content BecomeOurPartnerSectionContent col-md-12">
              <span>
                You can outsource some tasks which are not core tasks and can be completed by a
                partner.
              </span>
            </div>
          </div>
          <div className="BecomeOurPartnerSection4 row">
            <div className="BecomeOurPartnerSection4Head BecomeOurPartnerSectionHead col-md-12">
              <span>
                <strong>3. Do you want timely completion of your project?</strong>
              </span>
            </div>
            <div className="BecomeOurPartnerSection4Content BecomeOurPartnerSectionContent col-md-12">
              <span>
                Your projects will be completed at the stipulated deadline with a quality that is
                off the charts.
              </span>
            </div>
          </div>
          <div className="BecomeOurPartnerSection5 row">
            <div className="BecomeOurPartnerSection5Head BecomeOurPartnerSectionHead col-md-12">
              <span>
                <strong>4. Are you good at marketing and sales?</strong>
              </span>
            </div>
            <div className="BecomeOurPartnerSection5Content BecomeOurPartnerSectionContent col-md-12">
              <span>
                But you lack a team to execute your instructions and money to complete your
                projects. Our resources will propel your business forward.
              </span>
            </div>
          </div>
          <div className="BecomeOurPartnerFooter row">
            <center>
              <span className="BecomeOurPartnerFooterHead">
                <strong>FSJARS is the place for you!!</strong>
              </span>
              <div className="BecomeOurPartnerFooterContent">
                We have a keen interest in making you happy. We believe that your business is in the
                right hands when you work with us. We have a skill set that matches every business
                need of yours and we communicate thoroughly. We are passionate about what we do and
                our partnership will make a power-packed duo that will leave every competitor
                behind. We value you.
              </div>
            </center>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default BecomeOurPartner;
