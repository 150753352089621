import React from 'react';
import './CustSoftProd.css';
import { Helmet } from 'react-helmet';
class CustomerSoftProduct extends React.Component {
  constructor(props) {
    super(props);
    this.initialState = {
      isShow: true,
    };
  }
  componentWillMount() {
    this.setState(this.initialState);
  }
  componentDidMount() {
    // window.addEventListener('scroll', (event) => {
    //     this.myFunction();
    // })
  }
  componentWillUnmount() {
    this.setState(this.initialState);
  }
  handleChange = event => {
    event.preventDefault();
    document.querySelector('.learnMoreText').classList.toggle('hide');
    this.setState({
      isShow: !this.state.isShow,
    });
  };
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Custom Software Product</title>
        </Helmet>
        {/* <header className="customerSoftProductHeader">
          <div className="customerSoftProductImage row">
            <div className="transparentLayer" />
            <img  src={customerSoftProduct} alt="customerSoftProductImage" className='col-12'/>
          </div>
          <div className="customerSoftProductTitle">
            <center>
              <strong>
                Product<span> Development</span>
              </strong>
            </center>
          </div>
        </header> */}
        <div className="genericContainer genericContainerHeader">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-6 col-12">
              <div className="hero-software content hero-feature-content">
                <strong>
                  <span className="TeamPageTitle1">PRODUCT</span> DEVELOPMENT
                </strong>
                {/* <p className="alignCenter">Please read the document carefully. </p> */}
              </div>
            </div>
          </div>
        </div>
        <div className="customerSoftProductContent container">
          <div className="customerSoftProductSection1">
            <br />
            <center className="customerSoftProductContentTagLine">
              <i class="fa fa-quote-left" aria-hidden="true" />
              <h4>
                <strong className="customerSoftProductContentTagLineTitle">
                  The force behind your IT infrastructure
                </strong>
              </h4>
            </center>
            <p className="customerSoftProductIntro">
              We can automate your business operations with the help of custom development and help
              you manage your operations more efficiently. We will translate your manual operations
              into automated web applications.
              {/* <span onClick={this.handleChange} className='learnMoreText show'>[Learn More...]</span> */}
            </p>
            {this.state.isShow && (
              <div className="learnMore">
                <div className="customerProductDesign">
                  <div className="customerProductTitle" />
                  <div className="customerProductContent">
                    <p>
                      Product development is the process of conceptualizing a business idea into a
                      tangible product that can be used on a commercial scale. The process of
                      product development is very important as it reflects the company and its
                      business processes and hence it should be organized and well-defined.
                    </p>
                    <p>
                      The stages of product development include
                      <ol>
                        <li>The market need is identified.</li>
                        <li>The product is conjured and designed using various tools.</li>
                        <li>The product roadmap is created.</li>
                        <li> The minimum viable product (MVP) is developed.</li>
                        <li>The MVP is released to the users.</li>
                        <li>
                          The product is then changed and modified and improved based on the
                          feedback provided by the users.
                        </li>
                      </ol>
                    </p>
                    <p>
                      The roadmap helps in defining the major milestones and key objectives of the
                      product that is to be created. This roadmap is a clear visualization of how
                      the product looks and the timeline needed to create the product with all its
                      parts and how it will finally fare in the market. You can be assured that your
                      company is in good hands with our team of experts.
                    </p>

                    <p>
                      FSJARS is the one stop location to all your product development needs. We have
                      the resources and manpower that your business requires and we customize the
                      product as per your demands. We create a product that leaves your customers
                      happy and satisfied and increase your market value and customer base through
                      top-grade services.
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CustomerSoftProduct;
