import React, { Component } from 'react';
import './FAQ.css';
import { Helmet } from 'react-helmet';
class FAQ extends Component {
  state = {};
  componentDidMount() {
    var acc = document.getElementsByClassName('accordion');
    var i;
    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener('click', function() {
        this.classList.toggle('active');
        this.classList.toggle('opened');
        var selected = this;
        var panel = this.nextElementSibling;
        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
        } else {
          panel.style.maxHeight = panel.scrollHeight + 'px';
        }
        for (let j = 0; j < acc.length; j++) {
          if (acc[j] != selected) {
            console.log('hey');
            acc[j].classList.remove('active');
            acc[j].classList.remove('opened');
            var panel = acc[j].nextElementSibling;
            if (panel.style.maxHeight) {
              panel.style.maxHeight = null;
            }
          } else {
            console.log('its equal');
          }
        }
      });
    }
  }
  render() {
    return (
      <div className="FAQ">
        <Helmet>
          <meta charSet="utf-8" />
          <title>FAQ</title>
        </Helmet>
        <div className="genericContainer FAQheader genericContainerHeader">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-6 col-12">
              <div className="hero-software content hero-feature-content">
                <strong>
                  <span className="TeamPageTitle1">FAQ'</span>S
                </strong>
                {/* <p className="alignCenter">Please read the document carefully. </p> */}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="FAQheader">
          <div className="FAQImage row">
            <div className="transparentLayer" />
            <img  src={aboutUs} alt="aboutUsImage" className='col-12'/>
          </div>
          <div className="FAQTitle">
            <strong>
              <span className="FAQTitle1">FAQ'</span>S
            </strong>
          </div>
        </div> */}
        <div className="container">
          <div className="row">
            <div className="col-md-6 askedQuestions">
              <div className="question">
                <button className="accordion">
                  <strong>How costly is website designing?</strong>
                  <span className="toggle-plus">
                    <i className="plus fa fa-plus-circle" />
                  </span>
                  <span className="toggle-minus">
                    <i className="minus fa fa-minus-circle" />
                  </span>
                </button>
                <div className="panel">
                  <p>
                    For a business such as yours which has ample potential, we design the website
                    free of cost. There are several types of websites such as dynamic websites,
                    static websites, e-commerce websites, multilingual websites, company websites,
                    corporate websites, personal websites, and blogs.{' '}
                  </p>
                  <p>
                    FSJARS is ready to help you with all digital services that will elevate your
                    business to the top.
                  </p>
                </div>
              </div>
              <div className="question">
                <button className="accordion">
                  <strong>
                    What is the difference between website development and web app development?
                  </strong>
                  <span className="toggle-plus">
                    <i className="plus fa fa-plus-circle" />
                  </span>
                  <span className="toggle-minus">
                    <i className="minus fa fa-minus-circle" />
                  </span>
                </button>
                <div className="panel">
                  <p>
                    Websites are informational whereas web applications are interactive. This means
                    that even though they both have many overlapping attributes- access to the
                    internet, going there through the web browser, and having back-end and front-end
                    written in similar programming languages, they have one key difference that sets
                    them apart. A web application can be controlled by a user but a website is
                    static and only provides the information of the company to the user.{' '}
                  </p>
                </div>
              </div>
              <div className="question">
                <button className="accordion">
                  <strong>What will happen when I search for my products on Google?</strong>
                  <span className="toggle-plus">
                    <i className="plus fa fa-plus-circle" />
                  </span>
                  <span className="toggle-minus">
                    <i className="minus fa fa-minus-circle" />
                  </span>
                </button>
                <div className="panel">
                  <p>
                    With our fantastic SEO services, your website will have keywords that will match
                    the Google search queries you type and this will automatically bring your
                    website on the top of the Google search page. It will be the first to be seen
                    when anybody types in the keywords.{' '}
                  </p>
                </div>
              </div>
              <div className="question">
                <button className="accordion">
                  <strong>How do I see your work in order to hire your company?</strong>
                  <span className="toggle-plus">
                    <i className="plus fa fa-plus-circle" />
                  </span>
                  <span className="toggle-minus">
                    <i className="minus fa fa-minus-circle" />
                  </span>
                </button>
                <div className="panel">
                  <p>
                    You can check our portfolio section to view our diligent hard work. We are fully
                    dedicated to customer service and we are here for helping you out. You can also
                    click on the client reviews section to see how happy our clients are with our
                    wide range of services.{' '}
                  </p>
                  <p>
                    Once you have made the decision of hiring us, you can CONTACT US for working
                    with us because we are eager to work with you!
                  </p>
                </div>
              </div>
              <div className="question">
                <button className="accordion">
                  <strong>What other services do you offer?</strong>
                  <span className="toggle-plus">
                    <i className="plus fa fa-plus-circle" />
                  </span>
                  <span className="toggle-minus">
                    <i className="minus fa fa-minus-circle" />
                  </span>
                </button>
                <div className="panel">
                  <p>
                    We offer Web Development, Mobile Application, E-Commerce, Product development,
                    Support and maintenance, Machine Learning and AI services that will help your
                    business flourish. You can click on the Our Services option for further details.{' '}
                  </p>
                </div>
              </div>
              <div className="question">
                <button className="accordion">
                  <strong>What are the prices of your services?</strong>
                  <span className="toggle-plus">
                    <i className="plus fa fa-plus-circle" />
                  </span>
                  <span className="toggle-minus">
                    <i className="minus fa fa-minus-circle" />
                  </span>
                </button>
                <div className="panel">
                  <p>
                    Our website development services are free of cost for every budding business.
                    You can check our pricing list for our other services.{' '}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="askQuestion">
                Our doors are always open to help you… ask a question now!
              </div>
              <form className="row">
                <div className="col-md-12 FAQInput">
                  <input type="text" className="form-control" placeholder="First Name" />
                </div>
                <div className="col-md-12 FAQInput">
                  <input type="text" className="form-control" placeholder="Email Address" />
                </div>
                <div className="col-md-12 FAQInput">
                  <textarea type="text" className="form-control" placeholder="Message" rows="5" />
                </div>
                <div className="col-md-12">
                  <button type="button" className="btn btn-success">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FAQ;
