import React, { Component } from "react";
import "./Navbar.css";
import $ from "jquery";
// import { Link } from 'react-router-dom';
import Axios from "axios";
import SimpleReactValidator from "simple-react-validator";
import { ApiUrl, Error_Type } from "../../Utils/globalConst/ApiConfig";
import Swal from "sweetalert2";
import { NavHashLink as Link } from "react-router-hash-link";

window.jQuery = $;
window.$ = $;
global.jQuery = $;
var counter = 0;
var validEmailRegEx = /\S+@\S+\.\S+/;
var mobileNumber = /^[1-9]{1}[0-9]{9}$/;

const initialState = {
  ui_company: "",
  ui_contact: "",
  ui_email: "",
  ui_name: "",
  isModalShow: false,
  isLoginModal: false,
  ui_project_details: "",
  ui_solution_looking_for: "",
  clientSideValidation: [],
  serverSideValidation: [],
  ValidationFailure: [],
};
export class Navbar extends Component {
  constructor() {
    super();
    this.state = {
      solutionList: [],
      solutionLooking: [],
      serverSideValidation: [],
    };
    // this._handleScroll = this._handleScroll.bind(this);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
  }
  componentWillMount() {
    this.setState({ ...this.state, ...initialState });
  }
  componentDidMount() {
    // this._handleScroll();
    this.getSolutionList();
  }

  getSolutionList = async () => {
    try {
      const response = await Axios.get(
        ApiUrl.Api + "/solution_looking_for/values"
      );
      if (response) {
        const solutionList = response.data.data.items[0];
        this.setState({
          solutionList,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  handleChange = (event, index) => {
    console.log("handleChange");
    var targetName = event.target.name;
    if (targetName === "shouldRemember") {
      this.setState({
        shouldRemember: !this.state.shouldRemember,
      });
      return;
    }
    if (
      targetName === "ui_contact" &&
      !/^(\s*|\d+)$/.test(event.target.value)
    ) {
      return;
    }
    if (event.target.name == "ui_solution_looking_for") {
      const { solutionList } = this.state;
      this.setState({
        ui_solution_looking_for: solutionList[index],
      });
    } else {
      this.setState(
        {
          [event.target.name]: event.target.value,
        },
        () => {
            console.log("this.state ", this.state);
            if (this.state.ValidationFailure.includes(targetName)) {
              let newValidationFailure = this.state.ValidationFailure.filter(
                string => {
                  return string !== targetName;
                }
              );
              this.setState({
                ValidationFailure: newValidationFailure,
              });
            }
            if (this.state.clientSideValidation.includes(targetName)) {
              let newValidationFailure = this.state.clientSideValidation.filter(
                string => {
                  return string !== targetName;
                }
              );
              this.setState({
                clientSideValidation: newValidationFailure,
              });
            }
          }
      )
    }
  };
  storeLoginDetails = details => {
    localStorage.setItem("loginDetails", details);
  };
  retrieveLoginDetails = () => {
    try {
      let loginDetails = JSON.parse(localStorage.getItem("loginDetails"));
      if (loginDetails) {
        this.setState(
          {
            userName: loginDetails["ui_username"],
            password: loginDetails["ui_password"],
            instituteCode: loginDetails["ui_institute_code"],
            shouldRemember: loginDetails["shouldRemember"],
          },
          () => {
            console.log("state ", this.state);
          }
        );
      }
    } catch (error) {
      // Not getting loginDetails
    }
  };
  removedLoginDetails = () => {
    localStorage.setItem("loginDetails", "");
  };
  clientSideValidation = event => {
    event.preventDefault();
    var clientSideValidation = [];
    if (this.state.instituteCode.length === 0) {
      clientSideValidation.push("instituteCode");
    }
    if (this.state.userName.length === 0) {
      clientSideValidation.push("userName");
    }
    if (this.state.userName.length !== 0) {
      console.log("is valid email", validEmailRegEx.test(this.state.userName));
      console.log("is mobile Number", mobileNumber.test(this.state.userName));
      if (
        !(
          validEmailRegEx.test(this.state.userName) ||
          mobileNumber.test(this.state.userName)
        )
      ) {
        clientSideValidation.push("userName");
      }
    }
    if (this.state.password.length < 8) {
      clientSideValidation.push("password");
    }
    this.setState(
      {
        clientSideValidation: clientSideValidation,
      },
      () => {
        if (this.state.clientSideValidation.length === 0) {
          this.submit();
        }
      }
    );
  };
  submitLogin = () => {
    if (
      this.state.userName !== "" &&
      this.state.password !== "" &&
      this.state.instituteCode !== ""
    ) {
      console.log("submit");
      if (this.state.user === "student") {
        Swal.fire("Student login is not supported right now");
        return;
      }
      this.setState(
        {
          submit: true,
        },
        () => {
          // var element=document.getElementById('loginWrapper');
          // element.classList.add('overlayWrapper');
        }
      );
      var requestBody = {
        ui_institute_code: this.state.instituteCode,
        ui_username: this.state.userName,
        ui_password: this.state.password,
      };
      if (this.state.shouldRemember) {
        this.storeLoginDetails(
          JSON.stringify({
            shouldRemember: this.state.shouldRemember,
            ...requestBody,
          })
        );
      } else {
        this.removedLoginDetails();
      }
      console.log("requestBody", requestBody);
      Axios.post(ApiUrl.Api + "/employee/login", requestBody)
        .then(response => {
          console.log(response);
          // var {x-id-token}=response.headers
          this.setState(
            {
              submit: false,
            },
            () => {
              var authToken = response.headers["x-id-token"];
              localStorage.setItem("authToken", authToken);
              // alert('You Register Successfully');
              this.props.history.push("/dashboard");
              console.log("login Successful ");
            }
          );
        })
        .catch(error => {
          console.log(error.response);
          switch (error.response.data.type) {
            case Error_Type.Validation_Error: {
              console.log("Validation Error");
              this.setState(
                {
                  submit: false,
                },
                () => {
                  // alert(error.response.data.message);
                  var validationFailure = [];
                  error.response.data.errors.forEach(error => {
                    if (error.field === "ui_username") {
                      validationFailure.push("userName");
                    }
                    if (error.field === "ui_password") {
                      validationFailure.push("password");
                    }
                  });
                  this.setState(
                    {
                      ValidationFailure: validationFailure,
                    },
                    () => {
                      this.setState({
                        password: "",
                      });
                    }
                  );
                }
              );
              break;
            }
            case Error_Type.Db_Error: {
              console.log("db Error");
              this.setState(
                {
                  submit: false,
                },
                () => {
                  alert(error.response.data.message);
                }
              );
              break;
            }
            case Error_Type.Internal_Error: {
              console.log("Internal Error");
              this.setState(
                {
                  submit: false,
                },
                () => {
                  alert(error.response.data.message);
                }
              );
              break;
            }
            case Error_Type.Fatal_Error: {
              console.log("Fatal Error");
              this.setState(
                {
                  submit: false,
                },
                () => {
                  alert(error.response.data.message);
                }
              );
              break;
            }
            case Error_Type.Auth_Error: {
              console.log("Auth_Error");
              this.setState(
                {
                  submit: false,
                },
                () => {
                  alert(error.response.data.message);
                }
              );
              break;
            }
            default: {
              console.log("default case hitted");
              this.setState(
                {
                  submit: false,
                },
                () => {
                  alert(error.response.data.message);
                }
              );
              break;
            }
          }
        });
    } else {
      this.setState({
        Error: true,
      });
    }
  };
  toggleQuotesModal = async event => {
    console.log("state ", this.state);
    this.setState(
      {
        ...this.state,
        ...initialState,
        isModalShow: !this.state.isModalShow,
      },
      () => {
        console.log("toggle", this.state);
        this.validator.visibleFields = [];
        this.validator.hideMessages();
        // if(this.state.isModalShow){$('#modal.display-block').modal({backdrop: 'static', keyboard: false})  }
        // else{
        //   $('.modal-backdrop').remove();
        // }
      }
    );
  };
  onGetQuotes = async event => {
    event.preventDefault();
    console.log("submit Form");
    if (this.validator.allValid()) {
      this.setState({
        submit: true,
      });
      this.validator.visibleFields = [];
      this.validator.hideMessages();
      const {
        ui_name,
        ui_contact,
        ui_company,
        ui_project_details,
        ui_email,
        ui_solution_looking_for,
      } = this.state;
      const requestBody = {
        ui_name,
        ui_contact,
        ui_company,
        ui_project_details,
        ui_email,
        ui_solution_looking_for,
      };
      console.log("requestBody ", requestBody);
      try {
        const response = await Axios.post(
          ApiUrl.Api + "/get_quotes",
          requestBody
        );
        if (response) {
          this.setState({ ...initialState, submit: false, isModalShow: false });
          Swal.fire({
            title: "Successful",
            text: response.data.data.description,
            type: "success",
            confirmButtonColor: "#7cce60",
          });
          $("#quotesModal").modal("hide");
        }
      } catch (error) {
        console.log(error);
        switch (error.response.data.type) {
          case Error_Type.Validation_Error: {
            console.log("Validation Error");
            var validationFailure = [];
            error.response.data.errors.forEach(error => {
              if (error.field === "ui_name") {
                validationFailure.push("ui_name");
              }
              if (error.field === "ui_contact") {
                validationFailure.push("ui_contact");
              }
              if (error.field === "ui_company") {
                validationFailure.push("ui_company");
              }
              if (error.field === "ui_email") {
                validationFailure.push("ui_email");
              }
              if (error.field === "ui_solution_looking_for") {
                validationFailure.push("ui_solution_looking_for");
              }
            });
            this.setState({
              submit: false,
              serverSideValidation: validationFailure,
            });
            Swal.fire("Oops...", error.response.data.message, "error");
            break;
          }
          case Error_Type.Db_Error: {
            console.log("db Error");
            this.setState({
              submit: false,
            });
            Swal.fire("Oops...", error.response.data.message, "error");
            break;
          }
          case Error_Type.Internal_Error: {
            console.log("Internal Error");
            this.setState({
              submit: false,
            });
            Swal.fire("Oops...", error.response.data.message, "error");
            break;
          }
          case Error_Type.Fatal_Error: {
            console.log("Fatal Error");
            this.setState({
              submit: false,
            });
            Swal.fire("Oops...", error.response.data.message, "error");
            break;
          }
          case Error_Type.Auth_Error: {
            console.log("Auth_Error");
            this.setState({
              submit: false,
            });
            Swal.fire("Oops...", error.response.data.message, "error");
            break;
          }
          default: {
            console.log("default case hitted");
            alert(error);
            this.setState({
              submit: false,
            });
            break;
          }
        }
      }
    } else {
      this.validator.showMessages();
    }
  };
  // _handleScroll() {
  //   var sections = $('section'),
  //     nav = $('nav'),
  //     nav_height = nav.outerHeight();

  //   $(window).on('scroll', function() {
  //     var cur_pos = $(this).scrollTop();

  //     sections.each(function() {
  //       var top = $(this).offset().top - nav_height,
  //         bottom = top + $(this).outerHeight();

  //       if (cur_pos >= top && cur_pos <= bottom) {
  //         nav.find('a').removeClass('active');
  //         sections.removeClass('active');

  //         $(this).addClass('active');
  //         nav.find('a[href="#' + $(this).attr('id') + '"]').addClass('active');
  //       }
  //       var scrollHeight = $(document).height();
  //       var scrollPosition = $(window).height() + $(window).scrollTop();
  //       if ((scrollHeight - scrollPosition) / scrollHeight === 0) {
  //         nav.find('a').removeClass('active');
  //         sections.removeClass('active');

  //         $(this).addClass('active');
  //         nav.find('a[href="#' + $(this).attr('id') + '"]').addClass('active');
  //       }
  //     });
  // nav.find('a').on('click', function () {
  //   var $el = $(this)
  //     , id = $el.attr('href');
  //
  //   $('html, body').animate({
  //     scrollTop: $(id).offset().top - nav_height
  //   }, 500);
  //
  //   return false;
  // });
  //   });
  // }
  toggleLoginModal = () => {
    this.setState(
      {
        ...this.state,
        ...initialState,
        isLoginModal: !this.state.isLoginModal,
      },
      () => {
        console.log("toggle", this.state);
        this.validator.visibleFields = [];
        this.validator.hideMessages();
        // if(this.state.isModalShow){$('#modal.display-block').modal({backdrop: 'static', keyboard: false})  }
        // else{
        //   $('.modal-backdrop').remove();
        // }
      }
    );
  };
  closeModal=()=>{
    this.setState({
      isLoginModal: false,
      isModalShow: false
    })
  }
  loginModal = event => {
    event.preventDefault();
    Swal.fire("Login");
  };
  render() {
    const { solutionList, serverSideValidation, isLoginModal,isModalShow } = this.state;
    console.log(this.modal);
    return (
      <React.Fragment>
        <nav
          id="navbar"
          className="navbar fixed-top navbar-expand-sm navbar-light bg-light custom-upper-navbar"
        >
          <Link className="navbar-brand" to="/">
            <img
              src={require("../../img/logo.png")}
              className="img-responsive"
              width="100px"
              alt="logo"
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbar-collapse"
          >
            ☰
          </button>
          <div
            className="collapse navbar-collapse custom-below-navbar"
            id="navbar-collapse"
          >
            <ul className="nav navbar-nav ml-auto" id="navig-links">
              <li className="nav-item">
                {" "}
                <strong>
                  <Link to="/" className="nav-link">
                    HOME
                  </Link>
                </strong>
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link">
                  <strong>
                    COMPANY{" "}
                    <span className="arrowDown">
                      <i class="fa fa-caret-down" aria-hidden="true" />
                    </span>
                    <span className="arrowUp">
                      <i class="fa fa-caret-left" />
                    </span>
                  </strong>
                </a>
                <div className="dropdown-content">
                  <ul>
                    <Link to="/aboutUs">
                      <li>About Us</li>
                    </Link>
                    <Link to="/whyUs">
                      <li>Why Us</li>
                    </Link>
                    <Link
                      to="/#team"
                      onClick={() => {
                        this.props.history.push("/#team");
                      }}
                    >
                      <li>Team</li>
                    </Link>
                    <Link to="/ourPartner">
                      <li>Our Partners</li>
                    </Link>
                    {/* <Link to='/comingSoon'><li>Products</li></Link> */}
                    <Link to="/career">
                      <li>Careers</li>
                    </Link>
                  </ul>
                </div>
              </li>
              <li className="nav-item dropdown">
                {" "}
                <a className="nav-link">
                  <strong>
                    CLIENT{" "}
                    <span className="arrowDown">
                      <i class="fa fa-caret-down" aria-hidden="true" />
                    </span>
                    <span className="arrowUp">
                      <i class="fa fa-caret-left" />
                    </span>
                  </strong>
                </a>
                <div className="dropdown-content">
                  <ul>
                    <Link to="/portfolio">
                      <li>Portfolio</li>
                    </Link>
                    <Link
                      to="/#testimonial"
                      onClick={() => {
                        this.props.history.push("/#testimonial");
                      }}
                    >
                      <li>Testimonials</li>
                    </Link>
                    <Link to="/comingSoon">
                      <li>Case Studies</li>
                    </Link>
                    <Link to="/comingSoon">
                      <li>Client Login</li>
                    </Link>
                    <Link to="/comingSoon">
                      <li>Open Support Ticket</li>
                    </Link>
                  </ul>
                </div>
              </li>
              <li className="nav-item dropdown">
                {" "}
                <a className="nav-link">
                  <strong>
                    SERVICES{" "}
                    <span className="arrowDown">
                      <i class="fa fa-caret-down" aria-hidden="true" />
                    </span>
                    <span className="arrowUp">
                      <i class="fa fa-caret-left" />
                    </span>
                  </strong>
                </a>
                <div className="dropdown-content">
                  <ul>
                    <Link to="/webDevelopment">
                      <li>Web Development</li>
                    </Link>
                    <Link to="/mobDevelopment">
                      <li>Mobile Application</li>
                    </Link>
                    <Link to="/eCommerceDev">
                      <li>E-Commerce</li>
                    </Link>
                    <Link to="/customerSoftwareProduct">
                      <li>Custom Software Product</li>
                    </Link>
                    <Link to="/machineLearningAndAI">
                      <li>Machine Learning & AI</li>
                    </Link>
                    <Link to="/supportAndMaintenance">
                      <li>Support & Maintenance</li>
                    </Link>
                    <Link to="hosting">
                      <li>Hosting</li>
                    </Link>
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                {" "}
                <Link to="/contact" className="nav-link">
                  <strong>CONTACT</strong>
                </Link>
              </li>
              <li className="nav-item dropdown">
                {" "}
                <a className="nav-link">
                  <strong>
                    OTHER{" "}
                    <span className="arrowDown">
                      <i class="fa fa-caret-down" aria-hidden="true" />
                    </span>
                    <span className="arrowUp">
                      <i class="fa fa-caret-left" />
                    </span>
                  </strong>
                </a>
                <div className="dropdown-content">
                  <ul>
                    <Link to="/comingSoon">
                      <li>Blog</li>
                    </Link>
                    <Link to="/comingSoon">
                      <li>Pricing</li>
                    </Link>
                    <Link to="FAQ">
                      <li>FAQ</li>
                    </Link>
                    <Link to="/becomeOurPartners">
                      <li>Become a partner</li>
                    </Link>
                    <Link to="/comingSoon">
                      <li>News</li>
                    </Link>
                    <Link to="/comingSoon">
                      <li>Technologies</li>
                    </Link>
                  </ul>
                </div>
              </li>
              {/* <button className="getQuotes" onClick={this.toggleQuotesModal}>
                <strong>GET QUOTES</strong>
              </button> */}
              <li className="nav-item dropdown ">
                <a
                  class="nav-link getQuotes"
                  data-toggle="modal"
                  data-target="#quotesModal"
                  onClick={this.toggleQuotesModal}
                >
                  <strong>GET QUOTES</strong>
                </a>
              </li>
              <li className="nav-item dropdown ">
                <a
                  class="nav-link loginButton getQuotes"
                  data-toggle="modal"
                  data-target="#quotesModal"
                  onClick={this.toggleLoginModal}
                >
                  <strong>LOGIN</strong>
                </a>
              </li>
            </ul>
          </div>
        </nav>
        <div id="quotesModal" ref={element=>this.modal=element}  class="modal fade" role="dialog" data-backdrop="static" data-keyboard="false">
          <div class={!isLoginModal ? "modal-dialog  quotesModal modal-lg" : "modal-dialog"}>
            <div class="modal-content">
              <div class="modal-header">
                <div>
                  <center>
                    <h4 className="modal-title">
                      <strong>{!isLoginModal ? "Get Quote" : "Login"}</strong>
                    </h4>
                  </center>
                </div>
                <button
                  type="button"
                  class="close modalClose"
                  onClick={isLoginModal ? this.toggleLoginModal : this.toggleQuotesModal}
                  data-dismiss="modal"
                >
                  &times;
                </button>
              </div>
              <div class="modal-body">
                {!isLoginModal ? isModalShow ? (
                  <>
                    <div style={{ textAlign: "right" }}>
                      <strong class="color01">
                        Want Instant Response? &nbsp;
                      </strong>
                      <a
                        href="https://wa.me/919175514982"
                        target="_blank"
                        style={{ fontSize: "22px" }}
                      >
                        <i
                          className="fa fa-whatsapp"
                          style={{ color: "#7CCE60" }}
                        />
                      </a>
                    </div>
                    <form>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-6">
                            <label for="name" className="">
                              <strong>Name</strong>
                            </label>
                            <span className="redColor">*</span>
                            <input
                              type="text"
                              name="ui_name"
                              value={this.state["ui_name"]}
                              onBlur={() =>
                                this.validator.showMessageFor("name")
                              }
                              className="form-control"
                              id="name"
                              onChange={this.handleChange}
                            />
                            <div className="text-danger">
                              {this.validator.message(
                                "name",
                                this.state["ui_name"],
                                "required|alpha_space|min:3|max:50"
                              )}
                            </div>
                            {serverSideValidation.includes("ui_name") && (
                              <div className="text-danger">
                                Name should be at-least 3 characters long
                              </div>
                            )}
                          </div>
                          <div className="col-md-6">
                            <label for="contact" className="">
                              <strong>Contact No.</strong>
                            </label>
                            <span className="redColor">*</span>
                            <input
                              type="text"
                              name="ui_contact"
                              className="form-control"
                              id="contact"
                              onChange={this.handleChange}
                              value={this.state["ui_contact"]}
                              onBlur={() =>
                                this.validator.showMessageFor("contact number")
                              }
                            />
                            <div className="text-danger">
                              {this.validator.message(
                                "contact number",
                                this.state["ui_contact"],
                                "required|numeric|min:10|max:10"
                              )}
                            </div>
                            {serverSideValidation.includes("ui_contact") && (
                              <div className="text-danger">
                                Mobile number is not valid please check if you
                                have entered a 10 digit mobile number
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label for="email">
                          <strong>Email Id</strong>
                        </label>
                        <span className="redColor">*</span>
                        <input
                          type="email"
                          name="ui_email"
                          className="form-control"
                          onChange={this.handleChange}
                          id="email"
                          value={this.state["ui_email"]}
                          onBlur={() => this.validator.showMessageFor("email")}
                        />
                        <div className="text-danger">
                          {this.validator.message(
                            "email",
                            this.state["ui_email"],
                            "required|email"
                          )}
                        </div>
                        {serverSideValidation.includes("ui_email") && (
                          <div className="text-danger">
                            Please enter a valid email-id
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <label for="company">
                          <strong>Company</strong>
                        </label>
                        <span className="redColor">*</span>
                        <input
                          type="company"
                          value={this.state["ui_company"]}
                          name="ui_company"
                          className="form-control"
                          id="company"
                          onChange={this.handleChange}
                          onBlur={() =>
                            this.validator.showMessageFor("company name")
                          }
                        />
                        <div className="text-danger">
                          {this.validator.message(
                            "company name",
                            this.state["ui_company"],
                            "required|alpha_num_space|min:3|max:50"
                          )}
                        </div>
                        {serverSideValidation.includes("ui_company") && (
                          <div className="text-danger">
                            Company Name should be at-least 3 characters long
                          </div>
                        )}
                      </div>

                      <div className="checkbox">
                        <div>
                          <strong>Solution you are looking for</strong>
                          <span className="redColor">*</span>
                        </div>
                        <div className="row">
                          {solutionList &&
                            solutionList.map((item, index) => (
                              <div className="col-md-4">
                                <input
                                  id={index}
                                  type="radio"
                                  checked={
                                    this.state["ui_solution_looking_for"] ===
                                    item
                                  }
                                  name="ui_solution_looking_for"
                                  value={item}
                                  onChange={e => this.handleChange(e, index)}
                                />
                                &nbsp;
                                <label for={index}>{item}</label>
                              </div>
                            ))}
                        </div>
                        <div className="text-danger">
                          {this.validator.message(
                            "solution looking for",
                            this.state["ui_solution_looking_for"],
                            "required"
                          )}
                        </div>
                        {serverSideValidation.includes(
                          "ui_solution_looking_for"
                        ) && (
                          <div className="text-danger">
                            Please choose a valid option for the solution you
                            are looking for
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <label for="projectDetails">
                          <strong>Project Details</strong>
                        </label>
                        <span className="redColor">*</span>
                        <textarea
                          name="ui_project_details"
                          defaultValue={this.state["ui_project_details"]}
                          value={this.state["ui_project_details"]}
                          rows="3"
                          className="form-control"
                          id="projectDetails"
                          onChange={this.handleChange}
                          onBlur={() =>
                            this.validator.showMessageFor("project details")
                          }
                        />
                        <div className="text-danger">
                          {this.validator.message(
                            "project details",
                            this.state["ui_project_details"],
                            "required"
                          )}
                        </div>
                      </div>
                    </form>
                  </>
                ) : <></> : (
                  <>
                    <form
                      onSubmit={this.clientSideValidation}
                      className="login-form"
                    >
                      <div className="input-group">
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <i className="fas fa-user" />
                          </span>
                        </div>
                        <input
                          type="text"
                          className="form-control input_user"
                          name="userName"
                          onChange={this.handleChange}
                          placeholder="Email Address/Mobile Number"
                          value={this.state.userName}
                        />
                      </div>
                      <div className="">
                        {this.state.ValidationFailure.includes("userName") && (
                          <div className="redColor">User Name Is Incorrect</div>
                        )}
                        {this.state.clientSideValidation.includes(
                          "userName"
                        ) && (
                          <div className="redColor">Enter Valid User Name</div>
                        )}
                      </div>
                      <div className="input-group">
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <i className="fas fa-key" />
                          </span>
                        </div>
                        <input
                          type="password"
                          name="password"
                          onChange={this.handleChange}
                          value={this.state.password}
                          className="form-control input_pass"
                          placeholder="password"
                        />
                      </div>
                      <div className="mb-2">
                        {this.state.ValidationFailure.includes("password") && (
                          <div className="redColor validationFail">
                            Password Is Incorrect!
                          </div>
                        )}
                        {this.state.clientSideValidation.includes(
                          "password"
                        ) && (
                          <div className="redColor validationFail">
                            Password Must Contain 8 Character
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customControlInline"
                            name="shouldRemember"
                            checked={this.state.shouldRemember}
                            onChange={this.handleChange}
                          />
                          <label
                            className="custom-control-label login-form-text"
                            htmlFor="customControlInline"
                          >
                            Remember me
                          </label>
                        </div>
                      </div>
                    </form>
                    <div className="d-flex justify-content-center login_container">
                      <button
                        type="submit"
                        name="button"
                        className="btn login_btn"
                        onClick={this.clientSideValidation}
                      >
                        Login
                      </button>
                    </div>
                    <div className='contact-us-note'>NOTE: If you don't have password, Contact at out support <a href="mailto:contact@fsjars.com"
                        target="_top">contact@fsjars.com</a></div>
                  </>
                )}
              </div>
              <div class="modal-footer">
                {this.state.submit ? (
                  <button
                    class="btn btn-success input-contact"
                    type="submit"
                    disabled
                  >
                    <span
                      class="spinner-grow spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                    />
                    Loading...
                  </button>
                ) : (
                  !isLoginModal && <button
                    type="button"
                    className="btn btn-success"
                    onClick={this.onGetQuotes}
                  >
                    <strong>Submit</strong>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* {this.state.isModalShow && (
          <div id='modal'  className={this.state.isModalShow ? 'modal display-block' : 'modal display-none'}>
            <div className="modal-content">
              <div className="modal-header">
                <div>
                  <center>
                    <h4 className="modal-title">
                      <strong>Get Quote</strong>
                    </h4>
                  </center>
                </div>
                <button type="button" className="close modalClose" onClick={this.toggleQuotesModal}>
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <div style={{ textAlign: 'right' }}>
                  <strong class="color01">Want Instant Response? &nbsp;</strong>
                  <a href="https://wa.me/919175514982" target="_blank" style={{ fontSize: '22px' }}>
                    <i className="fa fa-whatsapp" style={{ color: '#7CCE60' }} />
                  </a>
                </div>
                <form>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-6">
                        <label for="name" className="">
                          <strong>Name</strong>
                        </label><span className='redColor'>*</span>
                        <input
                          type="text"
                          name="ui_name"
                          value={this.state['ui_name']}
                          onBlur={() => this.validator.showMessageFor('name')}
                          className="form-control"
                          id="name"
                          onChange={this.handleChange}
                        />
                        <div className="text-danger">
                          {this.validator.message(
                            'name',
                            this.state['ui_name'],
                            'required|alpha_space|min:3|max:50',
                          )}
                        </div>
                        {serverSideValidation.includes('ui_name') && (
                          <div className="text-danger">
                            Name should be at-least 3 characters long
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <label for="contact" className="">
                          <strong>Contact No.</strong>
                        </label><span className='redColor'>*</span>
                        <input
                          type="text"
                          name="ui_contact"
                          className="form-control"
                          id="contact"
                          onChange={this.handleChange}
                          value={this.state['ui_contact']}
                          onBlur={() => this.validator.showMessageFor('contact number')}
                        />
                        <div className="text-danger">
                          {this.validator.message(
                            'contact number',
                            this.state['ui_contact'],
                            'required|numeric|min:10|max:10',
                          )}
                        </div>
                        {serverSideValidation.includes('ui_contact') && (
                          <div className="text-danger">
                            Mobile number is not valid please check if you have entered a 10 digit
                            mobile number
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label for="email">
                      <strong>Email Id</strong>
                    </label><span className='redColor'>*</span>
                    <input
                      type="email"
                      name="ui_email"
                      className="form-control"
                      onChange={this.handleChange}
                      id="email"
                      value={this.state['ui_email']}
                      onBlur={() => this.validator.showMessageFor('email')}
                    />
                    <div className="text-danger">
                      {this.validator.message('email', this.state['ui_email'], 'required|email')}
                    </div>
                    {serverSideValidation.includes('ui_email') && (
                      <div className="text-danger">Please enter a valid email-id</div>
                    )}
                  </div>
                  <div className="form-group">
                    <label for="company">
                      <strong>Company</strong>
                    </label><span className='redColor'>*</span>
                    <input
                      type="company"
                      value={this.state['ui_company']}
                      name="ui_company"
                      className="form-control"
                      id="company"
                      onChange={this.handleChange}
                      onBlur={() => this.validator.showMessageFor('company name')}
                    />
                    <div className="text-danger">
                      {this.validator.message(
                        'company name',
                        this.state['ui_company'],
                        'required|alpha_num_space|min:3|max:50',
                      )}
                    </div>
                    {serverSideValidation.includes('ui_company') && (
                      <div className="text-danger">
                        Company Name should be at-least 3 characters long
                      </div>
                    )}
                  </div>

                  <div className="checkbox">
                    <div>
                      <strong>Solution you are looking for</strong><span className='redColor'>*</span>
                    </div>
                    <div className='row'>
                      {solutionList &&
                        solutionList.map((item, index) => (
                          <div className='col-md-4'>
                            <input
                              id={index}
                              type="radio"
                              value={this.state['ui_solution_looking_for']}
                              name="ui_solution_looking_for"
                              onChange={e => this.handleChange(e, index)}
                            />
                            &nbsp;
                            <label for={index}>{item}</label>
                          </div>
                      ))}
                    </div>
                    <div className="text-danger">
                      {this.validator.message(
                        'solution looking for',
                        this.state['ui_solution_looking_for'],
                        'required',
                      )}
                    </div>
                    {serverSideValidation.includes('ui_solution_looking_for') && (
                      <div className="text-danger">
                        Please choose a valid option for the solution you are looking for
                      </div>
                    )}
                  </div>
                  <div className="form-group">
                    <label for="projectDetails">
                      <strong>Project Details</strong>
                    </label><span className='redColor'>*</span>
                    <textarea
                      name="ui_project_details"
                      value={this.state['ui_project_details ']}
                      rows="3"
                      className="form-control"
                      id="projectDetails"
                      onChange={this.handleChange}
                      onBlur={() => this.validator.showMessageFor('project details')}
                    />
                    <div className="text-danger">
                      {this.validator.message(
                        'project details',
                        this.state['ui_project_details'],
                        'required',
                      )}
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                {this.state.submit ? (
                  <button class="btn btn-success input-contact" type="submit" disabled>
                    <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" />
                    Loading...
                  </button>
                ) : (
                  <button type="button" className="btn btn-success" onClick={this.onGetQuotes}>
                    <strong>Submit</strong>
                  </button>
                )}
              </div>
            </div>
          </div>
        )} */}
      </React.Fragment>
    );
  }
}

export default Navbar;
//&& ($(this).offset().top + $(this).height()) > fromTop
// var id = cur && cur.length ? cur[0].id : "";
// // Set/remove active class
// menuItems
//   .parent().removeClass("active")
//   .end().filter("[href='#"+id+"']").parent().addClass("active");
//---------------------
//  //   var navigList = $("#navig-links").find("a");
//   var navigItems = navigList.map(function(){
//     var item = $($(this).attr("href"));
//     if(item.length)
//     {return item;}
//   });
//   console.log(navigItems);
//   console.log(navigItems[0]);
// //  console.log(navigList);
//   // Bind to scroll
//   $(window).scroll(function(){
//     $('nav').hide();
//     if ($(document).scrollTop() > 50)
//     {
//       $('nav').show();
//     }
//     // Get container scroll position
//     var fromTop = $(this).scrollTop();
//     // Get id of current scroll item
//     var cur = navigItems.map(function(){
//        //console.log("after "+this);
//       if ($(this).offset().top < fromTop && ($(this).offset().top + $(this).height()) > fromTop )
//       {
//         console.log("at "+this);
//         console.log(navigItems.parent());
//         return this;
//       }
//     });
//     var id = cur && cur.length ? cur[0].id : "";
//     // Set/remove active class
//     navigItems.parent().removeClass("active")
//       .end().filter("[href='#"+id+"']").parent().addClass("active");
//
//   });
