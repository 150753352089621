import React, { Component } from 'react';
import './TestimonialPage.css';
import client1 from '../../img/perso1.jpg';
import { Helmet } from 'react-helmet';
class TestimonialPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Customer's Testimonial</title>
        </Helmet>
        <div className="genericContainer genericContainerHeader">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-6 col-12">
              <div className="hero-software content hero-feature-content">
                <strong>
                  <span className="TeamPageTitle1">TESTIMO</span>NIALS
                </strong>
                {/* <p className="alignCenter">Please read the document carefully. </p> */}
              </div>
            </div>
          </div>
        </div>
        {/* <header className="testimonialHeader">
          <div className="testimonialImage row">
            <div className="transparentLayer" />
            <img  src={testimonial} alt="testimonialImage" className='col-12'/>
          </div>
          <div className="testimonialTitle">
            <strong>
              <span className="testimonialTitle1">TESTIMO</span>NIAL
            </strong>
          </div>
        </header> */}
        <div className="testimonialContent container">
          <div className="testimonialSection1">
            <center className="testimonialContentTagLine">
              <i class="fa fa-quote-left" aria-hidden="true" />
              <h4>
                <strong>What Customer Say About Us</strong>
              </h4>
            </center>
            <div className="learnMore">
              <div class="card ">
                <div class="card-body">
                  <div className="row testmonialBorder">
                    <div className="col-md-9">
                      <p>
                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aliquid modi
                        excepturi vero similique est nam itaque atque ullam saepe provident a rerum
                        amet, autem animi ex minima quos officia quibusdam.
                      </p>
                      <h5 style={{ color: 'black', textAlign: 'start', color: '#7CCE60' }}>
                        <strong>Lorem, ipsum.</strong>
                      </h5>
                    </div>
                    <div className="col-md-3 testimonialClientImage">
                      <img src={client1} alt="Client" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="card ">
                <div class="card-body">
                  <div className="row testmonialBorder">
                    <div className="col-md-9 ">
                      <p>
                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aliquid modi
                        excepturi vero similique est nam itaque atque ullam saepe provident a rerum
                        amet, autem animi ex minima quos officia quibusdam.
                      </p>
                      <h5 style={{ color: 'black', textAlign: 'start', color: '#7CCE60' }}>
                        <strong>Lorem, ipsum.</strong>
                      </h5>
                    </div>
                    <div className="col-md-3 testimonialClientImage">
                      <img src={client1} alt="Client" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="card ">
                <div class="card-body">
                  <div className="row testmonialBorder">
                    <div className="col-md-9">
                      <p>
                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aliquid modi
                        excepturi vero similique est nam itaque atque ullam saepe provident a rerum
                        amet, autem animi ex minima quos officia quibusdam.
                      </p>
                      <h5 style={{ color: 'black', textAlign: 'start', color: '#7CCE60' }}>
                        <strong>Lorem, ipsum.</strong>
                      </h5>
                    </div>
                    <div className="col-md-3 testimonialClientImage">
                      <img src={client1} alt="Client" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="card ">
                <div class="card-body">
                  <div className="row testmonialBorder">
                    <div className="col-md-9">
                      <p>
                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aliquid modi
                        excepturi vero similique est nam itaque atque ullam saepe provident a rerum
                        amet, autem animi ex minima quos officia quibusdam.
                      </p>
                      <h5 style={{ color: 'black', textAlign: 'start', color: '#7CCE60' }}>
                        <strong>Lorem, ipsum.</strong>
                      </h5>
                    </div>
                    <div className="col-md-3 testimonialClientImage">
                      <img src={client1} alt="Client" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="card ">
                <div class="card-body">
                  <div className="row testmonialBorder">
                    <div className="col-md-9">
                      <p>
                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aliquid modi
                        excepturi vero similique est nam itaque atque ullam saepe provident a rerum
                        amet, autem animi ex minima quos officia quibusdam.
                      </p>
                      <h5 style={{ color: 'black', textAlign: 'start', color: '#7CCE60' }}>
                        <strong>Lorem, ipsum.</strong>
                      </h5>
                    </div>
                    <div className="col-md-3 testimonialClientImage">
                      <img src={client1} alt="Client" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <center className="sloganFooter">
              <h3>
                <strong>You can trust us.</strong>
              </h3>
            </center>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default TestimonialPage;
