import React, { Component } from 'react';
import "./PillarBusiness.css";
class PillarBusiness extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            isShow : true
         };
    }
    render() {
        return (
            <React.Fragment>
                <header className='PillarBusinessHeader'>
                    <div className='PillarBusinessImage row'>
                    <div className="transparentLayer">
                            
                        </div>
                        {/* <img  src={PillarBusiness} alt="PillarBusinessImage" className='col-12'/> */}
                    </div>
                    <div className="PillarBusinessTitle">
                        <strong><span className='PillarBusinessTitle1'>A PILLAR OF SUPPORT</span> FOR YOUR BUSINESS</strong>
                    </div>
                </header>
                <div className="PillarBusinessContent container">
                    <div className="PillarBusinessSection1">
                        <center className='PillarBusinessContentTagLine'><i class="fa fa-quote-left" aria-hidden="true"></i><h4><strong>A Pillar of Support for Your Business.</strong></h4></center>
                        <p className='PillarBusinessIntro'>
                        <strong>
                            <p>
                                We provide END to END IT support and simplify business processes.
                                Let us take care of your all IT infrastructure so that you can focus on what matters the most for your growth – the next business need of your valued customer!
                                With 24-7 support and dedicated personals for each business function, FSJARS is your partner of choice!
                            </p>
                            </strong>
                            {/* <span onClick={this.handleChange} className='learnMoreText show'>[Learn More...]</span> */}
                        </p>
                        { this.state.isShow &&
                            <div className="learnMore">
                                <div className='PillarBusinessSection2'>
                                    <div className="PillarBusinessSection2Intro">
                                        <p> 
                                            We go the extra mile in supporting your business which is why we stay even after development so that your business does not face any technical problems. We ensure that your business process is running smoothly and constantly try to improve our technology so that your business processes become more simplified and secure.
                                        </p>
                                        <p>
                                            Our team consists of experts that are ready to solve your business problems and listen to you and your concerns. We develop products, website, mobile, and e-commerce applications that are bound to bring you success. we look for new and innovative ways to increase your revenue and optimize your business processes to create a better future for your business.
                                        </p> 
                                    </div>
                                </div>
                                
                                <center className='sloganFooter'><h3><strong >You can trust us.</strong></h3></center>
                            </div>  
                        }
                    </div>

                </div>
            </React.Fragment>
        );
    }
}

export default PillarBusiness;