import React, { Component } from 'react';
import './Home.css';
import Footer from '../Footer/Footer';
import Contact from '../Contact/Contact';
import Team from '../Team/Team';
import HappyClient from '../HappyClients/HappyClients';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import SimpleReactValidator from 'simple-react-validator';
import Swal from 'sweetalert2';
import { ApiUrl, Error_Type } from '../../Utils/globalConst/ApiConfig.js';
import { Helmet } from 'react-helmet';

const initialState = {
  ui_name: '',
  ui_number: '',
  email: '',
  ui_subject: '',
  message: '',
  serverSideValidation: [],
  submit: false,
};
export class Home extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
  }
  change = e => {
    if (e.target.name === 'ui_number' && !/^(\s*|\d+)$/.test(e.target.value)) {
      return;
    }
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        console.log(this.state.ui_number);
      },
    );
  };
  componentWillMount = () => {
    this.setState(initialState);
  };
  onSubmit = async e => {
    e.preventDefault();
    if (this.validator.allValid()) {
      try {
        this.setState({
          submit: true,
        });
        console.log(this.state);
        const { ui_name, ui_number, ui_subject } = this.state;
        const requestBody = {
          ui_name,
          ui_mobile: ui_number,
          ui_subject,
          ui_product_code: 'FSJARS',
        };
        const response = await axios.post(ApiUrl.Api + '/get_call_back', requestBody);
        if (response) {
          // Swal.fire(
          //   'Successful',
          //   response.data.data.description,
          //   'success'
          // )
          this.setState(
            {
              submit: false,
              callBackDone: true,
              ...initialState,
            },
            () => {
              setTimeout(() => {
                $('#numberModal').modal('hide');
                this.setState({
                  callBackDone: false,
                });
              }, 2000);
            },
          );
        }
      } catch (error) {
        console.log(error.response);
        switch (error.response.data.type) {
          case Error_Type.Validation_Error: {
            console.log('Validation Error');
            var validationFailure = [];
            error.response.data.errors.forEach(error => {
              if (error.field === 'ui_name') {
                validationFailure.push('ui_name');
              }
              if (error.field === 'ui_mobile') {
                validationFailure.push('ui_number');
              }
              if (error.field === 'ui_subject') {
                validationFailure.push('ui_subject');
              }
            });
            this.setState({
              submit: false,
              serverSideValidation: validationFailure,
            });
            Swal.fire('Oops...', error.response.data.message, 'error');
            break;
          }
          case Error_Type.Db_Error: {
            console.log('db Error');
            this.setState({
              submit: false,
            });
            Swal.fire('Oops...', error.response.data.message, 'error');
            break;
          }
          case Error_Type.Internal_Error: {
            console.log('Internal Error');
            this.setState({
              submit: false,
            });
            Swal.fire('Oops...', error.response.data.message, 'error');
            break;
          }
          case Error_Type.Fatal_Error: {
            console.log('Fatal Error');
            this.setState({
              submit: false,
            });
            Swal.fire('Oops...', error.response.data.message, 'error');
            break;
          }
          case Error_Type.Auth_Error: {
            console.log('Auth_Error');
            this.setState({
              submit: false,
            });
            Swal.fire('Oops...', error.response.data.message, 'error');
            break;
          }
          default: {
            console.log('default case hitted');
            this.setState({
              submit: false,
            });
            break;
          }
        }
      }
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      this.forceUpdate();
    }
  };
  render() {
    const { serverSideValidation, submit } = this.state;
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>FSJARS: Free website development | Mobile Application | Hosting Solutions</title>
        </Helmet>
        <div className="sections">
          <section id="home">
            <div className="slider-custom">
              <div className="">
                <div className="">
                  <div className="">
                    <div id="demo" className="carousel slide" data-ride="carousel">
                      <ul className="carousel-indicators">
                        <li data-target="#demo" data-slide-to="0" className="active" />
                        <li data-target="#demo" data-slide-to="1" className="" />
                        <li data-target="#demo" data-slide-to="2" className="" />
                      </ul>
                      <div className="carousel-inner">
                        <div className="carousel-item active">
                          <img
                            src={require('../../img/banner1.jpg')}
                            alt="Los Angeles"
                            className="img-fluid"
                          />
                          <div className="carousel-caption">
                            <h1 className="h1-top">Have a Business Idea?</h1>
                            <h3 className="h3-top">
                              We give you the IT solutions to build a website, mobile application,
                              e-commerce website, and SEO services .
                            </h3>
                            <Link
                              to="/haveabusiness"
                              className="btn headerLearnMore aboutUsLearnMore"
                            >
                              <strong>Learn More</strong>
                            </Link>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <img
                            src={require('../../img/banner2.jpg')}
                            alt="Chicago"
                            className="img-fluid"
                          />
                          <div className="carousel-caption">
                            <h1 className="h1-top">Have an Existing Business?</h1>
                            <h3 className="h3-top">We provide affordable IT solutions.</h3>
                            <Link
                              to="/existingbusiness"
                              className="btn headerLearnMore aboutUsLearnMore"
                            >
                              <strong>Learn More </strong>
                            </Link>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <img
                            src={require('../../img/banner3.jpg')}
                            alt="New York"
                            className="img-fluid"
                          />
                          <div className="carousel-caption">
                            <h1 className="h1-top">A Pillar of Support for Your Business</h1>
                            <h3 className="h3-top">
                              We provide IT support and simplify business processes.
                            </h3>
                            <Link
                              to="/pillarbusiness"
                              className="btn headerLearnMore aboutUsLearnMore"
                            >
                              <strong>Learn More </strong>
                            </Link>
                          </div>
                        </div>
                      </div>
                      <a className="carousel-control-prev" href="#demo" data-slide="prev">
                        <span className="carousel-control-prev-icon" />
                      </a>
                      <a className="carousel-control-next" href="#demo" data-slide="next">
                        <span className="carousel-control-next-icon" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*Home ends */}
          {/*About content */}
          <section id="about">
            <div className="welcome-content">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                    <p className="header-para">WHAT MAKES US DIFFERENT</p>
                    <h2 className="text-center">
                      About <span className="custom-h2">US</span>
                    </h2>
                    <hr className="below-header-hr" />
                    <p className="below-para">
                      <em>The architects that shape your digital future</em>
                    </p>
                  </div>
                </div>
                <div className="row second-custom-row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <p className="aboutus aboutUsPara">
                      We are a company with a large team of experts who are experienced in the
                      design and development of websites, e-commerce solutions, custom web
                      applications and mobile applications. We are a strong advocate of customer
                      satisfaction. After development we remain constantly in touch with you while
                      offering the highest amount of support. We also provide an excellent quality
                      SEO service. .{' '}
                      <Link to="/aboutUs" className="btn aboutUsLearnMore">
                        <strong>
                          Learn More <i className="fa fa-arrow-right" />
                        </strong>
                      </Link>
                    </p>
                    <p className="below-sec-row-para">
                      <em>We are committed to ensure success of our clients.</em>
                    </p>
                    {/*<ul className="center-list">
              <li>Website Development</li>
              <li>Mobile Application</li>
              <li>Search Engine Optimization</li>
              <li>Support & Maintenance</li>
  </ul>*/}
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 custom-padd">
                  <div className="custom-left-content">
                    <h1>
                      Build your website for free.&nbsp;&nbsp;
                      <i className="fa fa-hand-o-right" aria-hidden="true" />
                    </h1>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 custom-padd">
                  <div className="custom-right-content text-center">
                    <button type="button" className="btn btn-success custom-right-btn buildForFree">
                      Click Here
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*end about*/}
          {/*portfolio*/}
          <section id="portfolio">
            <div className="software-solution">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                    <p className="header-para">SOFTWARE SOLUTIONS</p>
                    <h2 className="text-center">
                      <span className="custom-h2">We</span> Carefully handle all{' '}
                      <span className="custom-h2">Projects</span>
                    </h2>
                    <hr className="below-header-hr" />
                    <p className="below-para">
                      <em>We go the extra mile.</em>
                      <Link to="/portfolio" className="btn aboutUsLearnMore">
                        <strong>
                          Learn More <i className="fa fa-arrow-right" />
                        </strong>
                      </Link>
                    </p>
                  </div>
                </div>

                <div className="row custom-second-row">
                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-4">
                    <div className="custom-images-featured">
                      <img
                        src={require('../../img/ElipiWeb.PNG')}
                        width="600"
                        height="300"
                        className="img-fluid image"
                      />
                      <div className="overlay" />
                      <a href="https://www.elipi.in/" target="_blank" className="text">
                        <i className="fa fa-search" aria-hidden="true" />
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-4">
                    <div className="custom-images-featured">
                      <img
                        src={require('../../img/CoreIdd.png')}
                        width="600"
                        height="300"
                        className="img-fluid image"
                      />
                      <div className="overlay" />
                      <a href="http://coreidd.com/" target="_blank" className="text">
                        <i className="fa fa-search" aria-hidden="true" />
                      </a>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-4">
                    <div className="custom-images-featured">
                      <img
                        src={require('../../img/LetsWeGrow.png')}
                        width="600"
                        height="300"
                        className="img-fluid image"
                      />
                      <div className="overlay" />
                      <a href="http://www.letswegrow.com/" target="_blank" className="text">
                        <i className="fa fa-search" aria-hidden="true" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*end portfolio */}
          {/* service */}
          <section id="services">
            <div className="offer-content" id="service">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <h2 className="text-center">
                      Our <span className="custom-h2">Services</span>
                    </h2>
                    <hr className="below-header-hr" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4 col-sm-6 col-xs-12 col-12 ">
                    <div className="custom-offer-box">
                      <div className="row">
                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                          <i className="fa fa-laptop fa-2x" aria-hidden="true" />
                        </div>
                        <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12 custom-parent-col-padding">
                          <h4>Web Development</h4>
                          <p style={{ height: '130px' }} className="servicePara">
                            We simplify and support your business through affordable IT solutions.
                            What’s a website that has only beauty and no brains?
                          </p>
                        </div>
                      </div>
                      <center>
                        <Link to="/webDevelopment" className="btn aboutUsLearnMore">
                          <strong>
                            Learn More <i className="fa fa-arrow-right rightArrowLearnMore" />
                          </strong>
                        </Link>
                      </center>
                    </div>
                  </div>

                  <div className="col-lg-4 col-sm-6 col-xs-12 col-12">
                    <div className="custom-offer-box">
                      <div className="row">
                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                          <center>
                            <i className="fa fa-mobile fa-2x" aria-hidden="true" />
                          </center>
                        </div>
                        <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12 custom-parent-col-padding">
                          <h4>Mobile Application</h4>
                          <p style={{ height: '130px' }} className="servicePara">
                            We are focused on building new and innovative mobile applications to
                            build new and enhanced user experiences on mobile devices.
                          </p>
                        </div>
                      </div>
                      <center>
                        <Link to="/mobDevelopment" className="btn aboutUsLearnMore">
                          <strong>
                            Learn More <i className="fa fa-arrow-right rightArrowLearnMore" />
                          </strong>
                        </Link>
                      </center>
                    </div>
                  </div>

                  <div className="col-lg-4  col-sm-6 col-xs-12 col-12">
                    <div className="custom-offer-box Ecom">
                      <div className="row">
                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                          <i className="fa fa-shopping-cart fa-2x" aria-hidden="true" />
                        </div>
                        <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12 custom-parent-col-padding">
                          <h4>E-Commerce</h4>
                          <p style={{ height: '130px' }} className="servicePara">
                            We transform your brilliant business ideas into visually appealing
                            websites with online shopping tools.
                          </p>
                        </div>
                      </div>
                      <center>
                        <Link to="/eCommerceDev" className="btn aboutUsLearnMore">
                          <strong>
                            Learn More <i className="fa fa-arrow-right rightArrowLearnMore" />
                          </strong>
                        </Link>
                      </center>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-6 col-xs-12 col-12">
                    <div className="custom-offer-box">
                      <div className="row">
                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                          <i className="fa fa-desktop fa-2x" aria-hidden="true" />
                        </div>
                        <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12 custom-parent-col-padding">
                          <h4>Custom Software Product</h4>
                          <p style={{ height: '200px' }} className="servicePara">
                            We can automate your business operations with the help of custom
                            development and help you manage your operations more efficiently. We
                            will translate your manual operations into automated web applications.
                          </p>
                        </div>
                      </div>
                      <center>
                        <Link to="/customerSoftwareProduct" className="btn aboutUsLearnMore">
                          <strong>
                            Learn More <i className="fa fa-arrow-right rightArrowLearnMore" />
                          </strong>
                        </Link>
                      </center>
                    </div>
                  </div>

                  <div className="col-lg-4  col-sm-6 col-xs-12 col-12">
                    <div className="custom-offer-box">
                      <div className="row">
                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                          <i className=" fas fa-robot fa-2x" aria-hidden="true" />
                        </div>
                        <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12 custom-parent-col-padding">
                          <h4> Chatbots and Machine Learning</h4>
                          <p style={{ height: '200px' }} className="servicePara">
                            We incorporate machine learning to your websites to automate repetitive
                            tasks and enhance your website.
                          </p>
                        </div>
                      </div>
                      <center>
                        <Link to="/machineLearningAndAI" className="btn aboutUsLearnMore">
                          <strong>
                            Learn More <i className="fa fa-arrow-right rightArrowLearnMore" />
                          </strong>
                        </Link>
                      </center>
                    </div>
                  </div>

                  <div className="col-lg-4 col-sm-6 col-xs-6 col-12">
                    <div className="custom-offer-box SupportAndMaint">
                      <div className="row">
                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                          <i className="fa fa-cogs fa-2x" aria-hidden="true" />
                        </div>
                        <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12 custom-parent-col-padding">
                          <h4>Support & &nbsp; Maintenance</h4>
                          <p style={{ height: '200px' }} className="servicePara">
                            Our support will include everything from updating content of your
                            website to provide additional features in your websites which can
                            increase your business revenue.
                          </p>
                        </div>
                      </div>
                      <center>
                        <Link to="/supportAndMaintenance" className="btn aboutUsLearnMore">
                          <strong>
                            Learn More <i className="fa fa-arrow-right rightArrowLearnMore" />
                          </strong>
                        </Link>
                      </center>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*end service */}
          {/*happy clients */}
          <section id='testimonial'>
            <HappyClient />
          </section>
          {/*end happy clients*/}
          {/*team section*/}
          <section id='team'>
              <Team />
          </section>
          <section className="contactUs-client">
            <div className="section-brand section-ptb-120 custom-bg-color-clients">
              <div className="Container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="section-title--15 text-center mt--40">
                      <h2 className="text-center">
                        Contact <span className="custom-h2">Us</span>
                      </h2>
                      <hr className="below-header-hr" />
                      <em className="below-para">Find Us By Below Details</em>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="row mt--40 custom-detail-href">
                      <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                        <div
                          className="custom-contact-detail-sec custom-service-6 text-center center-block"
                          data-toggle="modal"
                          data-target="#numberModal"
                        >
                          <div className="custom-detail-icons">
                            <i className="fa fa-mobile mobile-circular" />
                          </div>
                          <br />
                          <br />
                          <p className="alignCenter contactUsType">Get A Call Back From Us</p>
                        </div>
                      </div>

                      <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                        <div className="custom-contact-detail-sec custom-service-6 text-center center-block">
                          <div className="custom-detail-icons">
                            <i className="fa fa-phone phone-circular" />
                          </div>
                          <br />
                          <br />
                          <div className="alignCenter contactUsType">
                            {' '}
                            +91 91755 14982 <br />
                            +91 92847 20778
                          </div>
                        </div>
                      </div>

                      <a
                        href="mailto:contact@fsjars.com"
                        target="_top"
                        className="col-lg-3 col-md-3 col-sm-12 col-12"
                      >
                        <div className="custom-contact-detail-sec custom-service-6 text-center center-block">
                          <div className="custom-detail-icons">
                            <i className="fa fa-envelope envelope-circular" />
                          </div>
                          <br />
                          <br />
                          <p className="contact-info alignCenter contactUsType">contact@fsjars.com</p>
                        </div>
                      </a>

                      <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                        <a
                          href={
                            'https://api.whatsapp.com/send?phone=919175514982&text=Hi%20we%20need%20help%20regarding%20something'
                          }
                          target="_blank"
                        >
                          <div className="custom-contact-detail-sec custom-service-6 text-center center-block">
                            <div className="custom-detail-icons">
                              <i className="fa fa-whatsapp whatsapp-circular" />
                            </div>
                            <br />
                            <br />
                            <p className="contact-info alignCenter contactUsType">WhatsApp</p>
                          </div>
                        </a>
                      </div>

                      {/* 
                       

                        <div className="col-lg-3 col-md-3 col-sm-12 col-12 text-center">
                          <div className="service text-left service-6 custom-service-contact-box custom-service-6">
                            <div className="row">
                              <div className="offset-md-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 text-center center-block">
                                <a
                                  href={
                                    "https://api.whatsapp.com/send?phone=919175514982&text=Hi%20we%20need%20help%20regarding%20something"
                                  }
                                  target="_blank"
                                >
                                  {" "}
                                  <div className="icons">
                                    <i
                                      className="fa fa-whatsapp"
                                      aria-hidden="true"
                                    />
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div className="content text-center">
                              <a
                                href={
                                  "https://api.whatsapp.com/send?phone=919175514982&text=Hi%20we%20need%20help%20regarding%20something"
                                }
                                target="_blank"
                              >
                                {" "}
                                <p>9175514982</p>
                              </a>
                            </div>
                          </div>
                        </div> */}
                      {/*contact modal */}
                      <div className="callbackModal">
                        <div>
                          <div
                            className="modal fade "
                            id="numberModal"
                            tabindex="-1"
                            role="dialog"
                            aria-labelledby="numberModalLabel"
                            aria-hidden="true"
                          >
                            <div className="modal-dialog modal-dialog-centered" role="document">
                              <div className="modal-content home-modal-callback">
                                <div className="modal-header">
                                  <h5 className="modal-title" id="numberModalLabel">
                                    Get a Callback
                                  </h5>
                                  <button
                                    type="button"
                                    className="close whiteColor"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                                <div className="modal-body">
                                  {!this.state.callBackDone ? (
                                    <form>
                                      <div className="form-group">
                                        <label for="contactNumber" className="callbackLabel">
                                          Contact Number
                                        </label>
                                        <span className="redColor">*</span>
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="contactNumber"
                                          aria-describedby="emailHelp"
                                          placeholder="Enter Contact Number"
                                          name="ui_number"
                                          value={this.state.ui_number}
                                          onChange={this.change}
                                        />
                                        <span className="text-danger">
                                          {this.validator.message(
                                            'Mobile Number',
                                            this.state.ui_number,
                                            'required|numeric|min:10|max:10',
                                          )}
                                          {serverSideValidation.includes('ui_number') && (
                                            <span>
                                              Mobile number is not valid please check if you have
                                              entered a 10 digit mobile number
                                            </span>
                                          )}
                                        </span>
                                        <br />
                                        <label for="name" className="callbackLabel">
                                          Name
                                        </label>
                                        <span className="redColor">*</span>
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="name"
                                          aria-describedby="emailHelp"
                                          placeholder="Enter Your Name"
                                          name="ui_name"
                                          value={this.state.ui_name}
                                          onChange={e => this.change(e)}
                                        />
                                        <span className="text-danger">
                                          {this.validator.message(
                                            'Name',
                                            this.state.ui_name,
                                            'required|alpha_space|min:3|max:50',
                                          )}
                                          {serverSideValidation.includes('ui_name') && (
                                            <span>Name should be at-least 3 characters long</span>
                                          )}
                                        </span>
                                        <br />
                                        <label for="subject" className="callbackLabel">
                                          Subject
                                        </label>
                                        <span className="redColor">*</span>
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="subject"
                                          aria-describedby="emailHelp"
                                          placeholder="Enter Subject"
                                          name="ui_subject"
                                          value={this.state.ui_subject}
                                          onChange={e => this.change(e)}
                                        />
                                        <span className="text-danger">
                                          {this.validator.message(
                                            'Subject',
                                            this.state.ui_subject,
                                            'required|alpha_num_space|min:3|max:100',
                                          )}
                                          {serverSideValidation.includes('ui_subject') && (
                                            <span>
                                              Subject should be at-least 3 characters long
                                            </span>
                                          )}
                                        </span>
                                        <small id="emailHelp" className="form-text text-muted">
                                          <em>Our Team Will Contact You Soon.</em>
                                        </small>
                                      </div>

                                      {!submit ? (
                                        <button
                                          type="submit"
                                          className="btn btn-success"
                                          onClick={e => this.onSubmit(e)}
                                          style={{ width: '100%' }}
                                        >
                                          Submit
                                        </button>
                                      ) : (
                                        <button
                                          class="btn btn-success input-contact"
                                          style={{ width: '100%' }}
                                          type="submit"
                                          disabled
                                        >
                                          <span
                                            class="spinner-grow spinner-grow-sm"
                                            role="status"
                                            aria-hidden="true"
                                          />
                                          Loading...
                                        </button>
                                      )}
                                    </form>
                                  ) : (
                                    <div className="callBackDone">
                                      Thank you , We Will Back to You Soon
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/*end modal */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <section id="team">
            <div className="team-members p-5">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                  <h2 className="text-center-contact">
                    MEET OUR <span className="custom-h2">TEAM</span>
                  </h2>
                  <hr className="below-header-hr" />
                  <p className="below-para" />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-2 col-md-4 col-sm-4 col-xs-6 col-6">
                  <div class="card">
                    <img
                      className="card-img-top"
                      src={require('../images/tm3.jpg')}
                      className="img-fluid image"
                      lt="Card image cap"
                    />
                    <div class="card-body">
                      <h5 class="card-title text-center">Sonali Mehetre</h5>
                      <p class="card-text text-center">Executive Director</p>
                      <div className="linkedin-logo text-center">
                        <a className="text-center">
                          <i class="fab fa-linkedin-in fa-2x" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 col-md-4 col-sm-4 col-xs-6 col-6">
                  <div class="card">
                    <img
                      className="card-img-top"
                      src={require('../images/tm4.jpg')}
                      className="img-fluid image"
                      lt="Card image cap"
                    />
                    <div class="card-body">
                      <h5 class="card-title text-center">Swapnil Sharma</h5>
                      <p class="card-text text-center">Head of Development</p>
                      <div className="linkedin-logo text-center">
                        <a className="text-center">
                          <i class="fab fa-linkedin-in fa-2x" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 col-md-4 col-sm-4 col-xs-6 col-6">
                  <div class="card">
                    <img
                      className="card-img-top"
                      src={require('../images/tm4.jpg')}
                      className="img-fluid image"
                      lt="Card image cap"
                    />
                    <div class="card-body">
                      <h5 class="card-title text-center">Siva Kusi</h5>
                      <p class="card-text text-center">Full Stack Developer</p>
                      <div className="linkedin-logo text-center">
                        <a className="text-center">
                          <i class="fab fa-linkedin-in fa-2x" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-4 col-xs-6 col-6">
                  <div class="card">
                    <img
                      className="card-img-top"
                      src={require('../images/tm1.jpeg')}
                      className="img-fluid image"
                      lt="Card image cap"
                    />
                    <div class="card-body">
                      <h5 class="card-title text-center">M Shashwat Nigam</h5>
                      <p class="card-text text-center">Full stack Developer</p>
                      <div className="linkedin-logo text-center">
                        <a className="text-center">
                          <i class="fab fa-linkedin-in fa-2x" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-4 col-xs-6 col-6">
                  <div class="card">
                    <img
                      className="card-img-top"
                      src={require('../images/tm2.jpg')}
                      className="img-fluid image"
                      lt="Card image cap"
                    />
                    <div class="card-body">
                      <h5 class="card-title text-center">Shakti Singh</h5>
                      <p class="card-text text-center">Full Stack Developer</p>
                      <div className="linkedin-logo text-center">
                        <a className="text-center">
                          <i class="fab fa-linkedin-in fa-2x" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 col-md-4 col-sm-4 col-xs-6 col-6">
                  <div class="card">
                    <img
                      className="card-img-top"
                      src={require('../images/tm3.jpg')}
                      className="img-fluid image"
                      lt="Card image cap"
                    />
                    <div class="card-body">
                      <h5 class="card-title text-center">Zainab Khokawala</h5>
                      <p class="card-text text-center">OSM</p>
                      <div className="linkedin-logo text-center">
                        <a className="text-center">
                          <i class="fab fa-linkedin-in fa-2x" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 col-md-4 col-sm-4 col-xs-6 col-6">
                  <div class="card">
                    <img
                      className="card-img-top"
                      src={require('../images/tm3.jpg')}
                      className="img-fluid image"
                      lt="Card image cap"
                    />
                    <div class="card-body">
                      <h5 class="card-title text-center">Nikhil Ghatul</h5>
                      <p class="card-text text-center">Full Stack Developer</p>
                      <div className="linkedin-logo text-center">
                        <a className="text-center">
                          <i class="fab fa-linkedin-in fa-2x" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 col-md-4 col-sm-4 col-xs-6 col-6">
                  <div class="card">
                    <img
                      className="card-img-top"
                      src={require('../images/tm3.jpg')}
                      className="img-fluid image"
                      lt="Card image cap"
                    />
                    <div class="card-body">
                      <h5 class="card-title text-center">Shiva Varshney</h5>
                      <p class="card-text text-center">Full Stack Developer</p>
                      <div className="linkedin-logo text-center">
                        <a className="text-center">
                          <i class="fab fa-linkedin-in fa-2x" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </section> */}
          {/*get in touch*/}
          {/* Contact Us */}
          {/*end get in touch */}
        </div>
      </div>
    );
  }
}

export default Home;
{
  /* <div class="card" style="width: 18rem;">
  <img class="card-img-top" src="..." alt="Card image cap">
  <div class="card-body">
    <h5 class="card-title">Card title</h5>
    <p class="card-text ">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
    <a href="#" class="btn btn-primary">Go somewhere</a>
  </div>
</div> */
}
