import React, { Component } from 'react';
import './Privacy.css';
import { Helmet } from 'react-helmet';
class Privacy extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Fsjars Privacy Policy</title>
        </Helmet>
        <header className="privacyHeader">
          <div className="privacyImage row">
            <div className="transparentLayer" />
            {/* <img  src={privacy} alt="privacyImage" className='col-12'/> */}
          </div>
          <div className="privacyTitle">
            <center>
              <strong>
                <span className="privacyTitle1">Privacy</span> Policy
              </strong>
            </center>
          </div>
        </header>
        <div className="privacyContent container">
          <div className="privacyIntroduction">
            <p>
              FSJARS is committed to safeguarding the privacy of our website visitors and service
              users.
            </p>
            <p>
              This notice applies where we are acting as a data controller with respect to the
              personal data of our website visitors and service users; in other words, where we
              determine the purposes and means of the processing of that personal data.
            </p>
          </div>
          <div className="privacySections">
            <div className="privacySection1">
              <div className="privacySection1Header privacyContentTitle">
                COLLECTION OF INFORMATION
              </div>
              <div className="privacySection1Content privacyContent">
                <p>We may collect the following information:</p>
                <ol type="1">
                  <li>Name and Job title</li>
                  <li>Contact information including email address</li>
                  <li>Demographic information such as Postcode, preferences and interests</li>
                  <li>Other information relevant to customer surveys and/or offers</li>
                  <li>Information supplied through quote request forms</li>
                </ol>
              </div>
            </div>
            <div className="privacySection2">
              <div className="privacySection2Header privacyContentTitle">
                <p>What we do with the information we gather?</p>
              </div>
              <div className="privacySection2Content privacyContent">
                <p>
                  We require this information to understand your needs and provide you with a better
                  service, and in particular for the following reasons:
                </p>
                <ol type="1">
                  <li>Internal record keeping.</li>
                  <li>We may use the information to improve our products and services.</li>
                  <li>
                    We may periodically send promotional emails about new products, special offers
                    or other information which we think you may find interesting using the email
                    address which you have provided.
                  </li>
                  <li>
                    You can unscribe to this promotional emails anytime by clicking the links in
                    mail.
                  </li>
                  <li>
                    From time to time, we may also use your information to contact you for market
                    research purposes. We may contact you by email, phone, fax or mail. We may use
                    the information to customise the website according to your interests.
                  </li>
                </ol>
              </div>
            </div>
            <div className="privacySection3">
              <div className="privacySection3Header privacyContentTitle">
                How we use your personal data?
              </div>
              <div className="privacySection3Content privacyContent">
                In this Section we have set out:
                <ol type="1">
                  <li>The general categories of personal data that we may process;</li>
                  <li>
                    In the case of personal data that we did not obtain directly from you, the
                    source and specific categories of that data;
                  </li>
                  <li>the purposes for which we may process personal data; </li>
                  <li>the legal bases of the processing.</li>
                </ol>
              </div>
            </div>
            <div className="privacySection4">
              <div className="privacySection4Header privacyContentTitle">Usage Data</div>
              <div className="privacySection4Content privacyContent">
                <p>
                  We may process data about your use of our website and services (“usage data“). The
                  usage data may include your IP address, geographical location, browser type and
                  version, operating system, referral source, length of visit, page views and
                  website navigation paths, as well as information about the timing, frequency and
                  pattern of your service use. The sources of the usage data are Google Analytics,
                  Google AdWords, HubSpot, Instagram, Twitter and LInkedIn. This usage data may be
                  processed for the purposes of analysing the use of the website and services. The
                  legal basis for this processing is our legitimate interests, namely monitoring and
                  improving our website and services.
                </p>
              </div>
            </div>
            <div className="privacySection5">
              <div className="privacySection5Header privacyContentTitle">Service Data</div>
              <div className="privacySection5Content privacyContent">
                <p>
                  {' '}
                  We may process your personal data that are provided in the course of the use of
                  our services (“service data“). The service data may include names, email
                  addresses, contact details, financial records, service agreements, statements of
                  work, designs, specifications and proposals. The source of the service data is you
                  or your employer. The service data may be processed for the purposes of operating
                  our website, providing our services, ensuring the security of our website and
                  services, maintaining back-ups of our databases and communicating with you. The
                  legal basis for this processing is the performance of a contract between you and
                  us and/or taking steps, at your request, to enter into such a contract.
                </p>
              </div>
            </div>
            <div className="privacySection6">
              <div className="privacySection6Header privacyContentTitle">Publication Data</div>
              <div className="privacySection6Content privacyContent">
                <p>
                  We may process information that you post for publication on our website
                  (“publication data“). The publication data may be processed for the purposes of
                  enabling such publication and administering our website. The legal basis for this
                  processing is our legitimate interests, namely the proper administration of our
                  website.
                </p>
              </div>
            </div>
            <div className="privacySection7">
              <div className="privacySection7Header privacyContentTitle">Enquiry Data</div>
              <div className="privacySection7Content privacyContent">
                <p>
                  We may process information contained in any enquiry you submit to us regarding
                  goods and/or services (“enquiry data“). The enquiry data may be processed for the
                  purposes of offering, marketing and selling relevant goods and/or services to you.
                  The legal basis for this processing is our legitimate interests, namely the proper
                  administration of our business.
                </p>
              </div>
            </div>
            <div className="privacySection8">
              <div className="privacySection8Header privacyContentTitle">
                Customer Relationship Data
              </div>
              <div className="privacySection8Content privacyContent">
                <p>
                  We may process information relating to our customer relationships, including
                  customer contact information (“customer relationship data“). The customer
                  relationship data may include your name, your employer, your job title or role,
                  your contact details, and information contained in communications between us and
                  you or your employer. The source of the customer relationship data is you or your
                  employer. The customer relationship data may be processed for the purposes of
                  managing our relationships with customers, communicating with customers, keeping
                  records of those communications and promoting our products and services to
                  customers. The legal basis for this processing is our legitimate interests, namely
                  the proper management of our customer relationships.
                </p>
              </div>
            </div>
            <div className="privacySection9">
              <div className="privacySection9Header privacyContentTitle">Job Application Data</div>
              <div className="privacySection9Content privacyContent">
                <p>
                  We may process information relating to job applications transactions (“job
                  application data“). The job application data data may include your contact
                  details, your gender, sociographic information, and employment & education
                  histories. The job application data may be processed for the purpose of properly
                  considering you for employment at FSJARS. The legal basis for this processing is
                  the performance of a contract between you and us and/or taking steps, at your
                  request, to enter into such a contract.
                </p>
              </div>
            </div>
            <div className="privacySection10">
              <div className="privacySection10Header privacyContentTitle">Transaction Data</div>
              <div className="privacySection10Content privacyContent">
                <p>
                  We may process information relating to transactions, including purchases of goods
                  and services that you enter into with us (“transaction data“). The transaction
                  data may include your contact details, your card details and the transaction
                  details. The transaction data may be processed for the purpose of supplying the
                  purchased goods and services and keeping proper records of those transactions. The
                  legal basis for this processing is the performance of a contract between you and
                  us and/or taking steps, at your request, to enter into such a contract and our
                  legitimate interests, namely the proper administration of our business.
                </p>
              </div>
            </div>
            <div className="privacySection11">
              <div className="privacySection11Header privacyContentTitle">Notification Data</div>
              <div className="privacySection11Content privacyContent">
                <p>
                  We may process information that you provide to us for the purpose of subscribing
                  to our email notifications and/or newsletters (“notification data“). The
                  notification data may be processed for the purposes of sending you the relevant
                  notifications and/or newsletters. The legal basis for this processing is our
                  legitimate interests, namely the proper administration of our email notifications
                  and/or newsletters.
                </p>
              </div>
            </div>
            <div className="privacySection12">
              <div className="privacySection12Header privacyContentTitle">Correspondence Data</div>
              <div className="privacySection12Content privacyContent">
                <p>
                  We may process information contained in or relating to any communication that you
                  send to us (“correspondence data“). The correspondence data may include the
                  communication content and metadata associated with the communication. Our website
                  will generate the metadata associated with communications made using the website
                  contact forms. The correspondence data may be processed for the purposes of
                  communicating with you and record-keeping. The legal basis for this processing is
                  our legitimate interests, namely the proper administration of our website and
                  business and communications with users.
                </p>
                <p>
                  We may process any of your personal data identified in this notice where necessary
                  for the establishment, exercise or defence of legal claims, whether in court
                  proceedings or in an administrative or out-of-court procedure. The legal basis for
                  this processing is our legitimate interests, namely the protection and assertion
                  of our legal rights, your legal rights and the legal rights of others.
                </p>
                <p>
                  We may process any of your personal data identified in this notice where necessary
                  for the purposes of obtaining or maintaining insurance coverage, managing risks,
                  or obtaining professional advice. The legal basis for this processing is our
                  legitimate interests, namely the proper protection of our business against risks.
                </p>
                <p>
                  In addition to the specific purposes for which we may process your personal data
                  set out above, we may also process any of your personal data where such processing
                  is necessary for compliance with a legal obligation to which we are subject, or in
                  order to protect your vital interests or the vital interests of another natural
                  person.
                </p>
                <p>
                  Please do not supply any other person’s personal data to us, unless we prompt you
                  to do so.
                </p>
              </div>
            </div>
            <div className="privacySection13">
              <div className="privacySection13Header privacyContentTitle">
                Disclosure of your personal data to others
              </div>
              <div className="privacySection13Content privacyContent">
                <p>
                  We may disclose your personal data to any member of our group of companies (this
                  means our subsidiaries, our ultimate holding company and all its subsidiaries)
                  insofar as reasonably necessary for the purposes, and on the legal bases, set out
                  in this notice.
                </p>
                <p>
                  We may disclose your personal data to our insurers and/or professional advisers
                  insofar as reasonably necessary for the purposes of obtaining or maintaining
                  insurance coverage, managing risks, obtaining professional advice, or the
                  establishment, exercise or defence of legal claims, whether in court proceedings
                  or in an administrative or out-of-court procedure.
                </p>
                <p>
                  Financial transactions relating to our services may be handled by our payment
                  services providers. We will share transaction data with our payment services
                  providers only to the extent necessary for the purposes of processing your
                  payments, refunding such payments and dealing with complaints and queries relating
                  to such payments and refunds.
                </p>
                <p>
                  In addition to the specific disclosures of personal data set out above, we may
                  disclose your personal data where such disclosure is necessary for compliance with
                  a legal obligation to which we are subject, or in order to protect your vital
                  interests or the vital interests of another natural person. We may also disclose
                  your personal data where such disclosure is necessary for the establishment,
                  exercise or defence of legal claims, whether in court proceedings or in an
                  administrative or out-of-court procedure.
                </p>
              </div>
            </div>
            <div className="privacySection14">
              <div className="privacySection14Header privacyContentTitle">
                International transfers of your personal data
              </div>
              <div className="privacySection14Content privacyContent">
                <p>
                  In this section, we provide information about the circumstances in which personal
                  data may be transferred to countries outside the India.
                </p>
                <p>The hosting facilities for our website are situated in India and USA.</p>
                <p>
                  In pursuit of the proper administration of our business and the provision of our
                  services we use various third party service providers. The categories of personal
                  data that may be transferred to these organisations are: Service data, publication
                  data, enquiry data, customer relationship data, transaction data, notification
                  data and correspondence data. Information regarding the location and privacy
                  provisions provided by these organisations in relation to this data can be found
                  in the list linked to above.
                </p>
                <p>
                  You acknowledge that personal data that you submit for publication through our
                  website or services may be available, via the internet, around the world. We
                  cannot prevent the use (or misuse) of such personal data by others.
                </p>
              </div>
            </div>
            <div className="privacySection15">
              <div className="privacySection15Header privacyContentTitle">
                Retaining and deleting personal data
              </div>
              <div className="privacySection15Content privacyContent">
                <p>
                  This Section sets out our data retention policies and procedure, which are
                  designed to help ensure that we comply with our legal obligations in relation to
                  the retention and deletion of personal data.
                </p>
                <p>
                  Personal data that we process for any purpose or purposes shall not be kept for
                  longer than is necessary for that purpose or those purposes.
                </p>
                <p>We will retain your personal data as follows:</p>
                <ol type="a">
                  <li>
                    Usage data will be retained for a minimum period of 6 months following use of
                    our website, and for a maximum period of 3 year following use of our website.
                  </li>
                  <li>
                    Service data will be retained for a minimum period of 1 year following the
                    expiration or cancellation of a client service agreement, and for a maximum
                    period of 3 years following the expiration or cancellation of a client service
                    agreement.
                  </li>
                  <li>Publication data will be retained indefinitely.</li>
                  <li>
                    Enquiry data will be retained for a minimum period of 1 year following the
                    original enquiry, and for a maximum period of 3 years following the original
                    enquiry.
                  </li>
                  <li>
                    Customer relationship data will be retained for a minimum period of 1 year
                    following the expiration or cancellation of a client service agreement, and for
                    a maximum period of 3 years following the expiration or cancellation of a client
                    service agreement.
                  </li>
                  <li>
                    Job Application data will be retained for a period of 1 year following the
                    original job application.
                  </li>
                  <li>Transaction data will be retained for 6 years.</li>
                  <li>
                    Notification data will be retained for a minimum period of 1 year following
                    subscription to our newsletter and for a maximum period of 3 years following
                    subscription to our newsletter.
                  </li>
                  <li>Correspondence data will be retained indefinitely.</li>
                </ol>
                <p>
                  Notwithstanding the other provisions of this section, we may retain your personal
                  data where such retention is necessary for compliance with a legal obligation to
                  which we are subject, or in order to protect your vital interests or the vital
                  interests of another natural person.
                </p>
              </div>
            </div>
            <div className="privacySection16">
              <div className="privacySection16Header privacyContentTitle">Amendments</div>
              <div className="privacySection16Content privacyContent">
                <p>
                  We may update this notice from time to time by publishing a new version on our
                  website.
                </p>
                <p>
                  You should check this page occasionally to ensure you are happy with any changes
                  to this notice.
                </p>
                <p>We may notify you of significant changes to this notice by email.</p>
              </div>
            </div>
            <div className="privacySection17">
              <div className="privacySection17Header privacyContentTitle">Your rights</div>
              <div className="privacySection17Content privacyContent">
                In this section, we have summarised the rights that you have under data protection
                law. Some of the rights are complex, and not all of the details have been included
                in our summaries. Accordingly, you should read the relevant laws and guidance from
                the regulatory authorities for a full explanation of these rights. Your principal
                rights under data protection law are:
                <ol type="a">
                  <li>the right to access;</li>
                  <li>the right to rectification;</li>
                  <li>the right to erasure;</li>
                  <li>the right to restrict processing;</li>
                  <li>the right to object to processing;</li>
                  <li>the right to data portability;</li>
                  <li>the right to complain to a supervisory authority; </li>
                  <li>the right to withdraw consent.</li>
                </ol>
                <p>
                  You have the right to confirmation as to whether or not we process your personal
                  data and, where we do, access to the personal data, together with certain
                  additional information. That additional information includes details of the
                  purposes of the processing, the categories of personal data concerned and the
                  recipients of the personal data. Providing the rights and freedoms of others are
                  not affected, we will supply to you a copy of your personal data. The first copy
                  will be provided free of charge, but additional copies may be subject to a
                  reasonable fee.
                </p>
                <p>
                  You have the right to have any inaccurate personal data about you rectified and,
                  taking into account the purposes of the processing, to have any incomplete
                  personal data about you completed.
                </p>
                <p>
                  In some circumstances you have the right to the erasure of your personal data
                  without undue delay. Those circumstances include: the personal data are no longer
                  necessary in relation to the purposes for which they were collected or otherwise
                  processed; you withdraw consent to consent-based processing; you object to the
                  processing under certain rules of applicable data protection law; the processing
                  is for direct marketing purposes; and the personal data have been unlawfully
                  processed. However, there are exclusions of the right to erasure. The general
                  exclusions include where processing is necessary: for exercising the right of
                  freedom of expression and information; for compliance with a legal obligation; or
                  for the establishment, exercise or defence of legal claims.
                </p>
                <p>
                  In some circumstances you have the right to restrict the processing of your
                  personal data. Those circumstances are: you contest the accuracy of the personal
                  data; processing is unlawful but you oppose erasure; we no longer need the
                  personal data for the purposes of our processing, but you require personal data
                  for the establishment, exercise or defence of legal claims; and you have objected
                  to processing, pending the verification of that objection. Where processing has
                  been restricted on this basis, we may continue to store your personal data.
                  However, we will only otherwise process it: with your consent; for the
                  establishment, exercise or defence of legal claims; for the protection of the
                  rights of another natural or legal person; or for reasons of important public
                  interest.
                </p>
                <p>
                  You have the right to object to our processing of your personal data on grounds
                  relating to your particular situation, but only to the extent that the legal basis
                  for the processing is that the processing is necessary for: the performance of a
                  task carried out in the public interest or in the exercise of any official
                  authority vested in us; or the purposes of the legitimate interests pursued by us
                  or by a third party. If you make such an objection, we will cease to process the
                  personal information unless we can demonstrate compelling legitimate grounds for
                  the processing which override your interests, rights and freedoms, or the
                  processing is for the establishment, exercise or defence of legal claims.
                </p>
                <p>
                  You have the right to object to our processing of your personal data for direct
                  marketing purposes (including profiling for direct marketing purposes). If you
                  make such an objection, we will cease to process your personal data for this
                  purpose.
                </p>
                <p>
                  To the extent that the legal basis for our processing of your personal data is:
                  <ol type="a">
                    <li>consent; or</li>
                    <li>
                      that the processing is necessary for the performance of a contract to which
                      you are party or in order to take steps at your request prior to entering into
                      a contract,
                    </li>
                  </ol>
                  and such processing is carried out by automated means, you have the right to
                  receive your personal data from us in a structured, commonly used and
                  machine-readable format. However, this right does not apply where it would
                  adversely affect the rights and freedoms of others.
                </p>
                <p>
                  If you consider that our processing of your personal information infringes data
                  protection laws, you have a legal right to lodge a complaint with a supervisory
                  authority responsible for data protection. You may do so in the Indian state of
                  your habitual residence, your place of work or the place of the alleged
                  infringement.
                </p>
                <p>
                  To the extent that the legal basis for our processing of your personal information
                  is consent, you have the right to withdraw that consent at any time. Withdrawal
                  will not affect the lawfulness of processing before the withdrawal.
                </p>
              </div>
            </div>
            <div className="privacySection18">
              <div className="privacySection18Header privacyContentTitle">DO WE USE 'COOKIES'?</div>
              <div className="privacySection18Content privacyContent">
                <p>
                  Yes. Cookies are small files that a site or its service provider transfers to your
                  computer's hard drive through your Web browser (if you allow) that enables the
                  site's or service provider's systems to recognize your browser and capture and
                  remember certain information. For instance, we use cookies to help us remember and
                  process the items in your shopping cart. They are also used to help us understand
                  your preferences based on previous or current site activity, which enables us to
                  provide you with improved services. We also use cookies to help us compile
                  aggregate data about site traffic and site interaction so that we can offer better
                  site experiences and tools in the future.
                </p>
              </div>
            </div>
            <div className="privacySection19">
              <div className="privacySection19Header privacyContentTitle">HOW WE USE COOKIES</div>
              <div className="privacySection19Content privacyContent">
                <p>
                  A cookie is a small file which asks permission to be placed on your computer's
                  hard drive. Once you agree, the file is added and the cookie helps analyse web
                  traffic or lets you know when you visit a particular site. Cookies allow web
                  applications to respond to you as an individual. The web application can tailor
                  its operations to your needs, likes and dislikes by gathering and remembering
                  information about your preferences.
                </p>
                <p>
                  We use traffic log cookies to identify which pages are being used. This helps us
                  analyse data about webpage traffic and improve our website in order to tailor it
                  to customer needs. We only use this information for statistical analysis purposes
                  and then the data is removed from the system.
                </p>
                <p>
                  Overall, cookies help us provide you with a better website by enabling us to
                  monitor which pages you find useful and which you do not. A cookie in no way gives
                  us access to your computer or any information about you, other than the data you
                  choose to share with us.
                </p>
                <p>
                  You can choose to accept or decline cookies. Most web browsers automatically
                  accept cookies, but you can usually modify your browser setting to decline cookies
                  if you prefer. This may prevent you from taking full advantage of the website.
                </p>
              </div>
            </div>
            <div className="privacySection20">
              <div className="privacySection20Header privacyContentTitle">
                Understand and save user's preferences for future visits.
              </div>
              <div className="privacySection20Content privacyContent">
                <p>Keep track of advertisements.</p>
                <p>
                  Compile aggregate data about site traffic and site interactions in order to offer
                  better site experiences and tools in the future. We may also use trusted
                  third-party services that track this information on our behalf.
                </p>
                <p>
                  You can choose to have your computer warn you each time a cookie is being sent, or
                  you can choose to turn off all cookies. You do this through your browser settings.
                  Since browser is a little different, look at your browser's Help Menu to learn the
                  correct way to modify your cookies.
                </p>
                <p>
                  If you turn cookies off, some features will be disabled. It won't affect the
                  user's experience that make your site experience more efficient and may not
                  function properly.
                </p>
                <p>However, you will still be able to place orders.</p>
              </div>
            </div>
            <div className="privacySection21">
              <div className="privacySection21Header privacyContentTitle">
                THIRD-PARTY DISCLOSURE
              </div>
              <div className="privacySection21Content privacyContent">
                <p>
                  We do not sell, trade, or otherwise transfer to outside parties your Personally
                  Identifiable Information unless we provide users with advance notice. This does
                  not include website hosting partners and other parties who assist us in operating
                  our website, conducting our business, or serving our users, so long as those
                  parties agree to keep this information confidential. We may also release
                  information when it's release is appropriate to comply with the law, enforce our
                  site policies, or protect ours or others' rights, property or safety.
                </p>
                <p>
                  However, non-personally identifiable visitor information may be provided to other
                  parties for marketing, advertising, or other uses.
                </p>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Privacy;
