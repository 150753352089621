import React, { Component } from 'react';
import "./ExistingBusiness.css";

class ExistingBusiness extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            isShow : true
         };
    }
    render() {
        return (
            <React.Fragment>
                <header className='ExistingBusinessHeader'>
                    <div className='ExistingBusinessImage row'>
                    <div className="transparentLayer">
                            
                        </div>
                        {/* <img  src={ExistingBusiness} alt="ExistingBusinessImage" className='col-12'/> */}
                    </div>
                    <div className="ExistingBusinessTitle">
                        <strong><span className='ExistingBusinessTitle1'>HAVE AN </span>EXISTING BUSINESS</strong>
                    </div>
                </header>
                <div className="ExistingBusinessContent container">
                    <div className="ExistingBusinessSection1">
                        <center className='ExistingBusinessContentTagLine'><i class="fa fa-quote-left" aria-hidden="true"></i><h4><strong>We provide affordable IT solutions to you.</strong></h4></center>
                        <p className='ExistingBusinessIntro'>
                        <strong>Whether you want to build a website, mobile application, e-commerce website we are here to help you. When you choose FSJARS you don’t have to worry about hosting, content creation, modification to your IT solution or support and maintenance we will do it for you..</strong>
                            {/* <span onClick={this.handleChange} className='learnMoreText show'>[Learn More...]</span> */}
                        </p>
                        { this.state.isShow &&
                            <div className="learnMore">
                                <div className='ExistingBusinessSection2'>
                                    <div className="ExistingBusinessSection2Intro">
                                        <p> 
                                        We are dedicated to your business idea and we will truly support your vision of success. With our website design and development services, mobile application services, support and maintenance services, machine learning and AI as well as SEO services, you can be assured that you will always have an upper hand over competitors.
                                        </p>
                                        <p>
                                        Our team of smart minds will build applications and websites as per your requirement and add several new features that can help you generate more traffic and revenue to your online business. Our ecommerce services are flawless and secure, which will help you display your products online and expand your consumer base exponentially.
                                        </p> 
                                    </div>
                                </div>
                                
                                <center className='sloganFooter'><h3><strong >You can trust us.</strong></h3></center>
                            </div>  
                        }
                    </div>

                </div>
            </React.Fragment>
        );
    }
}

export default ExistingBusiness;