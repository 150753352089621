import React, { Component } from 'react';
import './Career.css';
import { Helmet } from 'react-helmet';

class Career extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShow: true,
    };
  }
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Careers</title>
        </Helmet>
        <div className="genericContainer genericContainerHeader">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-6 col-12">
              <div className="hero-software content hero-feature-content">
                <strong>
                  <span className="TeamPageTitle1">Careers</span>
                </strong>
                {/* <p className="alignCenter">Please read the document carefully. </p> */}
              </div>
            </div>
          </div>
        </div>
        <div className="CareerContent container">
          <div className="CareerSection1">
            <center className="CareerContentTagLine">
              <i class="fa fa-quote-left" aria-hidden="true" />
              <h4>
                <strong>Pursue a fulfilling career path with us!</strong>
              </h4>
            </center>
            <p className="CareerIntro">
              FSJARS is fully devoted to helping you build your career and we fully encourage
              innovation and focused work in our company. We provide steady guidance along with an
              enriching practical experience that can benefit you in the course of your career.
            </p>
            {this.state.isShow && (
              <div className="learnMore">
                <div className="CareerSection2">
                  <strong>
                    <span className="CareerSection2Title1 CareerSectionTitle1">
                      Why is working with us beneficial?
                    </span>
                    {/* <span className='CareerSection2Title2 CareerSectionTitle2'>HE INSPIRATION</span> */}
                  </strong>
                  <div className="CareerSection2Intro">
                    <p>
                      <strong>FSJARS</strong> is a technology-driven company which works with new
                      and upcoming technologies (eg. MEAN and MERN stack development) which pave a
                      path for innovation. We keep abreast with changes in the technologies we
                      utilize and keep up to date with new and upcoming versions of the same. We
                      provide the best products to our clients and are dedicated to the
                      incorporation of new technology that suits their business needs.
                    </p>
                    <p>
                      As we are a growing team, the scope of learning with us increases
                      exponentially. Working with our revered team will make you aware of the fact
                      that the sky’s the limit with us. We encourage innovation and driven focus on
                      new emerging technologies that will quickly make you a pioneer in our field.{' '}
                    </p>
                  </div>
                </div>
                <div className="CareerSection3">
                  <strong>
                    <span className="CareerSection3Title1 CareerSectionTitle1">
                      Perks of working with FSJARS
                    </span>
                    {/* <span className='CareerSection3Title2 CareerSectionTitle2'>ISSION</span> */}
                  </strong>
                  <div className="CareerSection3Intro">
                    <p>
                      <strong>FSJARS</strong> provides an optimal environment that encourages
                      learning and overall development of any individual that works with us. We
                      provide-
                    </p>
                    <ol>
                      <li>
                        <p>
                          <strong className="CareerSection3SubIntro">Flexibility </strong> - Our
                          work hours or job timings are flexible which give you the time and space
                          you need to get the job done. We provide an environment that promotes
                          growth and we know that it isn’t possible in a 9-5 mundane routine.{' '}
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong className="CareerSection3SubIntro">Innovation </strong> - We take
                          up challenging projects that counter crucial business problems and build
                          our projects from scratch. As a result, our team is a pot brimming with
                          new ideas. This is a stimulating and encouraging environment that will
                          bring out the best in you.{' '}
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong className="CareerSection3SubIntro">Make an impact </strong> - As
                          an addition to our family, you can cause a lasting impact with your work
                          in our company. We give you the platform and the resources to create,
                          innovate, and implement your ideas that hold enormous potential and have a
                          lasting effect on our clients.{' '}
                        </p>
                      </li>
                    </ol>
                  </div>
                </div>

                <center className="sloganFooter">
                  <h3>
                    If you think that we are what you’re looking for, send your resumes to{' '}
                    <strong>
                      {' '}
                      <a href="mailto:careers@fsjars.com">careers@fsjars.com </a>
                    </strong>{' '}
                    fsjars or contact us{' '}
                    <strong>
                      {' '}
                      <a href="https://wa.me/919175514982">9175514982 </a>
                    </strong>
                  </h3>
                </center>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Career;
