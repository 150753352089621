/* eslint-disable linebreak-style */
const Error_Type = {
  ValidationError: 0,
  InternalError: 1,
  DbError: 2,
  FatalError: 4,
  AuthError: 3,
};
const ApiUrl = {
  Api: 'http://generic.fsjars.letswegrow.com',
};

export { ApiUrl, Error_Type };
