import React, { Component } from 'react'
import './Footer.css'
import Sitemap from '../Sitemap/Sitemap';
import {Link} from 'react-router-dom';

export class Footer extends Component {
  render() {
    return (
      <div>
        {/* <div className="custom-footer">
        <footer className="container">
            <p className="float-right"><a href="#"><i class="fa fa-arrow-circle-up" aria-hidden="true"></i></a></p>
            <p className="text-center">&copy; 2017-2018 Edunomics. All rights reserved.</p>
      </footer>
      </div> */}
         {/*footer */}
          <Sitemap/>
          <footer className=" footer custom-footer-section  center-block">
                  <div className="row ">
                    <div className="col-md-3 socialIcon text-center">
                      <a href="https://www.linkedin.com/company/fsjars/about/" target='_blank'><i className='fa fa-linkedin-square linkedin'></i></a><a href="https://www.facebook.com/company/fsjars/about/" target='_blank'><i className='fa fa-facebook-square facebook'></i></a>
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-11 col-xs-11 footer-text">
                      <p className="footer-line">Copyright &copy; 2018.All Rights Reserved.Designed By <a href="https://www.fsjars.com"><strong >FSJARS</strong></a> | <Link to='/privacy' target="_blank">Privacy</Link> | Disclaimer | <Link to='/termsandcondition' target="_blank">Terms and Condition</Link></p>
                    </div>
                  </div>
                  {/* <div className='row'>
                      <div className='col-md-12 footer-social-div'>
                          <ul className='footer-social'>
                            <li><a href="https://www.linkedin.com/company/fsjars/about/"><i className='fa fa-linkedin-square linkedin'></i></a></li>
                            <li><a href="https://www.facebook.com/company/fsjars/about/"><i className='fa fa-facebook-square facebook'></i></a></li>
                            <li><a href='https://www.google-plus.com/company/fsjars/about/'><i className='fa fa-google-plus-square google-plus'></i></a></li>
                          </ul>
                      </div>
                    </div> */}
          </footer>

    {/* end foter */}
      </div>
    )
  }
}

export default Footer
