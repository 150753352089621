import React from 'react';
import WhyUsImage from '../../img/WhyUs.jpg';
import './WhyUs.css';
import { Helmet } from 'react-helmet';
class WhyUs extends React.Component {
  componentDidMount() {
    // window.addEventListener('scroll', (event) => {
    //     this.checkView();
    // })
  }
  // checkView=()=>{
  //     var animation_elements = Array.from((document.querySelector('.whyUsContent')).children);
  //     console.log('animation Element ',animation_elements);
  //     var window_height = window.screen.availHeight;
  //     var window_top_position = window.scrollY;
  //     var window_bottom_position = (window_top_position + window_height);
  //     console.log('window height ',window_height);
  //     console.log('window_top_position ',window_top_position);
  //     console.log('widnow bottom pos ',window_bottom_position);
  //     animation_elements.forEach(function(element){
  //         var element_height = element.availHeight;
  //         var element_top_position = element.offsetTop;
  //         var element_bottom_position = (element_top_position + element_height);

  //         //check to see if this current container is within viewport
  //         if ((element_bottom_position >= window_top_position) &&
  //             (element_top_position <= window_bottom_position)) {
  //             element.classList.add('in-view');
  //         } else {
  //             element.classList.remove('in-view');
  //         }
  //     });
  // }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Why You Should Choose FSJARS</title>
        </Helmet>
        <div className="genericContainer">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-6 col-12">
              <div className="hero-software content hero-feature-content">
              <strong><span className='aboutUsTitle1'>Why</span> Us</strong>
                {/* <div className="whyUsTitleContent"> */}
                  <div className="alignCenter whyUsTitleContent"> FSJARS is a company that is all about customer relationships. We believe in
                  keeping the customer happy and our services are tailor-made to suit your business
                  needs. Our main goal is to keep the customer smiling.</div> 
                {/* </div>
                {/* <p className="alignCenter">Please read the document carefully. </p> */}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="whyUsHeader">
          <div className="whyUsImage row">
            <div className="transparentLayer" />
          </div>
          <div className="whyUsTitle">
            <strong className="whyUsTitleHead">
              <span className="whyUsTitle1">WHY </span>US
            </strong>
            <div className="whyUsTitleContent">
              FSJARS is a company that is all about customer relationships. We believe in keeping
              the customer happy and our services are tailor-made to suit your business needs. Our
              main goal is to keep the customer smiling.
            </div>
          </div>
        </div> */}
        <div className="whyUsContent container">
          <div className="whyUsSection1 whyUsSectionContent row">
            <div className="col-md-8">
              <div style={{ textAlign: 'center', color: '#6AA85D' }}>
                <strong>OUR BELEIVE IN YOUR POTENTIAL</strong>
              </div>
              <p>
                We ensure that all resources are present with you when you go ahead with your
                business venture. We develop your website free of cost because we are the stepping
                stone to your success. We believe in you.
              </p>
            </div>
          </div>
          <br />
          <div className="whyUsSection2 whyUsSectionContent row">
            <div className="col-md-4" />
            <div className="col-md-8">
              <div style={{ textAlign: 'center' }}>
                <strong>OUR WIDE RANGE OF SERVICES</strong>
              </div>
              <p>
                {' '}
                We provide all types of services which include web development, mobile app
                development, search engine optimization (SEO), product development, E-commerce
                services, and Machine Learning and AI services. These diverse services lead to an
                all-round digital development for your business.
              </p>
            </div>
          </div>
          <br />
          <div className="whyUsSection3 whyUsSectionContent row">
            <div className="col-md-8">
              <div style={{ textAlign: 'center', color: '#6AA85D' }}>
                <strong>OUR CARE FOR YOUR PRODUCT</strong>
              </div>
              <p>
                We build your product from scratch. Our team of experts is dedicated to your product
                since day 1. We also help you with your business model by giving the most useful
                advice which helps in shaping your business in the right direction.
              </p>
            </div>
          </div>
          <br />
          <div className="whyUsSection4 whyUsSectionContent row">
            <div className="col-md-4" />
            <div className="col-md-8">
              <div style={{ textAlign: 'center' }}>
                <strong>OUR SUPPORT TO THE END AND BEYOND</strong>
              </div>
              <p>
                We help our customers even after the project is complete. Our company provides the
                best support and maintenance services which will help your website and product
                remain at the top no matter what.{' '}
              </p>
            </div>
          </div>
          <br />
          <div className="whyUsSection5 whyUsSectionContent row">
            <div className="col-md-8">
              <div style={{ textAlign: 'center', color: '#6AA85D' }}>
                <strong>OUR GOAL TO MAKE YOU STANDOUT</strong>
              </div>
              <p>
                Our SEO services help your website reach the top in Google searches and gives you a
                competitive edge over competitors. We wish to see you stand out amongst your
                competition which propels us to give you the best web development, mobile app
                development, and e-commerce services.
              </p>
            </div>
          </div>
          <br />
          <div className="whyUsSection6 whyUsSectionContent row">
            <div className="col-md-4" />
            <div className="col-md-8">
              <div style={{ textAlign: 'center' }}>
                <strong>OUR EAR ARE OPEN FOR YOU</strong>
              </div>
              <p>
                We keep your concerns as our top priority. We ensure that your requirements are met
                from point to point. We ensure that all your grievances are heard and solve
                immediately because the customer stands at the top in our priority chart.
              </p>
            </div>
          </div>
        </div>
        <div className="whyUsFooter">
          FSJARS is a company that is well-versed with new technological developments that will help
          your business keep abreast with changing times and still stand at the top.
        </div>
      </React.Fragment>
    );
  }
}
export default WhyUs;
