import React, { Component } from 'react';
import './Main.css';
import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer';
import Home from './Components/Home/Home';
import AboutUs from './Components/AboutUs/aboutUsPage';
import { Switch, Route } from 'react-router-dom';
import WhyUs from './Components/WhyUs/WhyUs';
import ComingSoon from './Components/ComingSoon/ComingSoon';
import ErrorPage from './Components/ErrorPage/ErrorPage';
import Contact from './Components/Contact/Contact';
import WebDev from './Components/DetailsPage/WebDev/WebDev';
import MobDev from './Components/DetailsPage/MobDev/MobDev';
import eComDev from './Components/DetailsPage/E-Com/E-Com';
import supportMaint from './Components/DetailsPage/SupportAndMaint/SupportAndMaint';
import machineLearnAI from './Components/DetailsPage/MachineLearningAndAI/MachineLearningAndAI';
import CustomerSoftProduct from './Components/DetailsPage/CustSoftProd/CustSoftProd';
import TeamPage from './Components/TeamPage/TeamPage';
import Portfolio from './Components/Portfolio/Portfolio';
import Grievance from './Components/Grievance/Grievance';
import TestimonialPage from './Components/TestimonialPage/TestimonialPage';
import Hosting from './Components/DetailsPage/Hosting/Hosting';
import BecomeOurPartner from './Components/BecomeOurPartner/BecomeOurPartner';
import OurPartner from './Components/OurPartner/OurPartner';
import Privacy from './Components/Privacy/Privacy';
import TermsAndCondition from './Components/Terms_and_Condition/Terms_and_Condition';
import HaveABusiness from './Components/HomeCarouselPage/HaveABusiness/HaveABusiness';
import ExistingBusiness from './Components/HomeCarouselPage/ExistingBusiness/ExistingBusiness';
import PillarBusiness from './Components/HomeCarouselPage/PillarBusiness/PillarBusiness';
import FAQ from './Components/FAQ/FAQ';
import Career from './Components/Career/Career';
import GenericPage from './Components/GenericPage/GenericPage';
import { Helmet } from 'react-helmet';
class Main extends Component {
  render() {
    return (
      <div className="website">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Welcome to FSJARS: Get your free website built today</title>
        </Helmet>
        <Navbar history={this.props.history}/>
        <a href="https://wa.me/919175514982" target="_blank">
          <button id="whatsAppBtn" title="WhatsApp us at ">
            <i className="fa fa-whatsapp" />
          </button>
        </a>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/aboutUs" exact component={AboutUs} />
          <Route path="/whyUs" exact component={WhyUs} />
          <Route path="/becomeOurPartners" exact component={BecomeOurPartner} />
          <Route path="/comingSoon" exact component={ComingSoon} />
          <Route path="/contact" exact component={Contact} />
          <Route path="/webDevelopment" exact component={WebDev} />
          <Route path="/mobDevelopment" exact component={MobDev} />
          <Route path="/eCommerceDev" exact component={eComDev} />
          <Route path="/supportAndMaintenance" exact component={supportMaint} />
          <Route path="/machineLearningAndAI" exact component={machineLearnAI} />
          <Route path="/customerSoftwareProduct" exact component={CustomerSoftProduct} />
          <Route path="/teamPortFolio" exact component={TeamPage} />
          <Route path="/portfolio" exact component={Portfolio} />
          <Route path="/grievance" exact component={Grievance} />
          <Route path="/testimonial" exact component={TestimonialPage} />
          <Route path="/hosting" exact component={Hosting} />
          <Route path="/ourPartner" exact component={OurPartner} />
          <Route path="/privacy" exact component={Privacy} />
          <Route path="/termsandcondition" exact component={TermsAndCondition} />
          <Route path="/haveabusiness" exact component={HaveABusiness} />
          <Route path="/existingbusiness" exact component={ExistingBusiness} />
          <Route path="/pillarbusiness" exact component={PillarBusiness} />
          <Route path="/FAQ" exact component={FAQ} />
          <Route path="/career" exact component={Career} />
          <Route path="/generic/page" exact component={GenericPage} />
          <Route path="*" component={ErrorPage} />
        </Switch>
        <Footer />
      </div>
    );
  }
}

export default Main;
