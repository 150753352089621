import React, { Component } from 'react';
import './Terms_and_Condition.css';
import { Helmet } from 'react-helmet';

class TermsAndCondition extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Fsjars Terms and Condition</title>
        </Helmet>
        <header className="tAndCHeader">
          <div className="container">
            <h1>TERMS AND CONDITION</h1>
          </div>
        </header>
        <div className="tAndCContent container">
          <div className="tAndCSections">
            <div className="tAndCSection1">
              <div className="tAndCSection1Header tAndCSectionHeader">OVERVIEW</div>
              <div className="tAndCSection1Content tAndCSectionContent">
                <p>
                  The following are terms of a legal agreement between you and FSJARS. By accessing,
                  browsing, or using this Web site, you acknowledge that you have read, understood,
                  and agree to be bound by these terms and to comply with all applicable laws and
                  regulations, including export and re-export control laws and regulations. If you
                  do not agree to these terms, please do not use this Web site. FSJARS may, without
                  notice to you, at any time revise these Terms of Use and any other information
                  contained in this Web site by updating this posting. FSJARS may also make
                  improvements or changes in the products, services, or programs described in this
                  site at any time without notice.
                </p>
              </div>
            </div>
            <div className="tAndCSection2">
              <div className="tAndCSection2Header tAndCSectionHeader">GENERAL</div>
              <div className="tAndCSection2Content tAndCSectionContent">
                <p>
                  FSJARS and FSJARS’s logo are registered trademarks of FSJARS technologies in the
                  United States and other countries. Products and specifications discussed in this
                  document may reflect future versions and are subject to change by FSJARS Ltd.
                  without notice. This Web site contains proprietary notices and copyright
                  information, the terms of which must be observed and followed. This site and all
                  content in this site may not be copied, reproduced, republished, uploaded, posted,
                  transmitted, distributed, or used for the creation of derivative works without
                  FSJARS’s prior written consent, except that FSJARS grants you non-exclusive,
                  non-transferable, limited permission to access and display the Web pages within
                  this site, solely on your computer and for your personal, non-commercial use of
                  this Web site. This permission is conditioned on your not modifying the content
                  displayed on this site, your keeping intact all copyright, trademark, and other
                  proprietary notices, and your acceptance of any terms, conditions, and notices
                  accompanying the content or otherwise set forth in this site. Notwithstanding the
                  foregoing, any software and other materials that are made available for
                  downloading, access, or other use from this site with their own license terms,
                  conditions, and notices will be governed by such terms, conditions, and notices.
                  Your failure to comply with the terms, conditions, and notices on this site will
                  result in automatic termination of any rights granted to you, without prior
                  notice, and you must immediately destroy all copies of downloaded materials in
                  your possession or control. Except for the limited permission in the preceding
                  paragraph, FSJARS does not grant you any express or implied rights or licenses
                  under any patents, trademarks, copyrights, or other proprietary or intellectual
                  property rights. You may not mirror any of the content from this site on another
                  Web site or in any other media.
                </p>
              </div>
            </div>
            <div className="tAndCSection3">
              <div className="tAndCSection3Header tAndCSectionHeader">SECURITY</div>
              <div className="tAndCSection3Content tAndCSectionContent">
                <p>
                  We are committed to ensuring that your information is secure. In order to prevent
                  unauthorised access or disclosure, we have put in place suitable physical,
                  electronic and managerial procedures to safeguard and secure the information we
                  collect online.
                </p>
              </div>
            </div>
            <div className="tAndCSection4">
              <div className="tAndCSection4Header tAndCSectionHeader">CERTAIN DISCLAIMERS</div>
              <div className="tAndCSection4Content tAndCSectionContent">
                <p>
                  Information on this Web site is not promised or guaranteed to be correct, current,
                  or complete, and this site may contain technical inaccuracies or typographical
                  errors. FSJARS assumes no responsibility (and expressly disclaims responsibility)
                  for updating this site to keep information current or to ensure the accuracy or
                  completeness of any posted information. Accordingly, you should confirm the
                  accuracy and completeness of all posted information before making any decision
                  related to any services, products, or other matters described in this site. FSJARS
                  provides no assurances that any reported problems will be resolved by FSJARS, even
                  if FSJARS elects to provide information with the goal of addressing a problem.
                </p>
              </div>
            </div>
            <div className="tAndCSection5">
              <div className="tAndCSection5Header tAndCSectionHeader">CONFIDENTIAL INFORMATION</div>
              <div className="tAndCSection5Content tAndCSectionContent">
                <p>
                  FSJARS does not want to receive confidential or proprietary information from you
                  through our Web site. Please note that any information or material sent to FSJARS
                  will be deemed NOT to be confidential. By sending FSJARS any information or
                  material, you grant FSJARS an unrestricted, irrevocable license to copy,
                  reproduce, publish, upload, post, transmit, distribute, publicly display, perform,
                  modify, create derivative works from, and otherwise freely use, those materials or
                  information. You also agree that FSJARS is free to use any ideas, concepts,
                  know-how, or techniques that you send us for any purpose. However, we will not
                  release your name or otherwise publicize the fact that you submitted materials or
                  other information to us unless: (a) we obtain your permission to use your name; or
                  (b) we first notify you that the materials or other information you submit to a
                  particular part of this site will be published or otherwise used with your name on
                  it; or (c) we are required to do so by law. Personally-identifiable information
                  that you submit to FSJARS for the purpose of receiving products or services will
                  be handled in accordance with our privacy policies. Please see the tab entitled
                  ?Privacy? for information regarding FSJARS privacy policies.
                </p>
              </div>
            </div>
            <div className="tAndCSection6">
              <div className="tAndCSection6Header tAndCSectionHeader">BUSINESS RELATIONSHIPS</div>
              <div className="tAndCSection6Content tAndCSectionContent">
                <p>
                  This Web site may provide links or references to non-FSJARS Web sites and
                  resources. FSJARS makes no representations, warranties, or other commitments
                  whatsoever about any non-FSJARS Web sites or third-party resources that may be
                  referenced, accessible from, or linked to any FSJARS site. A link to a non-FSJARS
                  Web site does not mean that FSJARS endorses the content or use of such Web site or
                  its owner. In addition, FSJARS is not a party to or responsible for any
                  transactions you may enter into with third parties, even if you learn of such
                  parties (or use a link to such parties) from an FSJARS site. Accordingly, you
                  acknowledge and agree that FSJARS is not responsible for the availability of such
                  external sites or resources, and is not responsible or liable for any content,
                  services, products, or other materials on or available from those sites or
                  resources. When you access a non-FSJARS Web site, even one that may contain the
                  FSJARS-logo, please understand that it is independent from FSJARS, and that FSJARS
                  does not control the content on that Web site. It is up to you to take precautions
                  to protect yourself from viruses, worms, trojan horses, and other potentially
                  destructive programs, and to protect your information as you deem appropriate.
                </p>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default TermsAndCondition;
