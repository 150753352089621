import React, { Component } from 'react';
import './Sitemap.css';
import {Link} from 'react-router-dom';

class Sitemap extends Component {
    constructor(props) {
        super(props);
        this.state = {  };
    }
    render() {
        return (
            <React.Fragment>
                <div className="SitemapContact row">
                    <div className="col-md-12">
                        <div className="Sitemap row">
                            <div className="col-md-3">
                                <strong className='sitemapTitle'>Quick Link</strong>
                                <ul>
                                    <li><Link to='/'>Home</Link></li>
                                    <li><Link to='/portfolio'>Portfolio</Link></li>
                                    <li><Link to='/contact'>Contact</Link></li>
                                    <li><Link to='/pricing'>Pricing</Link></li>
                                    <li><a style={{cursor : 'pointer'}} data-toggle="modal" data-target="#myModal">Get Quotes</a></li>
                                    <li><Link to='/comingSoon'>Case Studies</Link></li>
                                </ul>
                            </div>
                            <div className="col-md-3">
                            <strong className='sitemapTitle'>Company</strong>
                                <ul>
                                    <li><Link to='/aboutUs'>About</Link></li>
                                    <li><Link to='/whyUs'>Why Us</Link></li>
                                    <li><Link to='/contact'>Contact</Link></li>
                                    <li><Link to='/comingSoon'>Portfolio</Link></li>
                                    <li><Link to='/testimonial'>Testimonials</Link></li>
                                    <li><Link to='/career'>Careers</Link></li>
                                    <li><Link to='/teamPortFolio'>Team</Link></li>
                                    <li><Link to='/ourPartners'>Our Partners</Link></li>
                                    <li><Link to='/comingSoon'>Be A Partner</Link></li>
                                </ul>
                            </div>
                            <div className="col-md-3">
                                <strong className='sitemapTitle'>Services</strong>
                                <ul>
                                    <li><Link to='/webDevelopment'>Web Development</Link></li>
                                    <li><Link to='/mobDevelopment'>Mobile App Development</Link></li>
                                    <li><Link to='/eCommerceDev'>E-Commerce</Link></li>
                                    <li><Link to='/customerSoftwareProduct'>Custom Software Product</Link></li>
                                    <li><Link to='/supportAndMaintenance'>Support & Maintenance</Link></li>
                                    <li><Link to='/machineLearningAndAI'>Machine Learning & AI</Link></li>
                                </ul>
                            </div>
                            <div className="col-md-3">
                                <strong className='sitemapTitle'>Help And Support</strong>
                                <ul>
                                    <li><Link to='/FAQ'>FAQ</Link></li>
                                    <li><Link to='/grievance'>Raise A Grievance</Link></li>
                                    <li><Link to='/comingSoon'>Open Support Ticket</Link></li>
                                    <li><Link to='/comingSoon'>Customer Login</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* <div className="ContactUs col-md-4">
                        <div className="row">
                            <div className="col-12">
                                <h2 className="ContactUsTitle"><strong>Contact Us</strong></h2>
                                <hr className="below-header-hr"></hr>
                            </div>
                        </div>
                        
                        <div className="row custom-footer-section">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                                <div className="custom-contact-content-upper text-center-contact contactUsGroup">
                                <div className="row">
                                    <div className="col-sm-3" style={{textAlign : 'end'}}>
                                        <i className="fa fa-phone fa-2x" aria-hidden="true" />
                                    </div>
                                    <div className="col-sm-9 contactUsText" style={{textAlign:'start'}}>
                                        <p>+(91) 9175514982</p>
                                    </div>
                                </div>
                                
                                </div>
                            </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                                    <div className="custom-contact-content-upper text-center-contact contactUsGroup">
                                    <div className="row">
                                        <div className="col-sm-3" style={{textAlign : 'end'}}>
                                            <i className="fa fa-envelope fa-2x" aria-hidden="true" />
                                        </div>
                                        <div className="col-sm-9 contactUsText emailContact" style={{textAlign:'start'}}>
                                            <p><a href="mailto:contact@fsjars.com" target="_top" className="col-lg-3 col-md-3 col-sm-12 col-12">contact@fsjars.com</a></p>
                                        </div>
                                    </div>
                                    
                                    
                                    </div>
                                </div>

                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="custom-contact-content-upper text-center-contact contactUsGroup">
                                <div className="row">
                                    <div className="col-sm-3" style={{textAlign : 'end'}}>
                                        <i className="fa fa-map-marker fa-2x" aria-hidden="true" />
                                    </div>
                                    <div className="col-sm-9 contactUsText" style={{textAlign:'start'}}>
                                        <p>Shop No. 6, Malati Balawant Apts.576, Narayan</p>
                                        <p>Peth,Near Ramanbaug Chowk</p>
                                        <p>Pune- 30, Maharashtra, India</p>
                                    </div>
                                </div>
                                
                                
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </React.Fragment>
        );
    }
}

export default Sitemap;