import React from 'react';
import './Member.css';

class Member extends React.Component{
    render(){
        const     image =this.props.data.image,
				name = this.props.data.name,
                post = this.props.data.post,
                linkdinId = this.props.data.linkdinId;
        return(
            <React.Fragment>
                {console.log('image ',image)}
                <div className="card" style={{width: '18rem'}}>
                    <center><img className="card-img-top" src={image} alt="team member"/></center>
                    <div className="card-body">
                        <h5 className="card-title font-weight-bold">{name}</h5>
                        <h6 className="card-text">{post}</h6>
                        <span className='linkedinID'><a href={linkdinId}><i class="fab fa-linkedin-in"/></a></span>
                    </div>
                </div>
                {/* <figure className="snip1584 figureMember">
                    <img src={image}  alt='team member'/>
                    <figcaption className='captionMember'>
                        <h3>{name}</h3>
                        <h5>{post}</h5>
                        <span className='linkedinID'><i class="fab fa-linkedin-in fa-2x" /></span>
                    </figcaption>
                    
	    		</figure> */}
            </React.Fragment>
        )
    }
}
export default Member;