import React from 'react';
import './MachineLearningAndAI.css';
import { Helmet } from 'react-helmet';
class machineLearnAI extends React.Component {
  constructor(props) {
    super(props);
    this.initialState = {
      isShow: true,
    };
  }
  componentWillMount() {
    this.setState(this.initialState);
  }
  componentDidMount() {
    // window.addEventListener('scroll', (event) => {
    //     this.myFunction();
    // })
  }
  componentWillUnmount() {
    this.setState(this.initialState);
  }
  handleChange = event => {
    event.preventDefault();
    document.querySelector('.learnMoreText').classList.toggle('hide');
    this.setState({
      isShow: !this.state.isShow,
    });
  };
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Machine Learning and AI (Artificial Intelligence)</title>
        </Helmet>
        <div className="genericContainer genericContainerHeader">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-6 col-12">
              <div className="hero-software content hero-feature-content">
                <strong>
                  <span className="TeamPageTitle1">MACHINE LEARNING</span> & AI
                </strong>
                {/* <p className="alignCenter">Please read the document carefully. </p> */}
              </div>
            </div>
          </div>
        </div>
        {/* <header className="machineLearnAndAIHeader">
          <div className="machineLearnAndAIImage row">
            <div className="transparentLayer" />
            <img  src={machineLearnAndAI} alt="machineLearnAndAIImage" className='col-12'/>
          </div>
          <div className="machineLearnAndAITitle">
            <center>
              <strong>
                Machine Learning<span> & AI</span>
              </strong>
            </center>
          </div>
        </header> */}
        <div className="machineLearnAndAIContent container">
          <div className="machineLearnAndAISection1">
            <br />
            <center className="machineLearnAndAIContentTagLine">
              <i class="fa fa-quote-left" aria-hidden="true" />
              <h4>
                <strong className="machineLearnAndAIContentTagLineTitle">
                  The force behind your IT infrastructure
                </strong>
              </h4>
            </center>
            <p className="machineLearnAndAIIntro">
              We incorporate machine learning to your websites to automate your help and support
              workflow, to provide proper recommendations to customers which can increase your sales
              and provide analytics for business decisions.
              {/* <span onClick={this.handleChange} className='learnMoreText show'>[Learn More...]</span> */}
            </p>
            {this.state.isShow && (
              <div className="learnMore">
                <div className="machineLearnDesign">
                  <div className="machineLearnTitle" />
                  <div className="machineLearnContent">
                    <p>
                      Machine Learning can be employed in dynamic web pages as search engines,
                      recommendation systems, and to perform predictive analysis for web engines.
                      The ML engine can be chosen as per the data requirement such as Hadoop or
                      Spark ecosystems that are used for distributed applications. The mid-size
                      datasets use Java and C++ whereas the small datasets use R and MATLAB.
                    </p>
                    <p>
                      Based on the information gathered from these engines, recommendation systems
                      can be built that can be used to recommend products on your website to the
                      users based on their previous search history. It will help in increasing the
                      sales of your company.
                    </p>
                    <p>
                      Chatbots are analogous to robots that automate a repetitive task but this task
                      is only one task, i.e. chats. Chatbots react to spoken and written prompts to
                      deliver a service by using learning algorithms to pick up on conversational
                      cadences. This helps it imitate human conversation. These chatbots make good
                      communication platforms which can reduce the burden or frustration of users
                      and provide optimal services while maintaining good customer relationships.
                    </p>
                    <p>
                      We also back up the files which will ensure that you never lose your crucial
                      information and a backup will be performed regularly if you opt for our
                      services.
                    </p>
                    <p>
                      FSJARS is proud to have a team of experts that can help you develop chatbots
                      and implement machine learning to make your website more interactive. We
                      possess all the necessary resources to make your website akin to your company
                      and its needs.
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default machineLearnAI;
