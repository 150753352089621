import React, { Component } from 'react';
import './OurPartner.css';
import { Helmet } from 'react-helmet';
class OurPartner extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Our Partners</title>
        </Helmet>
        <div className="genericContainer">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-6 col-12">
              <div className="hero-software content hero-feature-content">
                <strong>
                  <span className="TeamPageTitle1">Our</span> Partners
                </strong>
                {/* <p className="alignCenter">Please read the document carefully. </p> */}
              </div>
            </div>
          </div>
        </div>
        {/* <header className="OurPartnerHeader">
          <div className="OurPartnerImage row">
            <div className="transparentLayer" />
            <img  src={OurPartner} alt="OurPartnerImage" className='col-12'/>
          </div>
        </header> */}

        <div className="OurPartnerContent container">
          <p>
            We at FSJARS pride ourselves for our impeccable customer relationships and the proof
            lies in the testimonials of our partners.
          </p>
        </div>
      </React.Fragment>
    );
  }
}

export default OurPartner;
