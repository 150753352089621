import React, { Component } from 'react';
import './TeamPage.css';
import teamMemberImage1 from '../../img/perso1.jpg';

import teamPortfolio from '../../img/teamPortFolio.jpeg';
import { Helmet } from 'react-helmet';
class TeamPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Meet FSJARS Team</title>
        </Helmet>
        {/* <header className="teamPageHeader">
          <div className="teamPageImage row">
            <img src={teamPortfolio} alt="teamPortfolio" className="col-12" />
          </div>
        </header> */}
        <div className="genericContainer">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-6 col-12">
              <div className="hero-software content hero-feature-content">
                <strong>
                  <span className="TeamPageTitle1">Meet the</span> Team
                </strong>
                {/* <p className="alignCenter">Please read the document carefully. </p> */}
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="teamPageContent container">
          <div className="teamPageTitle">
            {/* <h3>
              <strong>
                <span className="TeamPageTitle1">Meet the</span> Team
              </strong>
            </h3> */}
            <div className="teamPageSubContent">
              "Individuals can and do make a difference, but it makes the team to really mess things
              up"
            </div>
          </div>
        </div>
        <br />
        <div className="teamPageSubContent row">
          <div className="col-md-2">
            <center>
              <div className="teamMemberImage row col-md-12">
                <img src={teamMemberImage1} alt="" />
              </div>
              <div className=" row col-md-12">
                {/* <center> */}
                <div className="contactMember">
                  <i className="fa fa-mobile" />
                  <i className="fa fa-envelope-o" />
                  <i className="fa fa-twitter" />
                  <i className="fa fa-linkedin-in" />
                </div>
                {/* </center> */}
              </div>
            </center>
          </div>
          <div className="col-md-4">
            <hr />
            <div className="teamMemberName row">
              <strong>MATT MULLENWEG</strong>
            </div>
            <div className="teamMemberPosition row">
              <strong>FOUNDER</strong>
            </div>
            <br />
            <div className="teamMemberDetails row">
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maxime ab quis aperiam
              deleniti repellat in ad quibusdam nulla doloremque impedit, corrupti veniam obcaecati,
              suscipit distinctio nihil similique nemo id delectus?
            </div>
          </div>
          <div className="col-md-2">
            <center>
              <div className="teamMemberImage row col-md-12">
                <img src={teamMemberImage1} alt="" />
              </div>
              <div className="row col-md-12">
                <div className="contactMember">
                  <i className="fa fa-mobile" />
                  <i className="fa fa-envelope-o" />
                  <i className="fa fa-twitter" />
                  <i className="fa fa-linkedin-in" />
                </div>
              </div>
            </center>
          </div>
          <div className="col-md-4">
            <hr />
            <div className="teamMemberName row">
              <strong>MARK ZUCKERBERG</strong>
            </div>
            <div className="teamMemberPosition row">
              <strong>FOUNDER</strong>
            </div>
            <br />
            <div className="teamMemberDetails row">
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maxime ab quis aperiam
              deleniti repellat in ad quibusdam nulla doloremque impedit, corrupti veniam obcaecati,
              suscipit distinctio nihil similique nemo id delectus?
            </div>
          </div>
        </div>
        <div className="teamPageSubContent row">
          <div className="col-md-2">
            <center>
              <div className="teamMemberImage row col-md-12">
                <img src={teamMemberImage1} alt="" />
              </div>
              <div className="row col-md-12">
                <div className="contactMember">
                  <i className="fa fa-mobile" />
                  <i className="fa fa-envelope-o" />
                  <i className="fa fa-twitter" />
                  <i className="fa fa-linkedin-in" />
                </div>
              </div>
            </center>
          </div>
          <div className="col-md-4">
            <hr />
            <div className="teamMemberName row">
              <strong>MATT MULLENWEG</strong>
            </div>
            <div className="teamMemberPosition row">
              <strong>FOUNDER</strong>
            </div>
            <br />
            <div className="teamMemberDetails row">
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maxime ab quis aperiam
              deleniti repellat in ad quibusdam nulla doloremque impedit, corrupti veniam obcaecati,
              suscipit distinctio nihil similique nemo id delectus?
            </div>
          </div>
          <div className="col-md-2">
            <center>
              <div className="teamMemberImage row col-md-12">
                <img src={teamMemberImage1} alt="" />
              </div>
              <div className="row col-md-12">
                <div className="contactMember">
                  <i className="fa fa-mobile" />
                  <i className="fa fa-envelope-o" />
                  <i className="fa fa-twitter" />
                  <i className="fa fa-linkedin-in" />
                </div>
              </div>
            </center>
          </div>
          <div className="col-md-4">
            <hr />
            <div className="teamMemberName row">
              <strong>MARK ZUCKERBERG</strong>
            </div>
            <div className="teamMemberPosition row">
              <strong>FOUNDER</strong>
            </div>
            <br />
            <div className="teamMemberDetails row">
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maxime ab quis aperiam
              deleniti repellat in ad quibusdam nulla doloremque impedit, corrupti veniam obcaecati,
              suscipit distinctio nihil similique nemo id delectus?
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default TeamPage;
