import React, { Component } from 'react';
import mehetre from '../../img/Mehetre.png';
// import work2 from '../../img/work72.png';
// import work3 from '../../img/work32.png';
import coreidd from '../../img/CoreIdd.png';
import ims_portfolio from '../../img/ims_portfolio.png';
import tms_portfolio from '../../img/tms_portfolio.png';
// import work5 from '../../img/work52.png';
// import work6 from '../../img/work62.png';
import yantranant from '../../img/yantranant.png';
import elipi from "../../img/elipi.png";
import letswegrow from '../../img/LetsWeGrow.png';
import './Portfolio.css';
import { Helmet } from 'react-helmet';

class Portfolio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDesign: true,
      isWeb: true,
    };
  }
  handleChange = event => {
    console.log('id' + event.target.id);
    if (event.target.id == 'isAll') {
      this.setState({
        isDesign: true,
        isWeb: true,
      });
    } else if (event.target.id == 'isDesign') {
      this.setState({
        isWeb: false,
        isDesign: true,
      });
    } else if (event.target.id == 'isWeb') {
      this.setState({
        isWeb: true,
        isDesign: false,
      });
    }
  };
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Our Product and Project Portfolio</title>
        </Helmet>
        {/* <header className="PortfolioHeader">
          <div className="PortfolioImage row">
            <img  src={Portfolio} alt="PortfolioImage" className='col-12'/>
          </div>
          <div className="PortfolioTitle">
            <center>
              <strong>
                Port <span className="PortfolioTitle1">Folio</span>
              </strong>
            </center>
          </div>
        </header> */}
        <div className="genericContainer genericContainerHeader">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-6 col-12">
              <div className="hero-software content hero-feature-content">
                <strong>
                  <span className="TeamPageTitle1">PORT</span> FOLIO
                </strong>
                {/* <p className="alignCenter">Please read the document carefully. </p> */}
              </div>
            </div>
          </div>
        </div>
        <div className="PortfolioContent container">
          <div className="PortfolioSection1">
            <br />
            <center className="PortfolioContentTagLine">
              <h4 className="PortfolioContentTagLineTitle">
                {' '}
                OUR{' '}
                <span>
                  <strong>PORTFOLIO</strong>
                </span>
              </h4>
              <p className="PortfolioIntro">A solution for every problem</p>
              <ul className="portFolioList">
                <li
                  onClick={this.handleChange}
                  id="isAll"
                  className={this.state.isDesign && this.state.isWeb && 'activePortfolio'}
                >
                  ALL
                </li>
                <li
                  onClick={this.handleChange}
                  id="isDesign"
                  className={this.state.isDesign && !this.state.isWeb && 'activePortfolio'}
                >
                  OUR PRODUCTS
                </li>
                <li
                  onClick={this.handleChange}
                  id="isWeb"
                  className={!this.state.isDesign && this.state.isWeb && 'activePortfolio'}
                >
                  OUR CLIENTS
                </li>
              </ul>
            </center>
            {
              <div className="row-container">
                {this.state.isDesign && (
                  <React.Fragment>
                    <div
                      className={
                        this.state.isWeb ? 'column-item item1' : 'item1 column-item-isDesign'
                      }
                    >
                      <a href="http://institutejars.com/" target="_blank">
                        <div>
                          <img src={ims_portfolio} height="200" alt="work2" />
                          <div className="imgButton">
                            <i className="fa fa-link" />
                          </div>
                        </div>
                      </a>
                    </div>
                    <div
                      className={
                        this.state.isWeb ? 'column-item item4' : 'item4 column-item-isDesign'
                      }
                    >
                      <a href="https://tms.fsjars.com/" target="_blank">
                        <div>
                          <img src={tms_portfolio} height="200" alt="work8" />
                          <div className="imgButton">
                            <i className="fa fa-link" />
                          </div>
                        </div>
                      </a>
                    </div>
                    
                  </React.Fragment>
                )}
                {this.state.isWeb && (
                  <React.Fragment>
                    <div className="column-item item4">
                      <a href="http://coreidd.com/" target="_blank">
                        <div>
                          <img src={coreidd} width="800" height="200" alt="work4" />
                          <div className="imgButton">
                            <i className="fa fa-link" />
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="column-item item1">
                      <a href="http://mehetre.com" target="_blank">
                        <div>
                          <img src={mehetre} height="200" alt="work1" />
                          <div className="imgButton">
                            <i className="fa fa-link" />
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="column-item item4">
                      <a href="http://www.letswegrow.com/" target="_blank">
                        <div>
                          <img src={letswegrow} width="800" height="200" alt="work7" />
                          <div className="imgButton">
                            <i className="fa fa-link" />
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="column-item item4">
                      <a href="https://www.elipi.in/" target="_blank">
                        <div>
                          <img src={elipi} width="800" height="200" alt="work4" />
                          <div className="imgButton">
                            <i className="fa fa-link" />
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="column-item item4">
                      <a href="https://yantranant.com/" target="_blank">
                        <div>
                          <img src={yantranant} width="800" height="200" alt="work4" />
                          <div className="imgButton">
                            <i className="fa fa-link" />
                          </div>
                        </div>
                      </a>
                    </div>
                  </React.Fragment>
                )}
              </div>
            }
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Portfolio;
