import React, { Component, Fragment } from 'react';
import './ComingSoon.css';
import { Helmet } from 'react-helmet';
// import cloud from '../../img/cloud.png';
class ComingSoon extends Component {
  render() {
    return (
      <Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Something great will be coming here!!!</title>
        </Helmet>
        <div className="genericContainer">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-6 col-12">
              <div className="hero-software content hero-feature-content">
                <div className="comingSoonTitle">
                  <center>
                    <strong>COMING SOON</strong>
                  </center>
                </div>
                {/* <p className="alignCenter">Please read the document carefully. </p> */}
              </div>
            </div>
          </div>
        </div>
        <div className='comingSoonContent'>
          <center>
            <h1>
              <strong className="comingSoonText">Something great will be coming here!!!</strong>
            </h1>
          </center>
        </div>
      </Fragment>
    );
  }
}
export default ComingSoon;
