import React from 'react';
import './aboutUsPage.css';
import aboutUs from '../../img/about2.jpg';
import { Helmet } from 'react-helmet';

class AboutUs extends React.Component {
  constructor(props) {
    super(props);
    this.initialState = {
      isShow: true,
    };
  }

  componentWillMount() {
    this.setState(this.initialState);
  }
  componentDidMount() {
    // window.addEventListener('scroll', (event) => {
    //     this.myFunction();
    // })
  }
  componentWillUnmount() {
    this.setState(this.initialState);
  }
  handleChange = event => {
    event.preventDefault();
    document.querySelector('.learnMoreText').classList.toggle('hide');
    this.setState({
      isShow: !this.state.isShow,
    });
  };

  // myFunction=()=>{
  //     console.log('hey');
  //     var header = document.querySelector(".aboutUsTitle");
  //     var sticky = header.offsetTop;
  // if (window.pageYOffset > 350) {
  //     header.classList.add("sticky");
  // } else {
  //     header.classList.remove("sticky");
  // }
  // }
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>About Us</title>
        </Helmet>
        <div className="genericContainer">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-6 col-12">
              <div className="hero-software content hero-feature-content">
              <strong><span className='aboutUsTitle1'>ABOUT</span> US</strong>
                {/* <p className="alignCenter">Please read the document carefully. </p> */}
              </div>
            </div>
          </div>
        </div>
        <div className="aboutUsContent container">
          <div className="aboutUsSection1">
            <center className="aboutUsContentTagLine">
              <i class="fa fa-quote-left" aria-hidden="true" />
              <h4>
                <strong>The architects that shape your digital future.</strong>
              </h4>
            </center>
            <p className="aboutUsIntro">
              We are a company with a large team of experts who are experienced in the design and
              development of websites, e-commerce solutions, custom web applications, and mobile
              applications. We are a strong advocate of customer satisfaction. After development, we
              remain constantly in touch with you while offering the highest amount of support. We
              also provide an excellent quality SEO service.
              {/* <span onClick={this.handleChange} className='learnMoreText show'>[Learn More...]</span> */}
            </p>
            {this.state.isShow && (
              <div className="learnMore">
                <div className="aboutUsSection2">
                  <strong>
                    <span className="aboutUsSection2Title1 aboutUsSectionTitle1">T</span>
                    <span className="aboutUsSection2Title2 aboutUsSectionTitle2">
                      HE INSPIRATION
                    </span>
                  </strong>
                  <div className="aboutUsSection2Intro">
                    <p>
                      <strong>FSJARS</strong> is a revered name when it comes to digital services.
                      Our company, that was started in 2015 and is based in the heart of Pune has
                      developed a name for itself through its top-notch web and business services.
                      Our company was founded by IITians and tier 1 colleges alumni and it was
                      inspired by the need to provide digital solutions to keep small and large
                      businesses ahead of their competitors. We specialize in product development
                      and web services. We have been working on modern,robust, and multifunctional
                      technologies and platforms such as MERN stack and MEAN stack which contains
                      MongoDB/MySQL Express React/Angular and NodeJS and is instrumental in solving
                      all your business problems. We keep abreast with new technologies which are
                      why we provide optimum e-commerce solutions, custom web and mobile application
                      services, and SEO services.
                    </p>
                  </div>
                </div>
                <div className="aboutUsSection3">
                  <strong>
                    <span className="aboutUsSection3Title1 aboutUsSectionTitle1">M</span>
                    <span className="aboutUsSection3Title2 aboutUsSectionTitle2">ISSION</span>
                  </strong>
                  <div className="aboutUsSection3Intro">
                    <div className="aboutUsSubHeading">
                      <center>
                        <h4>
                          <strong>We are committed to ensuring your success.</strong>
                        </h4>
                      </center>
                    </div>
                    <p>
                      We believe in honest hard work that automatically takes us to the top. Our
                      determination helps us get the best IT solutions for our clients. We are a
                      team of experts that are passionate about their work.
                    </p>
                  </div>
                </div>
                <div className="aboutUsSection4">
                  <strong>
                    <span className="aboutUsSection4Title1 aboutUsSectionTitle1">V</span>
                    <span className="aboutUsSection4Title2 aboutUsSectionTitle2">ISION</span>
                  </strong>
                  <div className="aboutUsSection4Intro">
                    <div className="aboutUsSubHeading">
                      <center>
                        <h4>
                          <strong>Quality is remembered long after the price is forgotten.</strong>
                        </h4>
                      </center>
                    </div>
                    <p>
                      Customer relationship and customer management is our topmost priority and we
                      provide several services that will ensure that your business idea flourishes
                      online. We focus on the quality of work and we stay with you even after the
                      website is built or the task is completed so that you have someone to fall
                      back on in the face of any new problem.
                    </p>
                  </div>
                </div>
                <center className="sloganFooter">
                  <h3>
                    <strong>You can trust us.</strong>
                  </h3>
                </center>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default AboutUs;
