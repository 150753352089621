import React, { Component } from 'react';
import "./HaveABusiness.css";
class HaveABusiness extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            isShow:true
         };
    }
    render() {
        return (
            <React.Fragment>
                <header className='HaveABusinessHeader'>
                    <div className='HaveABusinessImage row'>
                    <div className="transparentLayer">
                            
                        </div>
                        {/* <img  src={HaveABusiness} alt="HaveABusinessImage" className='col-12'/> */}
                    </div>
                    <div className="HaveABusinessTitle">
                        <strong><span className='HaveABusinessTitle1'>HAVE AN</span> BUSINESS IDEA</strong>
                    </div>
                </header>
                <div className="HaveABusinessContent container">
                    <div className="HaveABusinessSection1">
                        <center className='HaveABusinessContentTagLine'><i class="fa fa-quote-left" aria-hidden="true"></i><h4><strong>Every business is an IT business now!</strong></h4></center>
                        <p className='HaveABusinessIntro'>
                        <strong>Ideas make organization – it's the seed in product development cycle that separates brilliance from mediocrity! If you have the concept that can shine, we want to talk to you. With absolutely no investment from your side, we help you productize your idea..</strong>
                            {/* <span onClick={this.handleChange} className='learnMoreText show'>[Learn More...]</span> */}
                        </p>
                        { this.state.isShow &&
                            <div className="learnMore">
                                <div className='HaveABusinessSection2'>
                                    <div className="HaveABusinessSection2Intro">
                                        <p> 
                                            Your business idea is important to us which is why we are ready to support any business idea with great potential. We believe that you have the qualities to come forward and create magic in the business world. We have all the resources to help you as a stepping stone towards your success. Our team of brilliant minds will give you the best digital solutions that you need to make your business flourish. 
                                        </p>
                                        <p>
                                            As an aspiring entrepreneur, you must be well-versed and experienced in sales, operations, and marketing with your MBA degree. However, if you don’t have an expertise in the IT field and don’t wish to spend a hefty amount in hiring an IT company, you can come to us. We see the potential in you and we will provide our website development for free.
                                        </p> 
                                        <p>
                                            We can help you build your business from scratch with our wide range of website design and development services, e-commerce solutions, custom web applications, mobile applications, and SEO services. Our experts will consult and ask you about your concerns and incorporate it with our advanced tech platforms to make your dreams come true.
                                        </p>
                                        
                                    </div>
                                </div>
                                
                                <center className='sloganFooter'><h3><strong >You can trust us.</strong></h3></center>
                            </div>  
                        }
                    </div>

                </div>
            </React.Fragment>
        );
    }
}

export default HaveABusiness;