import React, { Component, Fragment } from 'react';
import './GenericPage.css';
import { Helmet } from 'react-helmet';
class GenericPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Fsjars Generic Page</title>
        </Helmet>
        <div className="genericPage ">
          <div className="genericContainer">
            <div className="row align-items-center text-center">
              <div className="col-xl-12 col-lg-12 col-md-6 col-12">
                <div className="hero-software content hero-feature-content">
                  <span style={{ color: '#fff' }}>Privacy Policy</span>
                  <p className="alignCenter">Please read the document carefully. </p>
                </div>
              </div>
            </div>
          </div>
          <div className="custom-buy-content">
            <div className="container">
              <div className="buy-content-upper">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="custom-privacy-content">
                      {/* <h5>What information do we collect?</h5> */}

                      <p>
                        This document is an electronic record in terms of Information Technology
                        Act, 2000 and rules thereunder as applicable and the amended provisions
                        pertaining to electronic records in various statutes as amended by the
                        Information Technology Act, 2000. This electronic record is generated by a
                        computer system and does not require any physical or digital signatures.
                      </p>

                      <p>
                        This document is published in accordance with the provisions of Rule 3 (1)
                        of the Information Technology (Intermediaries guidelines) Rules, 2011 that
                        require publishing the rules and regulations, privacy policy and Terms of
                        Use for access or usage of this mobile application ('App').
                      </p>
                      <p>
                        Customer confidentiality and privacy are of utmost concern to us. Our
                        employees treat the information we have concerning your student & admin
                        accounts in the same responsible and confidential way that we want our own
                        educational institute treated. The information shared with the InstituteJARS
                        will be treated as private.
                      </p>
                    </div>
                    <div className="cookie-para">
                      <ul>
                        <li>
                          {' '}
                          <strong>Recognition of your expectation of privacy</strong>
                        </li>
                        <p>
                          We recognize that our customers expect privacy and security for their
                          educational academic information. We understand that, by selecting us for
                          your education needs, you have entrusted us to safeguard your educational
                          academic information. We want you to be informed of our commitment to
                          protect the privacy of your educational academic information with the
                          following privacy principles and practices.
                        </p>
                        <li>
                          {' '}
                          <strong>
                            What personally identifiable information is collected from you?
                          </strong>
                        </li>
                        <p>
                          We collect information from you regarding name, address, email address,
                          etc. Your personal information and contact details shall remain
                          confidential and shall not be used for any purpose other than our
                          communication with you. The information shall not be provided to third
                          parties in any manner whatsoever without your consent.
                        </p>
                        <li>
                          <strong>Cookies</strong>
                        </li>
                        <p>
                          A cookie is a data file that certain Web sites write to your computer's
                          hard drive when you visit such sites. A cookie file can contain
                          information such as a user identification code that the site uses to track
                          the pages you have visited and use the information commercially. We do not
                          use cookies on our web site.
                        </p>
                        <li>
                          <strong>How we use, collect, and retain customer information</strong>
                        </li>
                        <p>
                          On our site, we collect, retain, and use information about you only when
                          we reasonably believe that it will help administer our business or provide
                          products, services, and other opportunities to you. We collect and retain
                          information about you only for specific business purposes.
                        </p>
                        <li>
                          <strong>Returns Policy</strong>
                        </li>
                        <p>
                          {' '}
                          Return is a scheme provided by InstituteJARS directly under this policy.
                          All products & services under a particular category may not have the same
                          return/replacement period. Kindly check the respective item's applicable
                          return/replacement policy on the product & services.
                        </p>
                        <li>
                          <strong>Use of the App</strong>
                        </li>
                        <p>
                          You agree, undertake and confirm that your use of App shall be strictly
                          governed by the following binding principles: You shall not host, display,
                          upload, modify, publish, transmit, update or share any information which
                          Belongs to another person and to which you do not have any right to.
                        </p>
                        <li>
                          <strong>Charges</strong>
                        </li>
                        <p>
                          Use of the App is free for student. InstituteJARS does not charge any fee
                          for using the App and making purchases using the App. InstituteJARS
                          reserves the right to change its Fee Policy from time to time. In
                          particular, InstituteJARS may at its sole discretion introduce new
                          services and modify some or all of the existing services offered through
                          the App. In such an event, InstituteJARS reserves the right to introduce
                          fees for the new services offered or amend/introduce fees for existing
                          services, as the case may be. Changes to the Fee Policy shall be intimated
                          to users of the App and such changes shall automatically become effective
                          immediately thereafter. Unless otherwise stated, all fees shall be quoted
                          in Indian Rupees. You shall be solely responsible for compliance of all
                          applicable laws including those in India for making payments to
                          InstituteJARS.
                        </p>
                        <li>
                          <strong>We use the information to</strong>
                          <ul>
                            <li>Open and administer your accounts and to protect your records.</li>
                            <li>Comply with all applicable laws and regulations.</li>
                            <li>
                              Help us design or improve our products and services for your benefit.
                            </li>
                            <li>
                              Understand your educational needs so that we can provide you with
                              quality products and superior services.
                            </li>
                            <li>
                              To comply with laws, guidelines, and regulations that govern the
                              educational services in the country.
                            </li>
                          </ul>
                        </li>
                        <li>
                          <strong>Third Party Sites and Services</strong>
                        </li>
                        <p>
                          Related offerings with links from ACESYS’s websites have their own privacy
                          policies that can be viewed by clicking on the corresponding links within
                          each respective website. All ACESYS partners are encouraged to participate
                          in industry privacy initiatives and to take a responsible attitude towards
                          consumer privacy.
                        </p>
                        <p>
                          However, since we do not have direct control over the policies or
                          practices of participating third parties, we are not responsible for the
                          privacy practices or content of those sites. We recommend and encourage
                          that you always review the privacy policies of those third parties before
                          you provide any personal information or complete any transaction with such
                          parties.
                        </p>
                        <p>
                          Our web site also includes social media features, such as the Facebook
                          “Like” button and widgets, such as the “Share this” button or interactive
                          mini-programs that run on our site. These features may collect your IP
                          address, which page you are visiting on our site, and may set a cookie to
                          enable the feature to function properly. Social media features and widgets
                          are either hosted by a third party or hosted directly on our web site,
                          therefore your interactions with these features are governed by the
                          privacy statement of those third parties.
                        </p>
                        <li>
                          <strong>Collection and Use of Children’s Personal Information</strong>
                        </li>
                        <p>
                          ACESYS does not knowingly collect or use personal information from
                          children under the age of 13, or equivalent minimum age in the respective
                          jurisdiction. If ACESYS learns that we have personal information on a
                          child under the age of 13, or equivalent minimum age depending on
                          jurisdiction, we will take steps to delete that information from our
                          systems.
                        </p>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default GenericPage;
