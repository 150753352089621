import React from 'react';
import Member from './Member';
import Slider from 'react-slick';
import './Team.css';
import { Link } from 'react-router-dom';

class Team extends React.Component {
  render() {
    const imageGirl = require('../../img/Team/girl.png');
    const imageBoy = require('../../img/Team/boy.png');
    // const imageYogeshKhandelwal = require('../../img/Team/YogeshKhandelwal.jpeg');
    const imageSwapnilSharma = require('../../img/Team/SwapnilSharma.jpeg');
    const imageVidyadharYadav = require('../../img/Team/VidyadharYadav.jpg');
    const imageSachinKolhe = require('../../img/Team/SachinKolhe.jpeg');
    const imageShaktiSingh = require('../../img/Team/ShaktiSingh.jpg');
    const imageSivaKusi = require('../../img/Team/SivaKusi.jpg');
    const imageZainabKhokawala = require('../../img/Team/ZainabKhokawala.jpeg');
    const imageAakashPaliwal = require('../../img/Team/AakashPaliwal.jpeg');
    const imageShubhamRajoriya = require('../../img/Team/ShubhamRajoriya.png');
    const imageVipulModi = require('../../img/Team/VipulModi.png');
    const imageMuskanKhatri = require('../../img/Team/MuskanKhatri.jpg');
    const imageMansiJain = require('../../img/Team/MansiJain.jpg');
    // const shakti=require('');
    var settings = {
      dots: true,
      infinite: true,
      slidesToShow: 5,
      slidesToScroll: 1,
      autoplay: true,
      responsive: [
        {
          breakpoint: 650,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
            infinite: true,
          },
        },
        {
          breakpoint: 780,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            dots: false,
            infinite: true,
          },
        },
        {
          breakpoint: 1050,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            dots: false,
            infinite: true,
          },
        },
        {
          breakpoint: 1250,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            dots: true,
            infinite: true,
          },
        },
      ],
    };
    return (
      <div id="team">
        <div className="p-5 team-members ">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
              <h2 className="text-center-contact teamSectionHeader">
                MEET OUR <span className="custom-h2">TEAM</span>
              </h2>
              <hr className="below-header-hr-team" />
              <div style={{ textAlign: 'end', marginBottom: '0.5%' }}>
                <Link to="/teamPortFolio" className="btn aboutUsLearnMore">
                  <strong>
                    Learn More <i className="fa fa-arrow-right rightArrowLearnMore" />
                  </strong>
                </Link>
              </div>
            </div>
          </div>
          <div>
            <center>
              <Slider {...settings}>
                {/* <div>
                  <Member
                    data={{
                      image: imageYogeshKhandelwal,
                      name: 'Yogesh Khandelwal',
                      post: 'Executive Chairman',
                      linkdinId: 'xdc',
                    }}
                  />
                </div> */}

                <div>
                  <Member
                    data={{
                      image: imageGirl,
                      name: 'Sonali Mehetre',
                      post: 'Executive Chairman',
                      linkdinId: 'xdc',
                    }}
                  />
                </div>
                <div>
                  <Member
                    data={{
                      image: imageSwapnilSharma,
                      name: 'Swapnil Sharma',
                      post: 'CTO',
                      linkdinId: 'https://www.linkedin.com/in/swapnil-sharma-b829716a/',
                    }}
                  />
                </div>
                <div>
                  <Member
                    data={{
                      image: imageZainabKhokawala,
                      name: 'Zainab Khokawala',
                      post: 'UX/UI designer',
                      linkdinId: 'https://www.linkedin.com/in/zainab-khokawala-476817117/',
                    }}
                  />
                </div>
                <div>
                  <Member
                    data={{
                      image: imageShaktiSingh,
                      name: 'Shakti Singh',
                      post: 'Full Stack Developer',
                      linkdinId: 'https://www.linkedin.com/in/shaktisingh2397/',
                    }}
                  />
                </div>
                <div>
                  <Member
                    data={{
                      image: imageSivaKusi,
                      name: 'Siva Kusi',
                      post: 'Full Stack Developer',
                      linkdinId: 'https://www.linkedin.com/in/siva-kusi-9b210416a/',
                    }}
                  />
                </div>
                <div>
                  <Member
                    data={{
                      image: imageBoy,
                      name: 'Nikhil Ghatul',
                      post: 'Full Stack Developer',
                      linkdinId: 'asca',
                    }}
                  />
                </div>
                <div>
                  <Member
                    data={{
                      image: imageAakashPaliwal,
                      name: 'Aakash Paliwal',
                      post: 'Full Stack Developer',
                      linkdinId: 'https://www.linkedin.com/in/aakash-paliwal-205663a0',
                    }}
                  />
                </div>
                <div>
                  <Member
                    data={{
                      image: imageGirl,
                      name: 'Shiva Varshney',
                      post: 'Full Stack Developer',
                      linkdinId: 'asca',
                    }}
                  />
                </div>
                <div>
                  <Member
                    data={{
                      image: imageVidyadharYadav,
                      name: 'Vidyadhar Yadav',
                      post: 'Full Stack Developer',
                      linkdinId: 'asca',
                    }}
                  />
                </div>
                <div>
                  <Member
                    data={{
                      image: imageSachinKolhe,
                      name: 'Sachin Kolhe',
                      post: 'Sales and Marketing',
                      linkdinId: 'asca',
                    }}
                  />
                </div>
                <div>
                  <Member
                    data={{
                      image: imageShubhamRajoriya,
                      name: 'Shubham Rajoriya',
                      post: 'Marketing',
                      linkdinId: 'https://www.linkedin.com/in/shubham-rajoriya-aa863716b/',
                    }}
                  />
                </div>
                <div>
                  <Member
                    data={{
                      image: imageVipulModi,
                      name: 'Vipul Modi',
                      post: 'Marketing Partner',
                      linkdinId: 'https://www.linkedin.com/company/vrm-softcom/?viewAsMember=true',
                    }}
                  />
                </div>
                <div>
                  <Member
                    data={{
                      image: imageMansiJain,
                      name: 'Mansi Jain',
                      post: 'HR Head (VRM Consultancies)',
                      linkdinId: 'https://www.linkedin.com/in/mansi-jain-20a9a7196',
                    }}
                  />
                </div>
                <div>
                  <Member
                    data={{
                      image: imageMuskanKhatri,
                      name: 'Muskan Khatri',
                      post: 'Corporate Representative (VRM Consultancies)',
                      linkdinId: 'https://www.linkedin.com/in/mansi-jain-20a9a7196',
                    }}
                  />
                </div>
              </Slider>
            </center>
          </div>
          {/* <div className="row cardCarousel autoplay">
                <div className="card1 col-md-3">
                    <div className="card">
                        <img
                            className="card-img-top"
                            src={require('../images/tm3.jpg')}
                            className="img-fluid image"
                            lt="Card image cap"
                        />
                        <div className="card-body">
                            <h5 className="card-title text-center">Sonali Mehetre</h5>
                            <p className="card-text text-center">CEO</p>
                            <div className="linkedin-logo text-center">
                                <a className="text-center">
                                <i className="fab fa-linkedin-in fa-2x" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card2 col-md-3">
                    <img
                        className="card-img-top"
                        src={require('../images/tm4.jpg')}
                        className="img-fluid image"
                        lt="Card image cap"
                    />
                    <div class="card-body">
                        <h5 class="card-title text-center">Swapnil Sharma</h5>
                        <p class="card-text text-center">Head of Development</p>
                        <div className="linkedin-logo text-center">
                            <a className="text-center">
                            <i class="fab fa-linkedin-in fa-2x" />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="card3 col-md-3">
                    <img
                        className="card-img-top"
                        src={require('../images/tm4.jpg')}
                        className="img-fluid image"
                        lt="Card image cap"
                    />
                    <div class="card-body">
                        <h5 class="card-title text-center">Siva Kusi</h5>
                        <p class="card-text text-center">Full Stack Developer</p>
                        <div className="linkedin-logo text-center">
                            <a className="text-center">
                            <i class="fab fa-linkedin-in fa-2x" />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="card4 col-md-3">
                    <img
                        className="card-img-top"
                        src={require('../images/tm1.jpeg')}
                        className="img-fluid image"
                        lt="Card image cap"
                    />
                    <div class="card-body">
                        <h5 class="card-title text-center">M Shashwat Nigam</h5>
                        <p class="card-text text-center">Full stack Developer</p>
                        <div className="linkedin-logo text-center">
                            <a className="text-center">
                            <i class="fab fa-linkedin-in fa-2x" />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="card5 col-md-3">
                    <img
                        className="card-img-top"
                        src={require('../images/tm1.jpeg')}
                        className="img-fluid image"
                        lt="Card image cap"
                    />
                    <div class="card-body">
                        <h5 class="card-title text-center">M Shashwat Nigam</h5>
                        <p class="card-text text-center">Full stack Developer</p>
                        <div className="linkedin-logo text-center">
                            <a className="text-center">
                            <i class="fab fa-linkedin-in fa-2x" />
                            </a>
                        </div>
                    </div>
                </div>
              </div> */}
          {/* <div id="myCarousel" className="carousel slide" data-ride="carousel">
                <div className="carousel-inner row w-1000 mx-auto">
                <div className="carousel-item col-md-3 active">
                    <div className="card">
                    <img className="card-img-top img-fluid" src="http://placehold.it/800x600/f44242/fff" alt="Card image cap"/>
                    <div className="card-body">
                        <h4 className="card-title">Card 1</h4>
                        <p className="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                        <p className="card-text"><small className="text-muted">Last updated 3 mins ago</small></p>
                    </div>
                    </div>
                </div>
                <div className="carousel-item col-md-3">
                    <div className="card">
                    <img className="card-img-top img-fluid" src="http://placehold.it/800x600/418cf4/fff" alt="Card image cap"/>
                    <div className="card-body">
                        <h4 className="card-title">Card 2</h4>
                        <p className="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                        <p className="card-text"><small className="text-muted">Last updated 3 mins ago</small></p>
                    </div>
                    </div>
                </div>
                <div className="carousel-item col-md-3">
                    <div className="card">
                    <img className="card-img-top img-fluid" src="http://placehold.it/800x600/3ed846/fff" alt="Card image cap"/>
                    <div className="card-body">
                        <h4 className="card-title">Card 3</h4>
                        <p className="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                        <p className="card-text"><small className="text-muted">Last updated 3 mins ago</small></p>
                    </div>
                    </div>
                </div>
                <div className="carousel-item col-md-3">
                    <div className="card">
                    <img className="card-img-top img-fluid" src="http://placehold.it/800x600/42ebf4/fff" alt="Card image cap"/>
                    <div className="card-body">
                        <h4 className="card-title">Card 4</h4>
                        <p className="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                        <p className="card-text"><small className="text-muted">Last updated 3 mins ago</small></p>
                    </div>
                    </div>
                </div>
                <div className="carousel-item col-md-3">
                    <div className="card">
                    <img className="card-img-top img-fluid" src="http://placehold.it/800x600/f49b41/fff" alt="Card image cap"/>
                    <div className="card-body">
                        <h4 className="card-title">Card 5</h4>
                        <p className="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                        <p className="card-text"><small className="text-muted">Last updated 3 mins ago</small></p>
                    </div>
                    </div>
                </div>
                <div className="carousel-item col-md-3">
                    <div className="card">
                    <img className="card-img-top img-fluid" src="http://placehold.it/800x600/f4f141/fff" alt="Card image cap"/>
                    <div className="card-body">
                        <h4 className="card-title">Card 6</h4>
                        <p className="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                        <p className="card-text"><small className="text-muted">Last updated 3 mins ago</small></p>
                    </div>
                    </div>
                </div>
                <div className="carousel-item col-md-">
                    <div className="card">
                    <img className="card-img-top img-fluid" src="http://placehold.it/800x600/8e41f4/fff" alt="Card image cap"/>
                    <div className="card-body">
                        <h4 className="card-title">Card 7</h4>
                        <p className="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                        <p className="card-text"><small className="text-muted">Last updated 3 mins ago</small></p>
                    </div>
                    </div>
                </div>
                </div>
                <a className="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
                </a>
                <a className="carousel-control-next" href="#myCarousel" role="button" data-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
                </a>
            </div> */}
          {/* <div id="carousel-example-multi" className="carousel slide carousel-multi-item v-2" data-ride="carousel">
              <ol className="carousel-indicators">
                <li data-target="#carousel-example-multi" data-slide-to="0" class="active"></li>
                <li data-target="#carousel-example-multi" data-slide-to="1"></li>
                <li data-target="#carousel-example-multi" data-slide-to="2"></li>
                <li data-target="#carousel-example-multi" data-slide-to="3"></li>
                <li data-target="#carousel-example-multi" data-slide-to="4"></li>
                <li data-target="#carousel-example-multi" data-slide-to="5"></li>
                <li data-target="#carousel-example-multi" data-slide-to="6"></li>
            </ol>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div className="col-md-3">
                    <div className="card mb-2">
                        <img
                        className="card-img-top"
                        src={require('../images/tm3.jpg')}
                        className="img-fluid image"
                        lt="Card image cap"
                        />
                        <div className="card-body">
                            <h5 className="card-title text-center">Sonali Mehetre</h5>
                            <p className="card-text text-center">CEO</p>
                            <div className="linkedin-logo text-center">
                                <a className="text-center">
                                <i className="fab fa-linkedin-in fa-2x" />
                                </a>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="col-md-3 mb-2">
                    <div class="card">
                        <img
                        className="card-img-top"
                        src={require('../images/tm3.jpg')}
                        className="img-fluid image"
                        lt="Card image cap"
                        />
                        <div class="card-body">
                            <h5 class="card-title text-center">Sonali Mehetre</h5>
                            <p class="card-text text-center">CEO</p>
                            <div className="linkedin-logo text-center">
                                <a className="text-center">
                                <i class="fab fa-linkedin-in fa-2x" />
                                </a>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="col-md-3">
                    <div class="card mb-2">
                        <img
                        className="card-img-top"
                        src={require('../images/tm3.jpg')}
                        className="img-fluid image"
                        lt="Card image cap"
                        />
                        <div class="card-body">
                            <h5 class="card-title text-center">Sonali Mehetre</h5>
                            <p class="card-text text-center">CEO</p>
                            <div className="linkedin-logo text-center">
                                <a className="text-center">
                                <i class="fab fa-linkedin-in fa-2x" />
                                </a>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="col-md-3 mb-2">
                    <div class="card">
                        <img
                        className="card-img-top"
                        src={require('../images/tm3.jpg')}
                        className="img-fluid image"
                        lt="Card image cap"
                        />
                        <div class="card-body">
                            <h5 class="card-title text-center">Sonali Mehetre</h5>
                            <p class="card-text text-center">CEO</p>
                            <div className="linkedin-logo text-center">
                                <a className="text-center">
                                <i class="fab fa-linkedin-in fa-2x" />
                                </a>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="col-md-3 mb-2">
                    <div class="card">
                        <img
                        className="card-img-top"
                        src={require('../images/tm3.jpg')}
                        className="img-fluid image"
                        lt="Card image cap"
                        />
                        <div class="card-body">
                            <h5 class="card-title text-center">Sonali Mehetre</h5>
                            <p class="card-text text-center">CEO</p>
                            <div className="linkedin-logo text-center">
                                <a className="text-center">
                                <i class="fab fa-linkedin-in fa-2x" />
                                </a>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="col-md-3 mb-2">
                    <div class="card">
                        <img
                        className="card-img-top"
                        src={require('../images/tm3.jpg')}
                        className="img-fluid image"
                        lt="Card image cap"
                        />
                        <div class="card-body">
                            <h5 class="card-title text-center">Sonali Mehetre</h5>
                            <p class="card-text text-center">CEO</p>
                            <div className="linkedin-logo text-center">
                                <a className="text-center">
                                <i class="fab fa-linkedin-in fa-2x" />
                                </a>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="col-md-3 mb-2">
                    <div class="card">
                        <img
                        className="card-img-top"
                        src={require('../images/tm3.jpg')}
                        className="img-fluid image"
                        lt="Card image cap"
                        />
                        <div class="card-body">
                            <h5 class="card-title text-center">Sonali Mehetre</h5>
                            <p class="card-text text-center">CEO</p>
                            <div className="linkedin-logo text-center">
                                <a className="text-center">
                                <i class="fab fa-linkedin-in fa-2x" />
                                </a>
                            </div>
                        </div>
                    </div>
                  </div>
                </div> */}

          {/* 
                <div className="col-lg-2 col-md-4 col-sm-4 col-xs-6 col-6">
                  <div class="card">
                    <img
                      className="card-img-top"
                      src={require('../images/tm4.jpg')}
                      className="img-fluid image"
                      lt="Card image cap"
                    />
                    <div class="card-body">
                      <h5 class="card-title text-center">Swapnil Sharma</h5>
                      <p class="card-text text-center">Head of Development</p>
                      <div className="linkedin-logo text-center">
                        <a className="text-center">
                          <i class="fab fa-linkedin-in fa-2x" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 col-md-4 col-sm-4 col-xs-6 col-6">
                  <div class="card">
                    <img
                      className="card-img-top"
                      src={require('../images/tm4.jpg')}
                      className="img-fluid image"
                      lt="Card image cap"
                    />
                    <div class="card-body">
                      <h5 class="card-title text-center">Siva Kusi</h5>
                      <p class="card-text text-center">Full Stack Developer</p>
                      <div className="linkedin-logo text-center">
                        <a className="text-center">
                          <i class="fab fa-linkedin-in fa-2x" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-4 col-xs-6 col-6">
                  <div class="card">
                    <img
                      className="card-img-top"
                      src={require('../images/tm1.jpeg')}
                      className="img-fluid image"
                      lt="Card image cap"
                    />
                    <div class="card-body">
                      <h5 class="card-title text-center">M Shashwat Nigam</h5>
                      <p class="card-text text-center">Full stack Developer</p>
                      <div className="linkedin-logo text-center">
                        <a className="text-center">
                          <i class="fab fa-linkedin-in fa-2x" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-4 col-xs-6 col-6">
                  <div class="card">
                    <img
                      className="card-img-top"
                      src={require('../images/tm2.jpg')}
                      className="img-fluid image"
                      lt="Card image cap"
                    />
                    <div class="card-body">
                      <h5 class="card-title text-center">Shakti Singh</h5>
                      <p class="card-text text-center">Full Stack Developer</p>
                      <div className="linkedin-logo text-center">
                        <a className="text-center">
                          <i class="fab fa-linkedin-in fa-2x" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 col-md-4 col-sm-4 col-xs-6 col-6">
                  <div class="card">
                    <img
                      className="card-img-top"
                      src={require('../images/tm3.jpg')}
                      className="img-fluid image"
                      lt="Card image cap"
                    />
                    <div class="card-body">
                      <h5 class="card-title text-center">Zainab Khokawala</h5>
                      <p class="card-text text-center">HR</p>
                      <div className="linkedin-logo text-center">
                        <a className="text-center">
                          <i class="fab fa-linkedin-in fa-2x" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 col-md-4 col-sm-4 col-xs-6 col-6">
                  <div class="card">
                    <img
                      className="card-img-top"
                      src={require('../images/tm3.jpg')}
                      className="img-fluid image"
                      lt="Card image cap"
                    />
                    <div class="card-body">
                      <h5 class="card-title text-center">Nikhil Ghatul</h5>
                      <p class="card-text text-center">Full Stack Developer</p>
                      <div className="linkedin-logo text-center">
                        <a className="text-center">
                          <i class="fab fa-linkedin-in fa-2x" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 col-md-4 col-sm-4 col-xs-6 col-6">
                  <div class="card">
                    <img
                      className="card-img-top"
                      src={require('../images/tm3.jpg')}
                      className="img-fluid image"
                      lt="Card image cap"
                    />
                    <div class="card-body">
                      <h5 class="card-title text-center">Shiva Varshney</h5>
                      <p class="card-text text-center">Full Stack Developer</p>
                      <div className="linkedin-logo text-center">
                        <a className="text-center">
                          <i class="fab fa-linkedin-in fa-2x" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div> */}
        </div>
      </div>
    );
  }
}
export default Team;
